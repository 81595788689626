import { uniq } from "lodash";
import { put, select } from "redux-saga/effects";
import { selectAiComponentPropertiesToChange } from "../selectors";
import {
  processAiProperties,
  setPropertiesToChange,
  setNoResultsResponse,
} from "../slice";

const REPLACEMENTS = {
  column: "primaryColumns",
  menuItem: "manualChildren",
};

export function* processAiPropertiesSaga(
  action: ReturnType<typeof processAiProperties>,
) {
  const { widgetId, properties, widgetType, noResultsResponse } =
    action.payload;
  const previousProperties: string[] | undefined = yield select(
    selectAiComponentPropertiesToChange,
  );
  const processedProperties: string[] = [];
  properties.forEach((property: string) => {
    if (widgetType === "button" && property.includes("textColor")) {
      processedProperties.push("textColor");
    } else if (property.startsWith("eventListener")) {
      // get the second piece of the property
      processedProperties.push(property.split(".")[1]);
    }
    const firstPart = property.split(".")[0];
    if (firstPart in REPLACEMENTS) {
      processedProperties.push(
        REPLACEMENTS[firstPart as keyof typeof REPLACEMENTS],
      );
      processedProperties.push(
        property.replace(
          firstPart,
          REPLACEMENTS[firstPart as keyof typeof REPLACEMENTS],
        ),
      );
    } else {
      processedProperties.push(property);
    }
  });

  const allProperties = uniq([
    ...(previousProperties || []),
    ...processedProperties,
  ]);

  if (noResultsResponse) {
    yield put(setNoResultsResponse(noResultsResponse));
  }
  yield put(setPropertiesToChange({ [widgetId]: allProperties }));
}
