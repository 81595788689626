import { useCallback } from "react";
import React from "react";
import { convertAnyDateToNumber } from "store/slices/apisV2";
import { useRenameWorkflowOrScheduledJobMutation } from "store/slices/reduxApi/workflowsAndScheduledJobs";
import RenameEntityModal, { RenameEntityModalProps } from "./RenameEntityModal";

export const RenameScheduledJobModal = ({
  entity,
  open,
  closeModal,
}: RenameEntityModalProps) => {
  const scheduledJobId = entity?.id;
  const [renameScheduledJob] = useRenameWorkflowOrScheduledJobMutation();

  const lastSuccessfulWriteForApi = convertAnyDateToNumber(entity?.updated);

  const handleScheduledJobRename = useCallback(
    async (newName: string) => {
      if (!scheduledJobId) {
        return;
      }
      await renameScheduledJob({
        id: scheduledJobId,
        name: newName,
        lastSuccessfulWrite: lastSuccessfulWriteForApi,
      }).unwrap();
    },
    [scheduledJobId, renameScheduledJob, lastSuccessfulWriteForApi],
  );

  return (
    <RenameEntityModal
      open={open}
      closeModal={closeModal}
      entity={entity}
      onRename={handleScheduledJobRename}
      title="Enter a new scheduled job name"
      entityLabel="scheduled job"
    />
  );
};

export default RenameScheduledJobModal;
