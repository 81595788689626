import { createSelector } from "@reduxjs/toolkit";
import equal from "@superblocksteam/fast-deep-equal/es6";
import { TriggerStepType, WidgetTypes } from "@superblocksteam/shared";
import { omit } from "lodash";
import { isPlainObject } from "lodash";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getCanvasWidgets } from "legacy/selectors/entitiesSelector";
import { getWidget, getWidgets } from "legacy/selectors/sagaSelectors";
import { WidgetProps } from "legacy/widgets";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import { TableWidgetProps } from "legacy/widgets/TableWidget/TableWidgetConstants";
import { getColumnIdFromAi } from "store/slices/ai/columnUtils";
import { MenuMetadata } from "./types";
import type {
  TabsWidgetProps,
  TabContainerWidgetProps,
} from "legacy/widgets/TabsWidget/types";
import type { AppState } from "store/types";

interface AiAction {
  action: string;
  column: string;
  [key: string]: any;
}

interface ColumnChange {
  columnId: string;
  action: AiAction;
  column: ColumnProperties;
}

export const selectAiState = (state: AppState) => state.ai;

export const selectAiModalOpen = createSelector(
  selectAiState,
  (state) => state.selectedWidgetId !== undefined,
);

export const selectInitialPosition = createSelector(
  selectAiState,
  (state) => state.initialPosition,
);

export const selectAiEditedWidgetId = createSelector(
  selectAiState,
  (state) => state.selectedWidgetId,
);

export const selectAiLayoutChangesByWidgetId = createSelector(
  selectAiState,
  (state) => state.layoutChangesByWidgetId,
);

export const selectAiDataTreeChangesById = createSelector(
  selectAiState,
  (state) => {
    if (!state.selectedWidgetId || !state.dataTreeChanges) {
      return undefined;
    }
    return {
      ...state.dataTreeChanges,
    } as Record<string, Partial<Omit<WidgetProps, "children">>>;
  },
);

export const selectAiComponentDataTreeEdits = createSelector(
  selectAiState,
  (state) => {
    return state.dataTreeChanges;
  },
);

export const selectAiComponentPropertiesToChange = createSelector(
  selectAiState,
  (state) => {
    return state.propertiesToChange;
  },
);

export const selectAddedRemovedTableColumns = createSelector(
  [
    selectAiState,
    (state: AppState) => state,
    (_state: AppState, itemKind?: ItemKinds) => itemKind,
  ],
  (
    aiState,
    state,
    itemKind,
  ): { addedColumns: ColumnChange[]; removedColumns: ColumnChange[] } => {
    const emptyResult = {
      addedColumns: [],
      removedColumns: [],
    };
    if (!itemKind || itemKind !== ItemKinds.AI_EDITS) {
      return emptyResult;
    }

    if (!aiState.selectedWidgetId) {
      return emptyResult;
    }

    const selectedWidget = getWidget(state, aiState.selectedWidgetId);
    if (
      !selectedWidget ||
      selectedWidget.type !== WidgetTypes.TABLE_WIDGET ||
      !aiState.actions?.length
    ) {
      return emptyResult;
    }

    const tableWidget = selectedWidget as TableWidgetProps;
    const existingColumns = Object.keys(tableWidget.primaryColumns);

    const setActions = (aiState.actions || []).filter(
      (action: AiAction) => action?.action === "set" && action?.column != null,
    );

    // Added columns with their actions
    const addedColumns = setActions
      .filter((action) => !existingColumns.includes(action.column))
      .map((action) => {
        const primaryColumns = aiState.dataTreeChanges?.[tableWidget.widgetId]
          ?.primaryColumns as Record<string, ColumnProperties> | undefined;
        const column = primaryColumns?.[action.column];
        if (!column) return undefined;
        return {
          columnId: action.column,
          action,
          column,
        };
      })
      .filter((item): item is ColumnChange => item !== undefined);

    const removeActions = (aiState.actions || []).filter(
      (action: AiAction) =>
        action?.action === "remove" && action.column != null,
    );

    // Removed columns with their actions
    const removedColumns = removeActions
      .map((action) => {
        const columnId = getColumnIdFromAi(
          action.column,
          tableWidget.primaryColumns,
        );
        if (!columnId || !tableWidget.primaryColumns?.[columnId]) {
          return undefined;
        }
        // Skip derived columns as they just get hidden and not deleted
        if (!tableWidget.primaryColumns[columnId].isDerived) {
          return undefined;
        }
        const column = tableWidget.primaryColumns[columnId];
        return {
          columnId,
          action,
          column,
        };
      })
      .filter((item): item is ColumnChange => item !== undefined);

    return {
      addedColumns,
      removedColumns,
    };
  },
);

export const selectAddedRemovedEventTriggers = createSelector(
  [
    selectAiState,
    (state: AppState) => state,
    (_state: AppState, itemKind?: ItemKinds) => itemKind,
    (_state: AppState, _itemKind?: ItemKinds, propertyName?: string) =>
      propertyName,
  ],
  (
    aiState,
    state,
    itemKind,
    propertyName,
  ): { addedTriggers: any[]; removedTriggers: any[] } => {
    const emptyResult = {
      addedTriggers: [],
      removedTriggers: [],
    };
    if (!itemKind || itemKind !== ItemKinds.AI_EDITS || !propertyName) {
      return emptyResult;
    }

    if (!aiState.selectedWidgetId) {
      return emptyResult;
    }

    const selectedWidget = getWidget(state, aiState.selectedWidgetId);

    if (
      !selectedWidget ||
      !aiState.actions ||
      !aiState.actions?.length ||
      !aiState.dataTreeChanges
    ) {
      return emptyResult;
    }

    const addedEventTriggers = aiState.actions
      .filter((action) => {
        const isAddTriggerAction =
          action.action === "add" &&
          action.property === propertyName &&
          action.value &&
          isPlainObject(action.value) &&
          Object.values(TriggerStepType).includes(action.value.type);

        return isAddTriggerAction;
      })
      .map((addTriggerAction) => {
        return (
          (aiState.dataTreeChanges?.[selectedWidget.widgetId]?.[
            propertyName
          ] as any[]) || []
        ).find((dataTreeTrigger: any) => {
          return (
            dataTreeTrigger.type === addTriggerAction.value.type &&
            equal(omit(dataTreeTrigger, "id"), addTriggerAction.value)
          );
        });
      })
      .filter((trigger) => Boolean(trigger));

    const removedEventTriggers = (aiState.actions || [])
      .filter((action) => {
        const isRemoveTriggerAction =
          action.action === "remove" &&
          action.property === propertyName &&
          action.value?.type &&
          action.value?.type === "remove";

        return isRemoveTriggerAction;
      })
      .map((removeTriggerAction) => {
        const removeId = removeTriggerAction.value?.id;
        const propertyValue = selectedWidget[propertyName as keyof WidgetProps];

        if (!removeId || !propertyValue || !Array.isArray(propertyValue)) {
          return undefined;
        }

        return propertyValue.find((trigger: any) => trigger?.id === removeId);
      })
      .filter((trigger) => Boolean(trigger));

    return {
      addedTriggers: addedEventTriggers,
      removedTriggers: removedEventTriggers,
    };
  },
);

export const selectAddedRemovedProperty = createSelector(
  [
    selectAiState,
    (state: AppState) => state,
    (_state: AppState, itemKind?: ItemKinds) => itemKind,
    (_state: AppState, _itemKind?: ItemKinds, propertyName?: string) =>
      propertyName,
  ],
  (
    aiState,
    state,
    itemKind,
    propertyName,
  ):
    | {
        tag: "added" | "removed" | undefined;
        originalValue: any;
      }
    | undefined => {
    if (
      !itemKind ||
      itemKind !== ItemKinds.AI_EDITS ||
      !propertyName ||
      !aiState.selectedWidgetId ||
      !aiState.actions
    ) {
      return undefined;
    }

    const selectedWidget = getWidget(state, aiState.selectedWidgetId);
    if (!selectedWidget) {
      return undefined;
    }

    if (
      aiState.actions.some(
        (action) => action.action === "add" && action.property === propertyName,
      )
    ) {
      return {
        tag: "added",
        originalValue: null,
      };
    }

    if (
      aiState.actions.some(
        (action) =>
          action.action === "remove" && action.property === propertyName,
      )
    ) {
      return {
        tag: "removed",
        originalValue: selectedWidget[propertyName as keyof WidgetProps],
      };
    }

    return undefined;
  },
);

export const selectAddedUpdatedRemovedChildren = createSelector(
  [
    selectAiState,
    getWidgets,
    (_state: AppState, itemKind?: ItemKinds) => itemKind,
  ],
  (
    aiState,
    widgets,
    itemKind,
  ): {
    addedChildren: WidgetProps[];
    updatedChildren: WidgetProps[];
    removedChildren: WidgetProps[];
  } => {
    const emptyResult = {
      addedChildren: [],
      updatedChildren: [],
      removedChildren: [],
    };
    const selectedWidget = aiState.selectedWidgetId
      ? widgets[aiState.selectedWidgetId]
      : undefined;
    if (
      !itemKind ||
      itemKind !== ItemKinds.AI_EDITS ||
      !aiState.selectedWidgetId ||
      !selectedWidget ||
      !aiState.actions?.length
    ) {
      return emptyResult;
    }

    const selectedWidgetChildren = selectedWidget.children || [];
    const aiWidgetChildren = (aiState.dataTreeChanges?.[selectedWidget.widgetId]
      ?.children || []) as any[];

    const addedChildren = aiWidgetChildren
      .filter((childId: string) => !selectedWidgetChildren.includes(childId))
      .map((childId: string) => aiState.dataTreeChanges?.[childId])
      .filter(Boolean) as unknown as WidgetProps[];

    const updatedChildren = aiState.actions
      .filter((action) => action.action === "set" && action.tab != null)
      .map((action) => {
        const selectedTabWidget =
          selectedWidget as TabsWidgetProps<TabContainerWidgetProps>;
        const tab = selectedTabWidget.tabs[action.tab];
        return tab?.widgetId ? widgets[tab.widgetId] : undefined;
      })
      .filter(Boolean) as WidgetProps[];

    const removedChildren = selectedWidgetChildren
      .filter((child: string) => !aiWidgetChildren.includes(child))
      .map((child: string) => widgets[child]);

    return {
      addedChildren,
      updatedChildren,
      removedChildren,
    };
  },
);

export const selectAddedRemovedNestedChildListItems = createSelector(
  [
    selectAiState,
    (state: AppState) => state,
    (_state: AppState, itemKind?: ItemKinds) => itemKind,
  ],
  (aiState, state, itemKind) => {
    const emptyResult = {
      addedItems: [],
      removedItems: [],
    };

    if (itemKind !== ItemKinds.AI_EDITS) {
      return emptyResult;
    }
    if (
      !aiState.selectedWidgetId ||
      !aiState.metadataByWidgetId?.[aiState.selectedWidgetId]
    ) {
      return emptyResult;
    }
    const selectedWidget = getWidget(state, aiState.selectedWidgetId);
    if (!selectedWidget || selectedWidget.type !== WidgetTypes.MENU_WIDGET) {
      return emptyResult;
    }
    const metadata = aiState.metadataByWidgetId[aiState.selectedWidgetId];
    if (!metadata || !(metadata as MenuMetadata).indexMap) {
      return emptyResult;
    }
    const menuMetadta = metadata as MenuMetadata;
    const { menuItemChanges, indexMap } = menuMetadta;
    const addedItems = Object.entries(menuItemChanges)
      .filter(([key, change]) => (change as any)?.status === "added")
      .map(([key]) => {
        const indexInManualChildren = indexMap[parseInt(key, 10)];
        if (indexInManualChildren === undefined) {
          return undefined;
        }
        return {
          index: indexInManualChildren,
        };
      })
      .filter(Boolean) as { index: number }[];
    const removedItems = Object.entries(menuItemChanges)
      .filter(([key, change]) => (change as any)?.status === "removed")
      .map(([key]) => {
        const originalIndex = parseInt(key, 10);
        return {
          index: originalIndex,
          menuItem: (selectedWidget as any)?.manualChildren?.[
            originalIndex
          ] as any,
        };
      });
    return {
      addedItems,
      removedItems,
    };
  },
);

export const selectCanvasWidgetsWithAiChanges = createSelector(
  [
    getCanvasWidgets,
    selectAiDataTreeChangesById,
    (_state: AppState, includeAiChanges?: boolean) => includeAiChanges,
  ],
  (rawWidgets, aiChanges, includeAiChanges) => {
    // Start with raw widgets
    const widgets = {
      ...rawWidgets,
    };

    // Merge in any AI changes if they exist
    if (aiChanges && includeAiChanges) {
      for (const widgetId of Object.keys(aiChanges)) {
        widgets[widgetId] = {
          ...(widgets[widgetId] || {}),
          ...(aiChanges[widgetId] || {}),
        };
      }
    }

    return widgets;
  },
);

export const selectNonAiLayoutPropertiesByWidgetId = createSelector(
  [selectAiState],
  (aiState) => {
    return aiState.preAiLayoutValuesByWidgetId;
  },
);

export const selectAiChangedProperties = createSelector(
  [selectAiState, (_state: AppState, widgetId?: string) => widgetId],
  (aiState, widgetId) => {
    if (
      !aiState.selectedWidgetId ||
      aiState.selectedWidgetId !== widgetId ||
      !aiState.dataTreeChanges
    ) {
      return [];
    }
    return aiState.changedKeys?.[widgetId] || [];
  },
);
