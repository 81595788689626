import {
  ApplicationScope,
  RouteDef,
  WidgetTypes,
} from "@superblocksteam/shared";
import { set, uniq, uniqBy } from "lodash";
import { createNameValidator } from "hooks/store/useEntityNameValidator";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ItemWithPropertiesType } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import {
  flattenObject,
  unflattenObject,
} from "legacy/pages/Editor/PropertyPane/widgetPropertyPaneConfigUtils";
import { GeneratedTheme } from "legacy/themes";
import {
  getWidgetDynamicPropertyPathList,
  mergeUpdatesWithBindingsOrTriggers,
} from "legacy/utils/DynamicBindingUtils";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";

import { fastClone } from "utils/clone";
import { Flag, Flags } from "../featureFlags";
import { getColumnIdFromAi } from "./columnUtils";
import { getEventHandlers } from "./eventHandlers";
import {
  addMenuItem,
  applyMenuMetadata,
  initializeMenuMetadata,
  removeMenuItem,
  updateMenuItem,
} from "./menuUtils";
import {
  addTab,
  initializeTabMetadata,
  removeTab,
  updateTab,
} from "./tabUtils";
import {
  addColumns,
  removeColumns,
  updateColumnOrder,
  updateColumns,
} from "./tableUtils";
import {
  AddActionEvent,
  AddEventAction,
  ComponentEditAction,
  RemoveEventAction,
  SetAction,
  EditMetadata,
  MenuMetadata,
  TabMetadata,
} from "./types";
import { sanitizeEdits } from "./utils";

const METADATA_INITIALIZERS: Partial<
  Record<
    WidgetTypes,
    (widget: Partial<WidgetProps>, numItems: number) => EditMetadata
  >
> = {
  [WidgetTypes.TABS_WIDGET]: initializeTabMetadata,
  [WidgetTypes.MENU_WIDGET]: initializeMenuMetadata,
};

export type DiscardedEdit = {
  propertyName: string;
  reason: string;
  propertyValue: unknown;
};

type KeyPathObj = {
  key: string;
};

const getItems = (widget: Partial<WidgetProps>) => {
  switch (widget.type) {
    case WidgetTypes.MENU_WIDGET:
      return {
        items: (widget as any).manualChildren,
        itemCount: (widget as any).manualChildren.length,
      };
    case WidgetTypes.TABLE_WIDGET:
      return {
        items: Object.keys((widget as any).primaryColumns),
        itemCount: Object.keys((widget as any).primaryColumns).length,
      };
    case WidgetTypes.TABS_WIDGET:
      return {
        items: (widget as any).tabs,
        itemCount: (widget as any).tabs.length,
      };
    default:
      return {
        items: [],
        itemCount: 0,
      };
  }
};

type ItemIdAndExistence =
  | {
      itemId: string;
      type: "column";
      exists: boolean;
    }
  | {
      itemId: number;
      type: "menu_item";
      exists: boolean;
    }
  | {
      itemId: number;
      type: "tab";
      exists: boolean;
    }
  | {
      itemId: undefined;
      type: undefined;
      exists: false;
    };

const getItemIdAndExistence = ({
  action,
  widget,
  metadata,
  items,
  changes,
}: {
  action: ComponentEditAction;
  widget: Partial<WidgetProps>;
  metadata: EditMetadata | undefined;
  items: Array<any>;
  changes: Record<string, unknown>;
}): ItemIdAndExistence => {
  switch (widget.type) {
    case WidgetTypes.MENU_WIDGET:
      if ("menu_item" in action && action.menu_item != null) {
        const menuItem = parseInt(action.menu_item, 10);
        const menuItemExists =
          menuItem != null &&
          metadata &&
          menuItem in (metadata as MenuMetadata).menuItemChanges;
        return {
          itemId: menuItem,
          exists: Boolean(menuItemExists),
          type: "menu_item",
        };
      }
      break;
    case WidgetTypes.TABLE_WIDGET:
      if ("column" in action && action.column != null) {
        const primaryColumns = (widget as any).primaryColumns as Record<
          string,
          any
        >;
        const column =
          getColumnIdFromAi(action.column, primaryColumns) ?? action.column;

        const columnExists =
          column &&
          (items.includes(column) ||
            (changes.columnOrder as string[])?.includes(column));

        return {
          itemId: column,
          exists: Boolean(columnExists),
          type: "column",
        };
      }
      break;
    case WidgetTypes.TABS_WIDGET:
      if ("tab" in action && action.tab) {
        const tabIndex = parseInt(action.tab, 10);
        const existingIndices = Object.values(
          (metadata as TabMetadata).tabWidgetIdToIndex,
        );
        const tabExists =
          tabIndex != null && metadata && existingIndices.includes(tabIndex);
        return {
          itemId: tabIndex,
          exists: Boolean(tabExists),
          type: "tab",
        };
      }
      break;
  }
  return {
    itemId: undefined,
    exists: false,
    type: undefined,
  };
};

export const processActionsIntoChanges = async ({
  actions,
  existingWidget,
  dataTree,
  routes,
  theme,
  nameValidator,
  featureFlags,
  widgets,
}: {
  actions: ComponentEditAction[];
  existingWidget: Partial<WidgetProps>;
  dataTree: DataTree;
  routes: RouteDef[];
  theme: GeneratedTheme;
  nameValidator: ReturnType<typeof createNameValidator>;
  featureFlags: Flags;
  widgets: Record<string, Partial<WidgetProps>>;
}): Promise<{
  changedKeys?: Record<string, string[]>;
  dataTreeChanges?: Record<string, Record<string, unknown> | null>;
  rename?: string;
  discardedEdits: DiscardedEdit[];
  metadataByWidgetId?: Record<string, EditMetadata>;
}> => {
  const changes: Record<string, unknown> = {};
  const dependentChangesByWidgetId: Record<
    string,
    Record<string, unknown> | null
  > = {};
  let rename: string | undefined;
  const discardedEdits: DiscardedEdit[] = [];

  const itemData = getItems(existingWidget);
  const items = itemData.items;
  let itemCount = itemData.itemCount;

  const metadataByWidgetId: Record<string, EditMetadata> = {};
  const metadata: EditMetadata | undefined = METADATA_INITIALIZERS[
    existingWidget.type as keyof typeof METADATA_INITIALIZERS
  ]?.(existingWidget, itemCount);

  actions.forEach((action) => {
    const { action: actionType } = action;
    let returnedValue: any;
    if ("value" in action) {
      returnedValue = action.value;
    }
    const { itemId, exists, type } = getItemIdAndExistence({
      action,
      widget: existingWidget,
      metadata,
      items,
      changes,
    });

    let propertyName =
      !action.property && actionType === "remove" && type === "column"
        ? `primaryColumns.${itemId}`
        : (action.property as string);

    const prevValue =
      changes[propertyName] ??
      existingWidget[propertyName as keyof WidgetProps];
    if (
      propertyName === "textProps.textStyle.textColor.default" &&
      existingWidget.type === WidgetTypes.BUTTON_WIDGET
    ) {
      propertyName = "textColor";
    }

    switch (actionType) {
      case "set": {
        if (propertyName === "columnOrder") {
          const existingColumnOrder: string[] =
            changes.columnOrder ?? (existingWidget as any).columnOrder;
          const newColumnOrder = updateColumnOrder({
            columnOrderFromAi: returnedValue as string[],
            existingColumnOrder,
            originalColumns: (existingWidget as any).primaryColumns,
          });
          changes[propertyName] = newColumnOrder;
        } else if (type) {
          switch (type) {
            case "tab":
              if (exists && metadata) {
                updateTab({
                  tabMetadata: metadata as TabMetadata,
                  index: itemId,
                  property: action.property as string,
                  value: action.value,
                  dependentChangesByWidgetId,
                  widget: existingWidget,
                });
              } else if (!exists && metadata) {
                addTab({
                  tabMetadata: metadata as TabMetadata,
                  index: itemId,
                  property: action.property as string,
                  value: action.value,
                  widget: existingWidget,
                  dependentChangesByWidgetId,
                });
              }
              break;
            case "column":
              if (!exists) {
                addColumns({
                  widget: existingWidget,
                  changes,
                  numColumns: itemCount,
                  columnName: itemId,
                });
                itemCount += 1;
              }
              if (action.property && action.value) {
                updateColumns({
                  action: action as
                    | SetAction
                    | AddEventAction
                    | RemoveEventAction,
                  widget: existingWidget,
                  changes,
                  dataTree,
                  routes,
                  columnName: itemId,
                });
              }
              break;
            case "menu_item":
              if (exists && metadata) {
                updateMenuItem({
                  menuMetadata: metadata as MenuMetadata,
                  index: itemId,
                  property: action.property as string,
                  value: action.value,
                });
              } else if (!exists && metadata) {
                addMenuItem({
                  menuMetadata: metadata as MenuMetadata,
                  index: itemId,
                  property: action.property as string,
                  value: action.value,
                });
              }
              break;
          }
        } else if (propertyName === "widgetName") {
          if (typeof returnedValue === "string") {
            const val = returnedValue.replaceAll(" ", "_");
            const nameError = nameValidator({
              currentName: existingWidget.widgetName as string,
              name: val,
              scope: ApplicationScope.PAGE,
            });
            if (!nameError) {
              rename = val;
            }
          }
        } else {
          changes[propertyName] = returnedValue;
        }
        break;
      }
      case "add": {
        if (type === "column") {
          updateColumns({
            action: action as AddEventAction,
            widget: existingWidget,
            changes,
            dataTree,
            routes,
            columnName: itemId,
          });
        } else {
          changes[propertyName] = getEventHandlers({
            prevValue,
            value: returnedValue as AddActionEvent | AddActionEvent[],
            dataTree,
            routes,
          });
        }
        break;
      }
      case "remove": {
        if (type && exists && action.property) {
          // removing a property from a nested item
          switch (type) {
            case "tab":
              updateTab({
                tabMetadata: metadata as TabMetadata,
                index: itemId,
                property: action.property as string,
                value: undefined,
                dependentChangesByWidgetId,
                widget: existingWidget,
              });
              break;
            case "column":
              updateColumns({
                action: action as RemoveEventAction,
                widget: existingWidget,
                changes,
                dataTree,
                routes,
                columnName: itemId,
              });
              break;
            case "menu_item":
              updateMenuItem({
                menuMetadata: metadata as MenuMetadata,
                index: itemId,
                property: action.property as string,
                value: undefined,
              });
              break;
          }
        } else if (type && exists && !action.property) {
          switch (type) {
            case "tab":
              removeTab({
                tabMetadata: metadata as TabMetadata,
                index: itemId,
                dependentChangesByWidgetId,
                widget: existingWidget,
              });
              break;
            case "column":
              removeColumns({
                widget: existingWidget,
                changes,
                columnName: itemId,
              });
              itemCount -= 1;
              break;
            case "menu_item":
              removeMenuItem({
                menuMetadata: metadata as MenuMetadata,
                index: itemId,
              });
              break;
          }
        } else if ("value" in action) {
          const idToRemove = (action as RemoveEventAction).value?.id;
          if (Array.isArray(prevValue)) {
            changes[propertyName] = prevValue.filter(
              (item) => item.id !== idToRemove,
            );
          }
        } else {
          // remove is a reset
          changes[propertyName] = undefined;
        }
        break;
      }
      case "reset": {
        if (type === "menu_item" && metadata) {
          updateMenuItem({
            menuMetadata: metadata as MenuMetadata,
            index: itemId,
            property: action.property as string,
            value: undefined,
          });
        } else {
          changes[propertyName] = undefined;
        }
        break;
      }
      default:
        console.error(`Unknown action type ${actionType} returned`);
    }
  });
  if (metadata && existingWidget.widgetId) {
    metadataByWidgetId[existingWidget.widgetId] = metadata;
  }
  // apply menu metadata to changes
  if (existingWidget.type === WidgetTypes.MENU_WIDGET && metadata) {
    const {
      updatedMenuItems,
      metadata: updatedMetadata,
      hasUpdates,
    } = applyMenuMetadata({
      originalMenuItems: items,
      menuMetadata: metadata as MenuMetadata,
    });

    if (hasUpdates) {
      changes.manualChildren = updatedMenuItems;
      if (existingWidget.widgetId) {
        metadataByWidgetId[existingWidget.widgetId] = updatedMetadata;
      }
    }
  }

  // Apply final tab changes if any modifications were made
  if (metadata && (metadata as TabMetadata).tabsModified) {
    changes.tabs = (metadata as TabMetadata).currentTabs;
    changes.children = (metadata as TabMetadata).currentChildren;
  }

  if (Object.keys(changes).length > 0) {
    const propSections = getItemPropertyPaneConfig(
      existingWidget.type as ItemWithPropertiesType,
    );
    let dataTreeChanges = mergeUpdatesWithBindingsOrTriggers(
      existingWidget,
      propSections,
      changes,
      featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    const allProperties = propSections
      .flatMap((section) => section.children)
      .filter(Boolean);

    const dynamicProperties = getWidgetDynamicPropertyPathList(existingWidget);
    const tabChangedKeys = metadata
      ? ((metadata as TabMetadata)?.additionalChangedKeys ?? [])
      : [];

    let changedKeys = [...Object.keys(dataTreeChanges), ...tabChangedKeys];

    const newDataTreeChanges: Record<string, unknown> = {};
    // roll up the data tree changes to the top level, backfilling from existing widget when needed
    Object.entries(dataTreeChanges).forEach(([key, value]) => {
      if (newDataTreeChanges[key]) {
        return;
      }
      if (key.includes(".")) {
        const topLevelKey = key.split(".")[0];
        if (!newDataTreeChanges[topLevelKey]) {
          newDataTreeChanges[topLevelKey] = fastClone(
            existingWidget[topLevelKey as keyof WidgetProps],
          );
        }
        set(newDataTreeChanges, key, value);
      } else {
        newDataTreeChanges[key] = value;
      }
    });

    dataTreeChanges = newDataTreeChanges;

    await sanitizeEdits({
      edits: dataTreeChanges,
      properties: allProperties as PropertyPaneConfig[],
      discardedEdits,
      dynamicProperties,
      previousWidget: existingWidget,
      theme,
      featureFlags,
      changedKeys,
      dependentChangesByWidgetId,
      widgets,
    });

    // make sure dynamic property list is unique
    dataTreeChanges.dynamicPropertyPathList = uniqBy(
      dynamicProperties,
      (prop) => prop.key,
    );

    // TODO: We have to flatten and then unflatten the dataTreeChanges here
    // to make the logic in mergeUpdatesWithBindingsOrTriggers work as expected.
    // This is not performant, so we should fix soon
    dataTreeChanges = mergeUpdatesWithBindingsOrTriggers(
      existingWidget,
      propSections,
      flattenObject(dataTreeChanges),
      featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    // unflatten the dataTreeChanges
    dataTreeChanges = unflattenObject(dataTreeChanges);

    changedKeys = uniq(
      changedKeys.filter((key) => {
        if (discardedEdits.some((edit) => edit.propertyName === key)) {
          return false;
        }
        return true;
      }),
    );

    if (
      changedKeys.filter(
        (key) =>
          ![
            "dynamicPropertyPathList",
            "dynamicBindingPathList",
            "dynamicTriggerPathList",
          ].includes(key),
      ).length === 0
    ) {
      return {
        changedKeys: {},
        dataTreeChanges: {},
        rename,
        discardedEdits,
      };
    }

    dataTreeChanges.dynamicBindingPathList = uniqBy(
      (dataTreeChanges as any).dynamicBindingPathList ?? [],
      (prop: { key: string }) => prop.key,
    );

    dataTreeChanges.dynamicTriggerPathList = uniqBy(
      (dataTreeChanges as any).dynamicTriggerPathList ?? [],
      (prop: { key: string }) => prop.key,
    );
    dataTreeChanges.dynamicPropertyPathList = uniqBy(
      (dataTreeChanges as any).dynamicPropertyPathList ?? [],
      (prop: { key: string }) => prop.key,
    );

    // Make sure to remove any removed columns
    if (dataTreeChanges.primaryColumns) {
      // Get all removed columns by finding any primaryColumns that are null
      const removedColumns = Object.keys(
        dataTreeChanges.primaryColumns as Record<string, any>,
      ).filter(
        (column) =>
          dataTreeChanges.primaryColumns &&
          (dataTreeChanges.primaryColumns as Record<string, any>)[column] !==
            undefined &&
          (dataTreeChanges.primaryColumns as Record<string, any>)[column] ===
            null,
      );

      const allColumns = Object.keys(
        dataTreeChanges.primaryColumns as Record<string, any>,
      );

      const removeMatchingPaths = (columnName: string, pathList: any): void => {
        const pathListArray = pathList as KeyPathObj[];

        for (let i = pathListArray.length - 1; i >= 0; i--) {
          if (
            pathListArray[i].key.startsWith(`primaryColumns.${columnName}`) ||
            pathListArray[i].key.startsWith(`derivedColumns.${columnName}`)
          ) {
            pathListArray.splice(i, 1);
          }
        }
      };

      for (const column of removedColumns) {
        removeMatchingPaths(column, dataTreeChanges.dynamicBindingPathList);
        removeMatchingPaths(column, dataTreeChanges.dynamicTriggerPathList);
        removeMatchingPaths(column, dataTreeChanges.dynamicPropertyPathList);

        // Make sure primaryColumns and derivedColumns have this column removed, but other columns are there
        delete (dataTreeChanges.primaryColumns as Record<string, any>)[column];
        delete (dataTreeChanges.derivedColumns as Record<string, any>)[column];
      }

      for (const column of allColumns) {
        // these incorrectly get added to the dynamicBindingPathList when they should only appear on the dynamic trigger path list
        removeMatchingPaths(
          `${column}.onClick`,
          dataTreeChanges.dynamicBindingPathList,
        );
        if (
          !(dataTreeChanges.dynamicTriggerPathList as any).some(
            (path: { key: string }) =>
              path.key === `primaryColumns.${column}.onClick`,
          ) &&
          (dataTreeChanges.primaryColumns as any)?.[column]?.onClick
        ) {
          (dataTreeChanges.dynamicTriggerPathList as any).push({
            key: `primaryColumns.${column}.onClick`,
          });
        }
      }
    }

    return {
      changedKeys: {
        [existingWidget.widgetId as string]: changedKeys,
      },
      dataTreeChanges: {
        [existingWidget.widgetId as string]: dataTreeChanges,
        ...(dependentChangesByWidgetId
          ? Object.keys(dependentChangesByWidgetId).reduce(
              (acc, widgetId) => {
                if (dependentChangesByWidgetId[widgetId] !== undefined) {
                  acc[widgetId as string] =
                    dependentChangesByWidgetId[widgetId];
                }
                return acc;
              },
              {} as Record<string, Record<string, unknown> | null>,
            )
          : {}),
      },
      rename,
      discardedEdits,
      metadataByWidgetId,
    };
  }

  return {
    changedKeys: {},
    dataTreeChanges: {},
    rename,
    discardedEdits: [],
    metadataByWidgetId,
  };
};
