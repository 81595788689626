import {
  ActionTypeEnum,
  ApiTriggerType,
  FolderDto,
  IHomepageApplicationV2Dto,
  ResourceTypeEnum,
  ScheduleState,
} from "@superblocksteam/shared";
import React from "react";
import { ReactComponent as AppIcon } from "assets/icons/home/app_small.svg";
import { ReactComponent as FolderIcon } from "assets/icons/home/folder.svg";
import { ReactComponent as JobIcon } from "assets/icons/home/job_small.svg";
import { ReactComponent as WorkflowIcon } from "assets/icons/home/workflow_small.svg";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  EditorRoute,
  getApplicationDeployedURL,
  SCHEDULED_JOB_CONFIGURE_URL,
  WORKFLOW_CONFIGURE_URL,
} from "legacy/constants/routes";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { EntityType } from "utils/entity";

type HomeEntityType = EntityType | "folder";

export interface Entity {
  id: string;
  name?: string;
  isDeployed?: boolean;
  isEditable?: boolean;
  type: HomeEntityType;
  updated?: Date;
  editUrl?: string;
  deployUrl?: string;
  scheduleState?: ScheduleState;
  folderId?: string | null;
  folderName?: string;
  canDelete?: boolean;
  permissions?: ActionTypeEnum[];
  devEnvEnabled?: boolean;
  //newly added
  creator?: {
    id: string;
    name: string;
    email?: string;
  };
  lastDeployedAt?: Date | null;
}

export type FolderMap = Record<
  string,
  { name: string; updated?: Date; lastDeployedAt?: Date | null }
>;

export type FolderIdToNameMap = Record<string, string>;

export const getFolderNameFromFolderIdToNameMap = (
  folderId: string | null | undefined,
  folderIdToNameMap: FolderIdToNameMap,
) => {
  // folderId might be `""`, `null`, or `undefined`, which is the root folder and has no name
  return folderId ? folderIdToNameMap[folderId] : undefined;
};

export const getMostRecent = (a?: Date | null, b?: Date | null) => {
  if (a && b) return new Date(a).getTime() > new Date(b).getTime() ? a : b;

  if (!a && b) return b;
  if (!b && a) return a;

  return undefined;
};

export const appToEntity = (
  app: IHomepageApplicationV2Dto,
  folderIdToNameMap: FolderIdToNameMap,
): Entity => {
  const baseEditUrl = getEditorBasePath(EditorRoute.EditApplication, {
    applicationId: app.id,
  });
  const editUrl = app.devEnvEnabled ? `/code-mode${baseEditUrl}` : baseEditUrl;
  return Object.assign({}, app, {
    editUrl,
    deployUrl: getApplicationDeployedURL(app.id),
    type: EntityType.APPLICATION,
    permissions: app.permissions,
    canDelete: app.canDelete,
    devEnvEnabled: app.devEnvEnabled,
    creator: app.creator,
    lastDeployedAt: app.lastDeployedAt,
    folderName: getFolderNameFromFolderIdToNameMap(
      app.folderId,
      folderIdToNameMap,
    ),
  });
};

export const apiToEntity = (
  api: ApiDtoWithPb,
  folderIdToNameMap: FolderIdToNameMap,
): Entity => {
  return {
    id: api.id,
    name: api.apiPb?.metadata?.name || api.actions?.name || api.name,
    // ApiDtos can only tell if they're deployed if they're schedules. Default
    // to false here for workflows.
    isDeployed: !!api.isDeployed,
    isEditable: api.isEditable ?? true,
    updated: api.updated,
    scheduleState: api.scheduleState,
    editUrl:
      api?.triggerType === ApiTriggerType.WORKFLOW ||
      api?.actions?.triggerType === ApiTriggerType.WORKFLOW
        ? WORKFLOW_CONFIGURE_URL(api.id)
        : SCHEDULED_JOB_CONFIGURE_URL(api.id),
    type: (api?.triggerType === ApiTriggerType.WORKFLOW ||
    api?.actions?.triggerType === ApiTriggerType.WORKFLOW
      ? EntityType.WORKFLOW
      : EntityType.SCHEDULED_JOB) as HomeEntityType,
    folderId: api.folderId,
    folderName: getFolderNameFromFolderIdToNameMap(
      api.folderId,
      folderIdToNameMap,
    ),
    canDelete: api.canDelete,
    permissions: api.permissions,
    creator: api.creator,
    // TODO: need to add lastDeployed backend support
    lastDeployedAt: api.lastDeployedAt,
  };
};

export const folderToEntity = (
  folderDto: FolderDto,
  folderMap: FolderMap,
): Entity => {
  return {
    type: "folder",
    id: folderDto.id,
    name: folderDto.name,
    creator: folderDto.creator,
    updated: getMostRecent(folderDto.updated, folderMap[folderDto.id]?.updated),
    lastDeployedAt: folderMap[folderDto.id]?.lastDeployedAt,
  };
};

export const getEntityIcon = (type: HomeEntityType) => {
  switch (type) {
    case EntityType.APPLICATION:
      return <AppIcon />;
    case EntityType.WORKFLOW:
      return <WorkflowIcon />;
    case EntityType.SCHEDULED_JOB:
      return <JobIcon />;
    case "folder":
      return <FolderIcon />;
    default:
      return null;
  }
};

export const convertToResourceType = (type: HomeEntityType) => {
  switch (type) {
    case EntityType.APPLICATION:
      return ResourceTypeEnum.APPLICATIONS;
    case EntityType.WORKFLOW:
      return ResourceTypeEnum.WORKFLOWS;
    case EntityType.SCHEDULED_JOB:
      return ResourceTypeEnum.SCHEDULED_JOBS;
    case "folder":
      return ResourceTypeEnum.FOLDERS;
    default:
      return null;
  }
};
