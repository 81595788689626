import { useCallback } from "react";
import { convertAnyDateToNumber } from "store/slices/apisV2";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { useListWorkflowsAndScheduledJobsQuery } from "store/slices/reduxApi/workflowsAndScheduledJobs";
import { useUpdateWorkflowOrScheduledJobMutation } from "store/slices/reduxApi/workflowsAndScheduledJobs";
import type { Entity } from "pages/Home/EntityCard";

/**
 * Moves APIs (Workflows or Scheduled Jobs) to a folder.
 */
export default function useMoveApiToFolder() {
  const { data: workflowsAndScheduledJobs } =
    useListWorkflowsAndScheduledJobsQuery();
  const [updateWorkflowOrScheduledJob] =
    useUpdateWorkflowOrScheduledJobMutation();

  return useCallback(
    /**
     * @param entity - The entity to move to the folder, only the id is required. This allows us to use it as the transformed Entity used in the home page or as an ApiDtoWithPb.
     * @param folderId - The ID of the folder to move the entity to, or null to move to the root folder.
     */
    (entity: Pick<Entity, "id">, folderId: string | null) => {
      const updates: Partial<ApiDtoWithPb> = {
        folderId,
      };

      const api = workflowsAndScheduledJobs?.find(
        (api) => api.id === entity.id,
      );
      if (!api) {
        throw new Error("API not found");
      }

      const lastSuccessfulWriteForApi = convertAnyDateToNumber(api.updated);

      return updateWorkflowOrScheduledJob({
        id: entity.id,
        updates,
        meta: { lastSuccessfulWrite: lastSuccessfulWriteForApi },
      });
    },
    [workflowsAndScheduledJobs, updateWorkflowOrScheduledJob],
  );
}
