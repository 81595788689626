import { PutApiSettingsUpdateRequestBody } from "@superblocksteam/schemas";
import {
  PutApiSettingsUpdateResponseBody,
  ScheduleState,
} from "@superblocksteam/shared";
import { Metadata } from "@superblocksteam/types";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { reduxApiSlice } from "./index";

type PatchApiMetadataResponseBody = {
  updated: string;
  metadata: Metadata;
};

const extendedApi = reduxApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listWorkflowsAndScheduledJobs: builder.query<ApiDtoWithPb[], void>({
      query: () => `v3/apis`,
      transformResponse: (response: { data: ApiDtoWithPb[] }) => {
        return response.data;
      },

      providesTags: (result = []) => [
        { type: "WorkflowsAndScheduledJobs" as const },
        ...result.map(({ id }) => ({
          type: "WorkflowsAndScheduledJobs" as const,
          id,
        })),
      ],
    }),

    updateWorkflowOrScheduledJob: builder.mutation<
      PutApiSettingsUpdateResponseBody,
      {
        id: string;
        updates: Partial<ApiDtoWithPb>;
        meta: { lastSuccessfulWrite: number | undefined };
      }
    >({
      query: ({ id, updates, meta }) => {
        const body: Omit<
          PutApiSettingsUpdateRequestBody,
          "lastSuccessfulWrite"
        > &
          Pick<
            Partial<PutApiSettingsUpdateRequestBody>,
            "lastSuccessfulWrite"
          > = {
          folderId: updates.folderId,
          lastSuccessfulWrite: meta.lastSuccessfulWrite,
        };

        return {
          url: `v3/apis/${id}/settings`,
          method: "PUT",
          body,
        };
      },
      transformResponse: (response: {
        data: PutApiSettingsUpdateResponseBody;
      }) => {
        return response.data;
      },

      // We avoid refetching the list of workflows and jobs because it's expensive and there's no need.
      invalidatesTags: () => [],

      // Handle optimistic update instead
      async onQueryStarted({ id, updates }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listWorkflowsAndScheduledJobs",
            undefined,
            (draft) => {
              const itemIndex = draft.findIndex((item) => item.id === id);
              if (itemIndex !== -1) {
                draft[itemIndex] = {
                  ...draft[itemIndex],
                  ...updates,
                  // TODO: do we need updated time here?
                };
              }
            },
          ),
        );

        try {
          const { data: apiSettingsResp } = await queryFulfilled;
          dispatch(
            extendedApi.util.updateQueryData(
              "listWorkflowsAndScheduledJobs",
              undefined,
              (draft) => {
                const itemIndex = draft.findIndex((item) => item.id === id);
                if (itemIndex !== -1) {
                  draft[itemIndex].updated = new Date(apiSettingsResp.updated);
                }
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteWorkflowsOrJobs: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `v3/apis/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listWorkflowsAndScheduledJobs",
            undefined,
            (draft) => {
              const index = draft.findIndex((item) => item.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    renameWorkflowOrScheduledJob: builder.mutation<
      PatchApiMetadataResponseBody,
      { id: string; name: string; lastSuccessfulWrite: number | undefined }
    >({
      query: ({ id, name, lastSuccessfulWrite }) => ({
        url: `/v3/apis/${id}/metadata`,
        method: "PATCH",
        body: { metadata: { name }, lastSuccessfulWrite },
      }),
      transformResponse: (response: { data: PatchApiMetadataResponseBody }) => {
        return response.data;
      },
      invalidatesTags: () => [],
      async onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listWorkflowsAndScheduledJobs",
            undefined,
            (draft) => {
              const itemIndex = draft.findIndex((item) => item.id === id);
              if (itemIndex !== -1) {
                draft[itemIndex].name = name;
              }
            },
          ),
        );
        try {
          const { data: apiResponse } = await queryFulfilled;
          dispatch(
            extendedApi.util.updateQueryData(
              "listWorkflowsAndScheduledJobs",
              undefined,
              (draft) => {
                const itemIndex = draft.findIndex((item) => item.id === id);
                if (itemIndex !== -1) {
                  draft[itemIndex].updated = new Date(apiResponse.updated);
                }
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),

    updateScheduleState: builder.mutation<
      void,
      { id: string; scheduleState: ScheduleState }
    >({
      query: ({ id, scheduleState }) => ({
        url: `v3/scheduled-jobs/${id}/schedule-state`,
        method: "PUT",
        body: { scheduleState },
      }),
      invalidatesTags: () => [],
      async onQueryStarted(
        { id, scheduleState },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listWorkflowsAndScheduledJobs",
            undefined,
            (draft) => {
              const itemIndex = draft.findIndex((item) => item.id === id);
              if (itemIndex !== -1) {
                draft[itemIndex].scheduleState = scheduleState;
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useListWorkflowsAndScheduledJobsQuery,
  useUpdateWorkflowOrScheduledJobMutation,
  useDeleteWorkflowsOrJobsMutation,
  useRenameWorkflowOrScheduledJobMutation,
  useUpdateScheduleStateMutation,
} = extendedApi;
