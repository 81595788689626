import React, { useMemo, MouseEvent, useCallback, useState } from "react";
import { ReactComponent as RootFolder } from "assets/icons/home/folder-minus.svg";
import { ReactComponent as FolderIcon } from "assets/icons/home/folder.svg";
import { LinkButton } from "components/ui/Button";
import { UnderlinedLinkClass } from "components/ui/Link";
import { SearchableListPopup } from "components/ui/SearchableListPopup";
import useMoveApiToFolder from "hooks/api/useMoveApiToFolder";
import { useUpdateApplicationMetadataMutation } from "store/slices/reduxApi/applications";
import { useListFoldersQuery } from "store/slices/reduxApi/folders";
import { EntityType } from "utils/entity";
import logger, { stringifyError } from "utils/logger";
import { sendSuccessUINotification } from "utils/notification";
import { sendErrorUINotification } from "utils/notification";
import { EMPTY_FOLDER_ID } from "./MoveEntityModal";
import { Entity } from "./shared";

const SuccessMessage = ({
  entityName,
  targetFolderPath,
  targetFolderName,
  onUndo,
}: {
  entityName: string;
  targetFolderPath: string;
  targetFolderName: string;
  onUndo?: () => void;
}) => {
  const [isUndoing, setIsUndoing] = useState(false);

  const handleUndo = () => {
    if (!onUndo) return;
    setIsUndoing(true);
    onUndo();
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <div>
        <strong>{entityName}</strong> moved to{" "}
        <a
          href={targetFolderPath}
          className={UnderlinedLinkClass}
          target="_blank"
          rel="noreferrer"
        >
          {targetFolderName}
        </a>
      </div>

      {onUndo && !isUndoing && (
        <div>
          <LinkButton onClick={handleUndo} className={UnderlinedLinkClass}>
            Undo
          </LinkButton>
        </div>
      )}
    </div>
  );
};

export const MoveEntitySearchableList = ({ entity }: { entity: Entity }) => {
  const moveApiToFolder = useMoveApiToFolder();
  const { data: folders = [] } = useListFoldersQuery();
  const [updateApplicationMetadata] = useUpdateApplicationMetadataMutation();

  const entityFolderId = entity.folderId;
  const listItems = useMemo(() => {
    /** Remove current folder first */
    const filteredFolders = folders.filter(
      (folder) => folder.id !== entityFolderId,
    );

    const listItems = filteredFolders.map((folder) => ({
      label: folder.name,
      value: folder.id,
      icon: <FolderIcon />,
    }));

    if (entityFolderId) {
      listItems.unshift({
        label: "Home",
        value: EMPTY_FOLDER_ID,
        icon: <RootFolder />,
      });
    }

    return listItems;
  }, [folders, entityFolderId]);

  const handleMoveEntity = useCallback(
    async (
      newFolderId: string | null | undefined,
      showUndo: boolean = true,
    ) => {
      /**
       * Empty folder: `newFolderId` is `"null"` because it's explicitly being moved to the home folder,
       * or `newFolderId` is `null` or `undefined` which happens when undoing an action (`entity.folderId` is `null`)
       */
      const isHomeFolder = newFolderId === EMPTY_FOLDER_ID || !newFolderId;

      const targetFolder = isHomeFolder
        ? {
            id: null,
            path: "/",
            name: "Home",
          }
        : {
            name:
              folders.find((folder) => folder.id === newFolderId)?.name ?? "",
            id: newFolderId,
            path: `/folders/${newFolderId}`,
          };

      const handleUndo = () => {
        handleMoveEntity(entity.folderId, false);
      };

      try {
        switch (entity.type) {
          case EntityType.APPLICATION: {
            await updateApplicationMetadata({
              id: entity.id,
              folderId: targetFolder.id,
            }).unwrap();
            break;
          }
          case EntityType.WORKFLOW:
          case EntityType.SCHEDULED_JOB:
            await moveApiToFolder({ id: entity.id }, targetFolder.id).unwrap();
            break;
        }
        sendSuccessUINotification({
          message: (
            <SuccessMessage
              entityName={entity.name ?? ""}
              targetFolderPath={targetFolder.path}
              targetFolderName={targetFolder.name}
              onUndo={showUndo ? handleUndo : undefined}
            />
          ),
        });
      } catch (error) {
        logger.error(
          `Failed to move entity ${entity.id} into folder ${targetFolder.id}. ${stringifyError(error)}`,
        );

        sendErrorUINotification({
          message: `Failed to move ${entity.name} into folder ${targetFolder.name}.`,
        });
      }
    },
    [
      entity.type,
      entity.id,
      entity.folderId,
      entity.name,
      folders,
      moveApiToFolder,
      updateApplicationMetadata,
    ],
  );

  /** Prevent the sub-menu from closing when clicking on it */
  const handleContainerClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div onClick={handleContainerClick}>
      <SearchableListPopup
        id="move-entity-searchable-list"
        items={listItems}
        onItemSelect={handleMoveEntity}
        dataTest="move-entity-searchable-list"
      />
    </div>
  );
};
