import { Organization, ScheduleConfig } from "@superblocksteam/shared";
import { Input, InputRef, Modal } from "antd";
import React, { useEffect, useRef } from "react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  DefaultModalWidth,
  FooterWrapperWide,
  ModalInnerWrapper,
  ModalWrapClass,
} from "components/ui/Modal";
import { CREATE_WORKFLOWS } from "constants/rbac";
import { CREATE_JOBS } from "constants/rbac";
import { CREATE_APPLICATIONS } from "constants/rbac";
import { CreateApiSuccessOptions } from "hooks/api/useCreateNewApi";
import useCreateNewApi from "hooks/api/useCreateNewApi";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useDebounce } from "hooks/ui/useDebounce";
import { useEntityNameGenerator } from "hooks/ui/useEntityNameGenerator";
import {
  SCHEDULED_JOB_CONFIGURE_URL,
  WORKFLOW_CONFIGURE_URL,
} from "legacy/constants/routes";
import { useAppDispatch } from "store/helpers";
import { ApiTriggerType } from "store/slices/apis";
import { createApplicationInit } from "store/slices/homepage/slice";
import { EntityType } from "utils/entity";

type CreateEntityModalProps = {
  organization: Organization;
  isVisible: boolean;
  folderId?: string;
  onCancel: () => void;
};

type InternalCreateEntityModalProps = CreateEntityModalProps & {
  entityType: EntityType;
  title: string;
  onCreate: (entityName: string) => void;
};

const CreateEntityModal = (props: InternalCreateEntityModalProps) => {
  const [isCreatingEntity, setIsCreatingEntity] = useState(false);
  const [entityName, setEntityName] = useState("");
  const defaultEntityName = useEntityNameGenerator(
    props.entityType,
    props.isVisible,
  );

  const { onCreate, onCancel } = props;
  const handleCreate = useCallback(() => {
    if (isCreatingEntity) return;
    setIsCreatingEntity(true);

    const entityNameWithDefault =
      entityName.trim() === "" ? defaultEntityName : entityName;
    onCreate(entityNameWithDefault);
  }, [
    onCreate,
    entityName,
    defaultEntityName,
    setIsCreatingEntity,
    isCreatingEntity,
  ]);

  const debouncedHandleCreate = useDebounce(handleCreate, 2000, {
    leading: true,
    trailing: false,
  });

  const handleCancel = useCallback(() => {
    if (isCreatingEntity) return;
    onCancel();
  }, [isCreatingEntity, onCancel]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntityName(e.target.value);
    },
    [],
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        debouncedHandleCreate();
      }
    },
    [debouncedHandleCreate],
  );

  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      title={props.title}
      open={true}
      onCancel={handleCancel}
      destroyOnClose
      footer={null}
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
    >
      <div className={ModalInnerWrapper} onKeyDown={onKeyDown}>
        <div className={FormWrapper}>
          <FormItem label="Name">
            <Input
              ref={inputRef}
              type="text"
              placeholder={defaultEntityName}
              disabled={isCreatingEntity}
              value={entityName}
              onChange={handleInputChange}
            />
          </FormItem>
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            disabled={isCreatingEntity}
            data-test="cancel-integration-button"
            onClick={props.onCancel}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={isCreatingEntity}
            type="primary"
            data-test="save-integration-button"
            onClick={debouncedHandleCreate}
          >
            Create
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export const CreateApplicationModal = (props: CreateEntityModalProps) => {
  const dispatch = useAppDispatch();
  const [canCreateApps] = useAuthorizationCheck([CREATE_APPLICATIONS]);
  const handleCreate = useCallback(
    (applicationName: string) => {
      return dispatch(
        createApplicationInit({
          applicationName: applicationName,
          orgId: props.organization.id,
          folderId: props.folderId,
          navigateOpts: {
            replace: true, // avoid showing the create modal when navigating back
          },
        }),
      );
    },
    [dispatch, props.organization.id, props.folderId],
  );

  if (!canCreateApps) {
    return null;
  }

  return (
    <CreateEntityModal
      {...props}
      entityType={EntityType.APPLICATION}
      title="Create Application"
      onCreate={handleCreate}
    />
  );
};

export const CreateScheduledJobModal = (props: CreateEntityModalProps) => {
  const navigate = useNavigate();
  const createScheduledJob = useCreateNewApi(
    {
      triggerType: ApiTriggerType.SCHEDULE,
    },
    CreateApiSuccessOptions.NONE, // TODO: (kevinh) when we remove modals from route paths, use CreateApiSuccessOptions.OPEN_IN_SAME_TAB and remove navigate action
  );

  const [canCreateJobs] = useAuthorizationCheck([CREATE_JOBS]);

  const handleCreate = useCallback(
    async (jobName: string) => {
      const resp = await createScheduledJob({
        apiName: jobName,
        folder: props.folderId,
        schedule: ScheduleConfig.default(),
      });

      if (!resp) return;
      navigate(
        { pathname: SCHEDULED_JOB_CONFIGURE_URL(resp.id) },
        { replace: true }, // avoid showing the create modal when navigating back
      );
    },
    [navigate, createScheduledJob, props.folderId],
  );

  if (!canCreateJobs) {
    return null;
  }

  return (
    <CreateEntityModal
      {...props}
      entityType={EntityType.SCHEDULED_JOB}
      title="Create Scheduled Job"
      onCreate={handleCreate}
    />
  );
};

export const CreateWorkflowModal = (props: CreateEntityModalProps) => {
  const navigate = useNavigate();
  const createWorkflow = useCreateNewApi(
    {
      triggerType: ApiTriggerType.WORKFLOW,
    },
    CreateApiSuccessOptions.NONE, // TODO: (kevinh) when we remove modals from route paths, use CreateApiSuccessOptions.OPEN_IN_SAME_TAB and remove navigate action
  );

  const [canCreateWorkflows] = useAuthorizationCheck([CREATE_WORKFLOWS]);

  const handleCreate = useCallback(
    async (workflowName: string) => {
      const resp = await createWorkflow({
        apiName: workflowName,
        folder: props.folderId,
      });

      if (!resp) return;
      navigate(
        { pathname: WORKFLOW_CONFIGURE_URL(resp.id) },
        { replace: true }, // avoid showing the create modal when navigating back
      );
    },
    [navigate, createWorkflow, props.folderId],
  );

  if (!canCreateWorkflows) {
    return null;
  }

  return (
    <CreateEntityModal
      {...props}
      entityType={EntityType.WORKFLOW}
      title="Create Workflow"
      onCreate={handleCreate}
    />
  );
};
