import { takeLatest, ActionPattern } from "redux-saga/effects";
import {
  processAiActions,
  processAiProperties,
  updateAiChanges,
  updateAiDynamicProperties,
  removeChildInAiChanges,
  restoreDeletedChildInAiChanges,
  updateLayoutFromAi,
  clearAiChanges,
} from "../slice";
import { clearAiChangesSaga } from "./clearAiChanges";
import { processAiActionsSaga } from "./processAiActions";
import { processAiPropertiesSaga } from "./processAiProperties";
import { removeChildInAiChangesSaga } from "./removeChildInAiChangesSaga";
import { restoreDeletedChildInAiChangesSaga } from "./restoreDeletedChildInAiChanges";
import { updateAiChangesSaga } from "./updateAiChanges";
import { updateAiDynamicPropertiesSaga } from "./updateDynamicProperties";
import { updateLayoutFromAiSaga } from "./updateLayoutFromAi";

export function* aiSaga() {
  yield takeLatest(processAiActions.type, processAiActionsSaga);
  yield takeLatest(
    updateAiDynamicProperties.type,
    updateAiDynamicPropertiesSaga,
  );
  yield takeLatest(processAiProperties.type, processAiPropertiesSaga);
  yield takeLatest(updateAiChanges.type, updateAiChangesSaga);
  yield takeLatest(removeChildInAiChanges.type, removeChildInAiChangesSaga);
  yield takeLatest(
    restoreDeletedChildInAiChanges.type,
    restoreDeletedChildInAiChangesSaga,
  );
  yield takeLatest(
    clearAiChanges.type as ActionPattern<any>,
    clearAiChangesSaga,
  );
  yield takeLatest(updateLayoutFromAi.type, updateLayoutFromAiSaga);
}
