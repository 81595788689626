import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { Virtuoso } from "react-virtuoso";
import { DeleteModal } from "components/ui/Modal";
import TruncatedTextTooltip from "components/ui/TruncatedTextTooltip";
import { useDeleteFolderMutation } from "store/slices/reduxApi/folders";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { Entity, getEntityIcon } from "../shared";

const MAX_ITEMS_TO_SHOW_PER_PAGE = 5;
const MAX_ITEMS_LIST_HEIGHT = 178;
const HEIGHT_PER_ITEM = 40;

const EntityListStyle = styleAsClass`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.GREY_100};
  border-radius: 4px;
`;

const EntityItemStyle = styleAsClass`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${String(HEIGHT_PER_ITEM)}px;
  border-bottom: 1px solid ${colors.GREY_100};
  &[data-isLast="true"] {
    border-bottom: none;
  }
  .entity-index {
    width: 32px;
    padding: 12px;
    white-space: nowrap;
    padding-right: 0px;
  }
  .entity-name {
    padding: 12px;
    display: flex;
    gap: 8px;
  }
`;

const BoldText = styleAsClass`
  font-weight: 700 !important;
`;

const DeleteFolderModal = ({
  open,
  setIsOpen,
  folder,
  entities,
}: {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataTestName?: string;
  folder: Entity;
  entities: Entity[];
}) => {
  const navigate = useNavigate();

  const [deleteFolder, { isLoading: isDeleting }] = useDeleteFolderMutation();

  const entitiesInFolder = useMemo(() => {
    return entities.filter((entity) => entity.folderId === folder.id);
  }, [entities, folder.id]);

  const onDeleteFolder = useCallback(async () => {
    try {
      if (folder.id) {
        await deleteFolder(folder.id).unwrap();
        sendSuccessUINotification({
          message: "Folder deleted",
        });

        navigate("/home");
      }
    } catch (e: any) {
      sendErrorUINotification(e?.message ?? "Failed to delete folder");
    }
  }, [deleteFolder, folder, navigate]);

  const onCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <DeleteModal
      open={open}
      title="Delete folder"
      onCancel={onCancel}
      onDelete={onDeleteFolder}
      isDeleting={isDeleting}
      dataTestName={"delete-folder"}
      disableDelete={!folder}
    >
      <p>
        You are about to delete the <b className={BoldText}>{folder.name}</b>{" "}
        folder.{" "}
        {entitiesInFolder.length > 0 && (
          <>
            The folder and all of its{" "}
            <b className={BoldText}>{entitiesInFolder.length}</b> items will be
            deleted.
          </>
        )}
      </p>
      {entitiesInFolder.length > 0 && (
        <>
          <p style={{ marginBottom: "8px" }}>
            <b className={BoldText}>Items in this folder</b>
          </p>
          <Virtuoso
            style={{
              height:
                entitiesInFolder.length >= MAX_ITEMS_TO_SHOW_PER_PAGE
                  ? MAX_ITEMS_LIST_HEIGHT
                  : entitiesInFolder.length * HEIGHT_PER_ITEM,
            }}
            className={EntityListStyle}
            data={entitiesInFolder}
            itemContent={(index, entity) => (
              <div className={EntityItemStyle}>
                <div className="entity-index">{index + 1}</div>{" "}
                <div className="entity-name">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {getEntityIcon(entity.type)}
                  </div>
                  <div style={{ maxWidth: "270px" }}>
                    <TruncatedTextTooltip text={entity.name ?? ""} />
                  </div>
                </div>
              </div>
            )}
          />
        </>
      )}
    </DeleteModal>
  );
};

export default DeleteFolderModal;
