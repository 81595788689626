import { useSearchParams } from "react-router-dom";

export const useSearchParamsAsState = <T extends string>({
  key,
  defaultValue,
}: {
  key: string;
  defaultValue?: T;
}): [T, (value: T) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setter = (value: T) => {
    const newParams = new URLSearchParams(searchParams);
    if (value && value !== defaultValue) {
      newParams.set(key, value as string);
    } else {
      newParams.delete(key);
    }
    setSearchParams(newParams);
  };

  return [(searchParams.get(key) || defaultValue || "") as T, setter];
};
