import { ApiTriggerType, OAUTH_CALLBACK_PATH } from "@superblocksteam/shared";
import { EntityType } from "utils/entity";

export const HOME_URL = `/home`;
export const APPLICATIONS_URL = `/applications`;
export const OPA_URL = `/opas`;
export const SUPPORT_EMAIL_ADDRESS = `support@superblockshq.com`;
export const PAGE_VIEWER_URL = "/applications/:applicationId";
export const WORKFLOW_URL = "/workflows/:apiId";
export const SCHEDULED_JOB_URL = "/scheduled_jobs/:apiId";
export const AGENTS_BASE_URL = "/opas";
export const AGENTS_DEPLOYMENT_INSTRUCTIONS_URL = "/opas/instructions";
export const AUDIT_LOGS_BASE_URL = "/audit";
export const LOGOUT_URL = "/logout";
export const INTEGRATIONS_URL = "/integrations";

export const PROFILES_URL = "/profiles";
export const REPOSITORIES_URL = "/repositories";
export const TERMS_URL = "https://www.superblocks.com/legal/terms";
export const MSA_URL =
  "https://www.superblocks.com/legal/master-services-agreement";
export const PRICING_URL = "https://superblocks.com/pricing";
export const DOCS_URL = "https://docs.superblocks.com";
export const OAUTH_CALLBACK_URL = `/${OAUTH_CALLBACK_PATH}`;
export const OPA_LATEST_RELEASE_API_URL =
  "https://api.github.com/repos/superblocksteam/agent/releases/latest";
export const OPA_LATEST_RELEASE_PAGE_URL =
  "https://github.com/superblocksteam/agent/releases/latest";
export const CONTACT_SALES_URL = "/v1/billing/contact";
export const CHECKOUT_URL = "/checkout";
export const CHECKOUT_COMPLETE_URL = "/checkout/complete";
export const VIDEO_LIST_URL = "https://www.youtube.com/@Superblockshq/featured";
export const ACCESS_TOKENS_URL = "/access-tokens";
export const USERS_PAGE_URL = "/users";
export const GROUPS_PAGE_URL = "/groups";
export const GENERAL_PAGE_URL = "/general";

export enum DocsPage {
  ROOT = "",
  APPLICATION = "applications/overview",
  DEVELOPER_PREFERENCES = "applications/developer-preferences",
  SCHEDULED_JOBS = "scheduled-jobs/overview",
  WORKFLOWS = "workflows/overview",
  INTEGRATION_PROFILES = "development-lifecycle/profiles",
  GITSYNC_REPOSITORIES = "development-lifecycle/source-control",
  DEPLOY_GIT_SYNC = "development-lifecycle/source-control/setup/init-apps",
  ON_PREMISE_AGENT = "on-premise-agent/overview",
  ON_PREMISE_AGENT_USAGE = "on-premise-agent/deployment",
  RETURNING_JSON = "writing-code/python#returning-json",
  WHITELIST_DOC_LINK = "integrations/connection-options#ip-allowlists",
  TROUBLESHOOTING_CONNECTIONS = "integrations/troubleshooting/troubleshooting-connections",
  FIVE_MIN_GUIDE_URL = "getting-started/5-min-quickstart-guide",
  VERSION_CONTROL = "development-lifecycle/version-control",
  VERSION_CONTROL_DEPLOY = "development-lifecycle/version-control#deploy",
  // TODO: Update this to public cli doc once we make personal settings GA
  SUPERBLOCKS_CLI = "applications/custom-components#1-install-the-superblocks-cli",
}

export enum HomeModalRoutes {
  DEMO_VIDEO = "demo-video",

  CREATE = "create",

  MOVE = "move",
  REMOVE = "remove",
  RENAME = "rename",

  CREATE_FOLDER = "create-folder",
  RENAME_FOLDER = "rename-folder",

  INVITE_USER = "invite",

  // Homepage v2
  CREATE_APPLICATION = "create-application",
  DELETE_APPLICATION = "delete-application",
  RENAME_APPLICATION = "rename-application",
  CREATE_SCHEDULED_JOB = "create-scheduled-job",
  RENAME_SCHEDULED_JOB = "rename-scheduled-job",
  DELETE_SCHEDULED_JOB = "delete-scheduled-job",
  CREATE_WORKFLOW = "create-workflow",
  RENAME_WORKFLOW = "rename-workflow",
  DELETE_WORKFLOW = "delete-workflow",
  DELETE_FOLDER = "delete-folder",
  SHARE_PERMISSIONS = "share-permissions",
}

export enum ApplicationRoute {
  Base = "applications/*",
  Preview = "preview/:applicationId/*",
  Viewer = ":applicationId/*",

  DeprecatedViewer = ":applicationId/pages/:pageId",
  DeprecatedPreview = ":applicationId/pages/:pageId/preview",
  DeprecatedPageEdit = ":applicationId/pages/:pageId/edit/*",
}

export enum EmbedRoute {
  Base = "embed/applications/*",
}

export enum EditorRoute {
  EditApplication = "edit/:applicationId/*",

  EditApi = "sb_tab/API/:apiId/*",
  EditApiActionBase = "sb_tab/API/:apiId/action/*",
  EditApiAction = "sb_tab/API/:apiId/action/:actionId/*",
  EditApiInputs = "sb_tab/API/:apiId/inputs/*",
  EditApiResponse = "sb_tab/API/:apiId/response/*",

  EditEntityProperty = "sb_tab/:entityType/:entityId/property/:property/*",

  DeprecatedEditApi = "apis/*",
  DeprecatedEditApiAction = "apis/:apiId/action/:actionId/*",
  DeprecatedEditApiInputs = "apis/:apiId/inputs/*",
  DeprecatedEditApiResponse = "apis/:apiId/response/*",
}

export const CREATE_MODAL_URL = (entityType?: EntityType) => {
  return `${HOME_MODAL_URL(
    HomeModalRoutes.CREATE,
  )}?create-entity-type=${entityType}`;
};

export const HOME_MODAL_URL = (modal: HomeModalRoutes) =>
  `${HOME_URL}/${modal}`;

export const DOCS_PAGE_URL = (page: DocsPage): string => `${DOCS_URL}/${page}`;

export type BuilderRouteParams = {
  applicationId: string;
  pageId?: string;
};

export type AppViewerRouteParams = {
  applicationId?: string;
  pageId?: string;
};

export type QueryEditorRouteParams = {
  applicationId: string;
  apiId: string;
  actionId: string;
};

export type EditorRouteBottomPanelParams = BuilderRouteParams & {
  apiId?: string;
  actionId?: string;
  entityId?: string;
  property?: string;
  entityType?: string;
  "*"?: string;
};

export type WorkflowEditorRouteParams = {
  apiId: string;
  actionId: string;
};

const BUILDER_BASE_URL = (
  applicationId = ":applicationId",
  currentRoute = "",
): string => `/applications/edit/${applicationId}${currentRoute}`;

export const BUILDER_APP_ID_URL = (
  applicationId?: string,
  currentRoute?: string,
  params?: Record<string, string>,
): string => {
  const queryParams = convertToQueryParams(params);
  return `${BUILDER_BASE_URL(applicationId, currentRoute)}` + queryParams;
};

export const BUILDER_PAGE_URL = BUILDER_APP_ID_URL;

export const BUILDER_PAGE_TAB_API_URL = (
  applicationId = ":applicationId",
  apiId = ":apiId",
  currentRoute = "",
  params?: Record<string, string>,
): string => {
  const queryparams = convertToQueryParams(params);
  return `${BUILDER_PAGE_URL(
    applicationId,
  )}/sb_tab/API/${apiId}${currentRoute}${queryparams}`;
};

export const BUILDER_PAGE_TAB_API_EMPTY_ACTION_URL = (
  applicationId = ":applicationId",
  apiId = ":apiId",
  currentRoute = "",
  params?: Record<string, string>,
): string => {
  const queryparams = convertToQueryParams(params);
  return `${BUILDER_PAGE_TAB_API_URL(
    applicationId,
    apiId,
  )}/action${currentRoute}${queryparams}`;
};

export const BUILDER_PAGE_TAB_API_ACTION_URL = (
  applicationId = ":applicationId",
  apiId = ":apiId",
  actionId = ":actionId",
  currentRoute = "",
  params?: Record<string, string>,
): string => {
  const queryparams = convertToQueryParams(params);
  return `${BUILDER_PAGE_TAB_API_URL(
    applicationId,
    apiId,
  )}/action/${actionId}${currentRoute}${queryparams}`;
};

export const BUILDER_PAGE_TAB_API_INPUTS_URL = (
  applicationId = ":applicationId",
  apiId = ":apiId",
  currentRoute = "",
  params?: Record<string, string>,
): string => {
  const queryparams = convertToQueryParams(params);
  return `${BUILDER_PAGE_TAB_API_URL(
    applicationId,
    apiId,
  )}/inputs${currentRoute}${queryparams}`;
};

export const BUILDER_PAGE_TAB_API_RESPONSE_URL = (
  applicationId = ":applicationId",
  apiId = ":apiId",
  currentRoute = "",
  params?: Record<string, string>,
): string => {
  const queryparams = convertToQueryParams(params);
  return `${BUILDER_PAGE_TAB_API_URL(
    applicationId,
    apiId,
  )}/response${currentRoute}${queryparams}`;
};

export const BUILDER_PAGE_TAB_ITEM_KIND_PROPERTY_URL = (
  applicationId = ":applicationId",
  entityType = ":entityType",
  entityId = ":entityId",
  property = ":property",
  currentRoute = "",
  params?: Record<string, string>,
): string => {
  const queryparams = convertToQueryParams(params);
  return `${BUILDER_PAGE_URL(
    applicationId,
  )}/sb_tab/${entityType}/${entityId}/property/${property}${currentRoute}${queryparams}`;
};

export const WORKFLOW_EDITOR_URL = (
  apiId = ":apiId",
  actionId = ":actionId",
): string => `/workflows/${apiId}/action/${actionId}`;

export const SCHEDULED_JOB_EDITOR_URL = (
  apiId = ":apiId",
  actionId = ":actionId",
): string => `/scheduled_jobs/${apiId}/action/${actionId}`;

const removeTrailingSlash = (url: string) =>
  url.endsWith("/") ? url.slice(0, -1) : url;

export const WORKFLOW_EXECUTE_URL = (
  params:
    | {
        agentUrl: string;
        apiId?: string;
      }
    | {
        orchestratorUrl: string;
        apiId?: string;
      },
): string => {
  const apiId = params.apiId || ":apiId";
  if ("orchestratorUrl" in params) {
    if (params.orchestratorUrl.startsWith("/")) {
      return `http://localhost:3000${removeTrailingSlash(
        params.orchestratorUrl,
      )}/v2/execute/${apiId}`;
    }
    return `${removeTrailingSlash(params.orchestratorUrl)}/v2/execute/${apiId}`;
  } else {
    return `${removeTrailingSlash(params.agentUrl)}/v1/workflows/${apiId}`;
  }
};

export const WORKFLOW_CONFIGURE_URL = (apiId = ":apiId"): string =>
  `/workflows/${apiId}/configure`;

export const SCHEDULED_JOB_CONFIGURE_URL = (apiId = ":apiId"): string =>
  `/scheduled_jobs/${apiId}/configure`;

export const getWorkflowBaseURL = (apiId: string): string =>
  `/workflows/${apiId}/`;

export const getScheduledJobBaseURL = (apiId: string): string =>
  `/scheduled_jobs/${apiId}/`;

export const getApplicationDeployedURL = (
  applicationId = ":applicationId",
  currentRoute = "",
  params: Record<string, string> = {},
): string => {
  const url = `/applications/${applicationId}${currentRoute}`;
  const queryParams = convertToQueryParams(params);
  return url + queryParams;
};

export const APP_EMBED_URL =
  "/embed" + getApplicationDeployedURL(":applicationId", "/*");

export const getApplicationPreviewURL = (
  applicationId = ":applicationId",
  currentRoute = "",
  params: Record<string, string> = {},
): string => {
  const url = `/applications/preview/${applicationId}${currentRoute}`;
  const queryParams = convertToQueryParams(params);
  return url + queryParams;
};

export const APP_EMBED_PREVIEW_URL =
  "/embed" + getApplicationPreviewURL(":applicationId", "/*");

export const getApplicationEmbedURL = (
  applicationId = ":applicationId",
  currentRoute = "",
  params: Record<string, string> = {},
): string => {
  const url = `/embed/applications/${applicationId}${currentRoute}`;
  const queryParams = convertToQueryParams(params);
  return url + queryParams;
};

export const getApplicationEmbedPreviewURL = (
  applicationId = ":applicationId",
  currentRoute = "",
  params: Record<string, string> = {},
): string => {
  const url = `/embed/applications/preview/${applicationId}${currentRoute}`;
  const queryParams = convertToQueryParams(params);
  return url + queryParams;
};

export function convertToQueryParams(
  params: Record<string, string> = {},
): string {
  const paramKeys = Object.keys(params);
  const queryParams: string[] = [];
  if (paramKeys) {
    paramKeys.forEach((paramKey: string) => {
      const value = params[paramKey];
      if (paramKey && value) {
        queryParams.push(`${paramKey}=${value}`);
      }
    });
  }
  return queryParams.length ? "?" + queryParams.join("&") : "";
}

export function getApiActionUrl(
  applicationId: string,
  apiId: string,
  actionId: string,
  triggerType?: ApiTriggerType,
): string {
  switch (triggerType) {
    case ApiTriggerType.UI:
      return BUILDER_PAGE_TAB_API_ACTION_URL(applicationId, apiId, actionId);
    case ApiTriggerType.WORKFLOW:
      return WORKFLOW_EDITOR_URL(apiId, actionId);
    case ApiTriggerType.SCHEDULE:
      return SCHEDULED_JOB_EDITOR_URL(apiId, actionId);
    default:
      return "";
  }
}
