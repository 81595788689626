import {
  DEMO_ENTITIES_FOLDER_NAME,
  FolderDataDto,
  FolderDto,
} from "@superblocksteam/shared";
import { reduxApiSlice } from "./index";

const extendedApi = reduxApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listFolders: builder.query<FolderDto[], void>({
      query: () => `v1/folders/`,
      transformResponse: (response: { data: FolderDto[] }) => {
        return response.data.sort((a, b) => {
          if (a.name === DEMO_ENTITIES_FOLDER_NAME) {
            return 1;
          } else if (b.name === DEMO_ENTITIES_FOLDER_NAME) {
            return -1;
          } else {
            return (a.name ?? "").localeCompare(b.name ?? "");
          }
        });
      },
    }),
    createFolder: builder.mutation<FolderDto, FolderDataDto>({
      query: (folderData) => ({
        url: `v1/folders/`,
        method: "POST",
        body: { folder: folderData },
      }),

      transformResponse: (response: { data: FolderDto }) => {
        return response.data;
      },

      async onQueryStarted(folderData, { dispatch, queryFulfilled }) {
        // Create temporary ID for optimistic update
        const tempId = `temp-${Date.now()}`;

        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listFolders",
            undefined,
            (draft) => {
              const optimisticFolder: FolderDto = {
                ...folderData,
                id: tempId,
                organizationId: "temp-org-id",
                updated: new Date(),
              };
              draft.push(optimisticFolder);
            },
          ),
        );

        try {
          const { data: newFolder } = await queryFulfilled;
          dispatch(
            extendedApi.util.updateQueryData(
              "listFolders",
              undefined,
              (draft) => {
                const index = draft.findIndex((f) => f.id === tempId);
                if (index !== -1) {
                  draft[index] = newFolder;
                }
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    renameFolder: builder.mutation<
      FolderDto,
      { folderId: string; newName: string }
    >({
      query: ({ folderId, newName }) => ({
        url: `v1/folders/${folderId}`,
        method: "PUT",
        body: { folder: { name: newName } },
      }),
      transformResponse: (response: { data: FolderDto }) => {
        return response.data;
      },
      async onQueryStarted(
        { folderId, newName },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listFolders",
            undefined,
            (draft) => {
              const index = draft.findIndex((f) => f.id === folderId);
              if (index !== -1) {
                draft[index].name = newName;
              }
            },
          ),
        );

        try {
          const { data: newFolder } = await queryFulfilled;
          dispatch(
            extendedApi.util.updateQueryData(
              "listFolders",
              undefined,
              (draft) => {
                const index = draft.findIndex((f) => f.id === folderId);
                if (index !== -1) {
                  draft[index] = {
                    ...draft[index],
                    ...newFolder,
                  };
                }
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteFolder: builder.mutation<FolderDto, string>({
      query: (folderId) => ({
        url: `v1/folders/${folderId}`,
        method: "DELETE",
      }),
      async onQueryStarted(folderId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listFolders",
            undefined,
            (draft) => {
              const index = draft.findIndex((f) => f.id === folderId);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useListFoldersQuery,
  useCreateFolderMutation,
  useRenameFolderMutation,
  useDeleteFolderMutation,
} = extendedApi;
