import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { WIDGET_STATIC_PROPS } from "legacy/widgets/shared";

export const getLayoutChangesByWidgetId = (
  dataTreeChanges: Record<string, unknown>,
  existingWidgets?: CanvasWidgetsReduxState,
) => {
  // look through dataTreeChanges and dependentChangesByWidgetId and see if any of the keys are in LAYOUT_PROPERTIES
  // if they are, add them to layoutChangesByWidgetId
  const layoutChangesByWidgetId: Record<string, Record<string, unknown>> = {};
  const preAiLayoutValuesByWidgetId: Record<
    string,
    Record<string, unknown>
  > = {};

  Object.entries(dataTreeChanges ?? {}).forEach(([widgetId, changes]) => {
    const typedChanges = changes as Record<string, unknown>;
    Object.keys(typedChanges).forEach((key) => {
      if (WIDGET_STATIC_PROPS[key as keyof typeof WIDGET_STATIC_PROPS]) {
        layoutChangesByWidgetId[widgetId] = {
          ...(layoutChangesByWidgetId[widgetId] ?? {}),
          [key]: typedChanges[key],
        };
        if (existingWidgets) {
          preAiLayoutValuesByWidgetId[widgetId] = {
            ...(preAiLayoutValuesByWidgetId[widgetId] ?? {}),
            [key]: (existingWidgets as any)[widgetId][key],
          };
        }
      }
    });
  });
  return {
    layoutChangesByWidgetId,
    preAiLayoutValuesByWidgetId,
  };
};
