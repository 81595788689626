import { set, get } from "lodash";
import { call, put, select } from "redux-saga/effects";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import { WidgetProps } from "legacy/widgets";
import { AllFlags, Flag, selectFlags } from "store/slices/featureFlags";
import { AppState } from "store/types";
import { fastClone } from "utils/clone";
import { getLayoutChangesByWidgetId } from "../layoutUtils";
import { getMenuMetadataChanges } from "../menuUtils";
import { selectAiState } from "../selectors";
import { updateAiChanges, setAiChanges } from "../slice";
import { EditMetadata } from "../types";
import { applyWidgetHooksToAiEdits } from "./utils";

const getMetadataChanges = (
  properties: any,
  updates: Record<string, unknown>,
  aiState: AppState["ai"],
  widget: WidgetProps,
): Partial<EditMetadata> => {
  switch (properties.type) {
    case WidgetTypes.MENU_WIDGET:
      return getMenuMetadataChanges(properties, updates, aiState, widget);
    default:
      return {};
  }
};

export function* updateAiChangesSaga(
  action: ReturnType<typeof updateAiChanges>,
) {
  const aiState: ReturnType<typeof selectAiState> = yield select(selectAiState);
  const {
    changedKeys,
    dataTreeChanges,
    widgetRename,
    discardedEdits,
    selectedWidgetId,
    metadataByWidgetId,
  } = aiState;
  const widget: ReturnType<typeof getWidget> = yield select((state) =>
    getWidget(state, selectedWidgetId ?? ""),
  );

  if ("rename" in action.payload) {
    yield put(
      setAiChanges({
        changedKeys: changedKeys || {},
        dataTreeChanges: dataTreeChanges || {},
        rename: action.payload.rename,
        discardedEdits: discardedEdits || [],
      }),
    );
  } else {
    const { updates, properties } = action.payload;
    const id = properties.widgetId as string;
    if (id !== selectedWidgetId) {
      // update additional changes
      yield put(
        setAiChanges({
          changedKeys: changedKeys || {},
          dataTreeChanges: {
            ...dataTreeChanges,
            [id]: updates,
          },
          discardedEdits: discardedEdits || [],
        }),
      );
      return;
    }

    const metadataChanges = getMetadataChanges(
      properties,
      updates,
      aiState,
      widget,
    );
    const featureFlags: Partial<AllFlags> = yield select(selectFlags);
    const changesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      getItemPropertyPaneConfig(properties.type as WidgetType),
      updates,
      featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    const newDataTreeChanges = dataTreeChanges
      ? fastClone(dataTreeChanges)
      : {};

    const newDataTreeChangesForSelectedWidget = fastClone(
      dataTreeChanges?.[id] || {},
    );

    const newChangedKeys = changedKeys ? fastClone(changedKeys[id]) : [];
    Object.entries(changesWithBindings).forEach(([key, value]) => {
      set(newDataTreeChangesForSelectedWidget, key, value);
      if (!newChangedKeys.includes(key)) {
        newChangedKeys.push(key);
      }
    });
    const existingWidget: ReturnType<typeof getWidget> = yield select((state) =>
      getWidget(state, selectedWidgetId ?? ""),
    );
    yield call(applyWidgetHooksToAiEdits, {
      changes: newDataTreeChangesForSelectedWidget,
      existingWidget,
    });

    // Filter out undefined text style properties from newChangedKeys
    // to ensure we don't show inline text style properties that are not in aiEdits
    // this is for when a user manually changes to a variant from a custom variant that
    // was previously set by the AI
    let hasCustomTextStyleVariantUpdate = false;
    let hasTextStyleUpdate = false;
    for (const [key, value] of Object.entries(updates)) {
      if (key.includes("textStyle.variant") && value === SB_CUSTOM_TEXT_STYLE) {
        hasCustomTextStyleVariantUpdate = true;
      }
      if (key.includes("textStyle.variant") && value !== undefined) {
        hasTextStyleUpdate = true;
      }
    }

    const filteredNewChangedKeys = newChangedKeys.filter(
      (dottedPropertyPath: string) => {
        const isANonVariantTextStyleProperty =
          dottedPropertyPath.includes("textStyle.") &&
          !dottedPropertyPath.includes("textStyle.variant");

        if (
          hasTextStyleUpdate &&
          isANonVariantTextStyleProperty &&
          updates &&
          (get(updates, dottedPropertyPath) === undefined ||
            hasCustomTextStyleVariantUpdate)
        ) {
          return false;
        }
        return true;
      },
    );

    const { layoutChangesByWidgetId } =
      getLayoutChangesByWidgetId(newDataTreeChanges);

    yield put(
      setAiChanges({
        changedKeys: {
          ...changedKeys,
          [id]: filteredNewChangedKeys,
        },
        dataTreeChanges: {
          ...newDataTreeChanges,
          [id]: newDataTreeChangesForSelectedWidget,
        },
        rename: widgetRename,
        discardedEdits: discardedEdits ?? [],
        layoutChangesByWidgetId,
        metadataByWidgetId: {
          ...metadataByWidgetId,
          [id]: {
            ...(metadataByWidgetId?.[id] ?? {}),
            ...metadataChanges,
          } as EditMetadata,
        },
      }),
    );
  }
}
