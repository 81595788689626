import { FolderDto } from "@superblocksteam/shared";
import { Form, Input, Modal, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "store/helpers";
import { ApiDto } from "store/slices/apis/types";
import { updateApplicationMetadata } from "store/slices/application/applicationActions";
import { createFolder } from "store/slices/folders/client";
import { EntityType } from "utils/entity";
import logger from "utils/logger";
import { Entity } from "./shared";

type MoveEntityModalProps = {
  movingEntity: Entity | undefined;
  setMovingEntity: (entity: Entity | undefined) => void;
  updateWorkflow: (apiId: string, updateFn: (api: ApiDto) => void) => void;
  updateJob: (apiId: string, updateFn: (api: ApiDto) => void) => void;
  refreshFolders: () => void;
  folders: FolderDto[];
};

const NEW_FOLDER_ID = "superblocks_new_folder_name";
export const EMPTY_FOLDER_ID = "null";

/**
 * Deprecated. Remove once we remove the legacy home page.
 */

const MoveEntityModal = ({
  movingEntity,
  setMovingEntity,
  updateWorkflow,
  updateJob,
  folders,
  refreshFolders,
}: MoveEntityModalProps) => {
  const [movingEntityForm] = Form.useForm();

  const dispatch = useAppDispatch();

  const handleEntityMove = useCallback(() => {
    if (!movingEntity) {
      return;
    }
    movingEntityForm
      .validateFields()
      .then((values) => {
        const moveEntity = (folderId: string) => {
          let destinationFolderId: string | null = folderId;
          if (folderId === EMPTY_FOLDER_ID) destinationFolderId = null;

          const updateApi = (api: ApiDto): void => {
            api.folderId = destinationFolderId;
            if (api?.apiPb?.metadata) {
              api.apiPb.metadata.folder = destinationFolderId ?? "";
            }
          };

          switch (movingEntity.type) {
            case EntityType.APPLICATION:
              dispatch(
                updateApplicationMetadata(movingEntity.id, {
                  folderId: destinationFolderId,
                }),
              );
              break;
            case EntityType.WORKFLOW:
              updateWorkflow(movingEntity.id, updateApi);
              break;
            case EntityType.SCHEDULED_JOB:
              updateJob(movingEntity.id, updateApi);
              break;
          }
        };
        if (values.destination === NEW_FOLDER_ID) {
          createFolder({ name: values.name }).then((folder) => {
            moveEntity(folder.id);
            refreshFolders();
          });
        } else {
          moveEntity(values.destination);
        }

        movingEntityForm.resetFields();
      })
      .catch((info) => {
        logger.debug("Validate Failed:", info);
      })
      .finally(() => setMovingEntity(undefined));
  }, [
    movingEntity,
    movingEntityForm,
    dispatch,
    updateWorkflow,
    updateJob,
    refreshFolders,
    setMovingEntity,
  ]);

  const [creatingNewFolder, setCreatingNewFolder] = useState(false);
  useEffect(() => {
    if (movingEntity === undefined) {
      setMovingEntity(undefined);
    }
  }, [movingEntity, setMovingEntity]);

  return (
    <Modal
      title={`Move "${movingEntity?.name ?? "unknown"}" to another folder`}
      open={true}
      onOk={() => {
        handleEntityMove();
        setMovingEntity(undefined);
        setCreatingNewFolder(false);
      }}
      onCancel={() => {
        movingEntityForm.resetFields();
        setMovingEntity(undefined);
        setCreatingNewFolder(false);
      }}
      okText="Move"
      destroyOnClose
    >
      <Form
        form={movingEntityForm}
        layout="vertical"
        initialValues={{
          destination: folders
            .map((f) => f.id)
            .includes(movingEntity?.folderId ?? "")
            ? (movingEntity?.folderId ?? "")
            : EMPTY_FOLDER_ID,
        }}
      >
        <Form.Item
          label="Choose folder"
          name="destination"
          rules={[
            {
              required: true,
              message: "Please choose a folder to move it to",
            },
          ]}
        >
          <Select
            autoFocus
            showSearch
            options={[
              { id: EMPTY_FOLDER_ID, name: "No Folder" },
              ...folders,
              { id: NEW_FOLDER_ID, name: "New Folder..." },
            ].map((folder) => ({ value: folder.id, label: folder.name }))}
            onSelect={(value) => setCreatingNewFolder(value === NEW_FOLDER_ID)}
            filterOption={(input, option) => {
              const label = option?.label as string;
              return label?.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Form.Item>
        {creatingNewFolder && (
          <>
            <Form.Item
              name="name"
              label="Folder name"
              rules={[
                {
                  required: true,
                  message: "Please input a valid application name",
                },
              ]}
            >
              <Input placeholder="Folder name..." />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default MoveEntityModal;
