import { merge } from "lodash";
import { put, select } from "redux-saga/effects";
import { updatePartialLayout } from "legacy/actions/pageActions";
import { getWidgets } from "legacy/selectors/sagaSelectors";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { fastClone } from "utils/clone";
import { selectAiState } from "../selectors";
import { resetAiState, resetLayoutChanges } from "../slice";

export function* clearAiChangesSaga(action: {
  payload: { isAccept?: boolean; shouldClose: boolean };
}) {
  // clear out ai state
  yield put(resetAiState({ shouldClose: action.payload.shouldClose }));

  if (!action.payload.isAccept) {
    const aiState: ReturnType<typeof selectAiState> =
      yield select(selectAiState);
    const preAiLayoutValuesByWidgetId = aiState.preAiLayoutValuesByWidgetId;
    if (
      !preAiLayoutValuesByWidgetId ||
      !Object.keys(preAiLayoutValuesByWidgetId).length
    ) {
      return;
    }

    // get canvas widgets (without AI changes)
    const canvasWidgets: CanvasWidgetsReduxState = yield select(getWidgets);
    const editableWidgets = fastClone(canvasWidgets);

    // apply preAiLayoutValuesByWidgetId to editableWidgets
    const updatedWidgets = merge(
      editableWidgets,
      aiState.preAiLayoutValuesByWidgetId,
    );

    yield put(updatePartialLayout(updatedWidgets, false));
  }

  // now clear out layout changes
  yield put(resetLayoutChanges());
}
