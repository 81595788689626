import {
  SCHEDULED_JOB_CONFIGURE_URL,
  SCHEDULED_JOB_EDITOR_URL,
} from "legacy/constants/routes";

export const getJobUrl = (
  apiId: string,
  actionId: string | undefined,
): string => {
  if (actionId) {
    return SCHEDULED_JOB_EDITOR_URL(apiId, actionId);
  }
  return SCHEDULED_JOB_CONFIGURE_URL(apiId);
};
