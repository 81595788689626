import { useCallback } from "react";
import React from "react";
import { DeleteModal } from "components/ui/Modal";
import { useDeleteApplicationMutation } from "store/slices/reduxApi/applications";
import { styleAsClass } from "styles/styleAsClass";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { Entity } from "../shared";

const BoldText = styleAsClass`
  font-weight: 700 !important;
`;

const DeleteApplicationModal = ({
  open,
  closeModal,
  entity,
}: {
  open: boolean;
  closeModal: () => void;
  entity: Entity | undefined;
}) => {
  const appId = entity?.id;

  const [deleteApplication, { isLoading: isDeleting }] =
    useDeleteApplicationMutation();

  const handleApplicationDelete = useCallback(async () => {
    if (!appId) {
      return;
    }
    try {
      await deleteApplication({ id: appId });
      sendSuccessUINotification({
        message: "Application deleted",
      });
    } catch (e: any) {
      sendErrorUINotification({
        message: e?.message ?? "Failed to delete application",
      });
    } finally {
      closeModal();
    }
  }, [appId, closeModal, deleteApplication]);

  const handleCancel = useCallback(() => {
    if (isDeleting) return;
    closeModal();
  }, [isDeleting, closeModal]);

  return (
    <DeleteModal
      open={open}
      title="Delete Application"
      onCancel={handleCancel}
      onDelete={handleApplicationDelete}
      isDeleting={isDeleting}
      dataTestName="application"
      confirmText="Delete"
      disableDelete={false}
    >
      <p>
        You are about to delete <b className={BoldText}>{entity?.name}</b>. The
        app will become inaccessible to all users.
      </p>
      <p>Are you sure you want to continue?</p>
    </DeleteModal>
  );
};

export default DeleteApplicationModal;
