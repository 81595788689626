import { useCallback } from "react";
import React from "react";
import { useUpdateApplicationMetadataMutation } from "store/slices/reduxApi/applications";
import RenameEntityModal, { RenameEntityModalProps } from "./RenameEntityModal";

export const RenameApplicationModal = ({
  entity,
  open,
  closeModal,
}: RenameEntityModalProps) => {
  const appId = entity?.id;
  const [updateApplicationMetadata] = useUpdateApplicationMetadataMutation();

  const handleApplicationRename = useCallback(
    async (newName: string) => {
      if (!appId) {
        return;
      }
      await updateApplicationMetadata({
        id: appId,
        name: newName,
      });
    },
    [appId, updateApplicationMetadata],
  );

  return (
    <RenameEntityModal
      open={open}
      closeModal={closeModal}
      entity={entity}
      onRename={handleApplicationRename}
      title="Enter a new application name"
      entityLabel="application"
    />
  );
};

export default RenameApplicationModal;
