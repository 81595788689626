import { Input, InputRef, Modal } from "antd";
import React, { useCallback, useRef, useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  ModalWrapClass,
  FooterWrapperWide,
  ModalInnerWrapper,
} from "components/ui/Modal";
import { useDebounce } from "hooks/ui/useDebounce";
import { useRenameFolderMutation } from "store/slices/reduxApi/folders";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { Entity } from "../shared";

const RenameFolderModal = ({
  open,
  setIsOpen,
  folder,
}: {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  folder: Entity;
}) => {
  const [newName, setNewName] = useState(folder.name ?? "");
  const [isDirty, setIsDirty] = useState(false);
  undefined;

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
    setIsDirty(true);
  }, []);

  const [renameFolder, { isLoading: isRenaming }] = useRenameFolderMutation();

  const handleRename = useCallback(async () => {
    if (isRenaming) return;

    try {
      if (folder) {
        await renameFolder({
          folderId: folder.id,
          newName,
        }).unwrap();
        sendSuccessUINotification({
          message: "Folder renamed",
        });
        setIsDirty(false);
        setIsOpen(false);
      }
    } catch (e: any) {
      sendErrorUINotification({
        message: e?.message ?? "Failed to rename folder",
      });
    }
  }, [folder, newName, renameFolder, setIsOpen, isRenaming]);

  const debouncedHandleRename = useDebounce(handleRename, 2000, {
    leading: true,
    trailing: false,
  });

  const onCancel = useCallback(() => {
    if (isRenaming) return;
    setIsOpen(false);
  }, [setIsOpen, isRenaming]);

  const isNameValid = newName.trim().length > 0;

  const validationError =
    !isDirty || isNameValid ? null : "Folder name can't be blank";

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        debouncedHandleRename();
      }
    },
    [debouncedHandleRename],
  );

  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      width={384}
      wrapClassName={ModalWrapClass}
      open={open}
      onCancel={onCancel}
      footer={null}
      title={"Rename folder"}
      destroyOnClose={true}
    >
      <div className={ModalInnerWrapper} onKeyDown={onKeyDown}>
        <div className={FormWrapper}>
          <FormItem
            label="Enter a new folder name"
            error={validationError}
            required
          >
            <Input
              value={newName}
              onChange={onNameChange}
              disabled={isRenaming}
              data-test={`rename-folder-input`}
              ref={inputRef}
            />
          </FormItem>
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            onClick={onCancel}
            disabled={isRenaming}
            data-test={`cancel-rename-folder`}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={debouncedHandleRename}
            loading={isRenaming}
            data-test={`confirm-rename-folder`}
            disabled={!newName.trim() || !folder}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default RenameFolderModal;
