import { buffers } from "@redux-saga/core";
import { Channel } from "@redux-saga/types";
import * as Sentry from "@sentry/react";
import { VersionedEntityType } from "@superblocksteam/shared";
import { get, isEmpty } from "lodash";
import moment from "moment";
import {
  actionChannel,
  all,
  call,
  delay,
  flush,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
} from "redux-saga/effects";
import {
  deleteWidgetProperty,
  renameWidgets,
  setSingleWidget,
  renameEmbedProps,
  updateLocalWidgets,
} from "legacy/actions/controlActions";
import {
  pageLoadActionsComplete,
  restartEvaluation,
  startEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  resetWidgetMetaProperty,
  setMetaProp,
  setMetaProps,
} from "legacy/actions/metaActions";
import {
  createRoute,
  pageLoadSuccess,
  requestPageSave,
  updateLayout,
  updatePartialLayout,
} from "legacy/actions/pageActions";
import {
  deleteTableRows,
  evaluateBindings,
  insertTableRows,
  runEventHandlersError,
  updateTableRows,
} from "legacy/actions/widgetActions";
import { Toaster } from "legacy/components/ads/Toast";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { getEntityCount } from "legacy/entities/DataTree/DataTreeHelpers";
import {
  DataTree,
  DataTreeMetaPaths,
} from "legacy/entities/DataTree/dataTreeFactory";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { MetaState } from "legacy/reducers/entityReducers/metaReducer";
import { EvaluationType } from "legacy/reducers/evaluationReducers/evaluationTypeReducer";
import { type SetEvaluatedTreeAction } from "legacy/reducers/evaluationReducers/treeReducer";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getUnevaluatedDataTree } from "legacy/selectors/dataTreeSelectors";
import { getApplicationSidebarKey } from "legacy/selectors/editorPreferencesSelector";
import {
  getCurrentApplicationId,
  getIsFetchingPage,
  getIsLeftPanePinned,
} from "legacy/selectors/editorSelectors";
import {
  getPageCachedData,
  getWidgets,
  getWidgetsMeta,
} from "legacy/selectors/sagaSelectors";
// TODO: Stop importing directly, but this is a circular dep otherwise
import WidgetFactory from "legacy/widgets/Factory";
import renameApplicationEntitySaga from "store/sagas/renameApplicationEntity";
import {
  clearAiChanges,
  removeChildInAiChanges,
  resetAiState,
  resetLayoutChanges,
  setAiChanges,
  updateAiChanges,
} from "store/slices/ai/slice";
import {
  executeV1ApiSaga,
  updateV1ApiSaga,
  selectAllV1Apis,
  ApiV1,
  deleteV1ApiSaga,
  clearResponseV1Api,
} from "store/slices/apisShared";
import {
  clearResponseV2Api,
  deleteV2ApiSaga,
  executeV2ApiSaga,
  selectAllV2Apis,
  updateV2ApiSaga,
  getV2ApiToComponentDepsSaga,
  getV2ApiBlockDepsSaga,
} from "store/slices/apisV2";
import { checkEvaluationNamesDirty } from "store/slices/apisV2/sagas/checkEvaluationNamesDirty";
import { executeV2ApiBlockSaga } from "store/slices/apisV2/sagas/executeV2ApiBlock";
import { renameCustomEvents } from "store/slices/application/events/eventActions";
import { overwriteScopedEvents } from "store/slices/application/events/slice";
import { getIsIframeLoaded } from "store/slices/application/selectors";
import { overwriteScopedStateVars } from "store/slices/application/stateVars/slice";
import { updateStateVarMetaProperties } from "store/slices/application/stateVarsMeta/slice";
import {
  resetStateVar,
  setStateVarValue,
  setPropertyStateVar,
} from "store/slices/application/stateVarsMeta/stateVarsMetaActions";
import { overwriteScopedTimers } from "store/slices/application/timers/slice";
import { deleteTimer } from "store/slices/application/timers/timerActions";
import {
  createTimer,
  renameTimers,
  startTimer,
  stopTimer,
  updateTimers,
} from "store/slices/application/timers/timerActions";
import {
  resetTimerMetaProperties,
  updateTimerMetaProperties,
} from "store/slices/application/timersMeta/slice";
import { fetchCommitsSaga } from "store/slices/commits";
import { selectFlagById } from "store/slices/featureFlags";
import { resolveById } from "store/utils/resolveIdSingleton";
import { callSagas, SagaReturnValue } from "store/utils/saga";
import { GeneratorReturnType } from "store/utils/types";
import logger from "utils/logger";
import {
  formatBytes,
  getDetailedSize,
  LOG_STYLES,
  shouldLogPerformance,
} from "utils/performance";
import { stringToJS } from "utils/string";
import { updateCurrentRoute } from "../actions/pageActions";
import {
  EVAL_WORKER_ACTIONS,
  EvalTreeRequest,
  EvalTreeResponse,
  ReplayState,
  ReplayStateOptions,
} from "../utils/DynamicBindingUtils";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "../utils/PerformanceTracker";
import { Flag } from "./../../store/slices/featureFlags/models/Flags";
import {
  extractBindingsForPageLoadAPIsV2,
  evalErrorHandler,
} from "./EvaluationsSagaHelpers";
import {
  cachePageLoadActionsInfo,
  executePageLoadActions,
  getPageLoadInfo,
} from "./PageLoadSagas";
import {
  openPropertyPaneSaga,
  postUndoRedoSaga,
  UndoRedoPayload,
} from "./ReplaySaga";
import { evaluateActionBindings } from "./TriggerSagaHelpers";
import { worker } from "./evaluationLoader";
import type { WidgetTypeConfigMap } from "legacy/widgets";

let widgetTypeConfigMap: WidgetTypeConfigMap;

function* evaluateTreeSaga(replayState: ReplayState, isFirstTime?: boolean) {
  const eventName = isFirstTime
    ? PerformanceTransactionName.FIRST_EVALUATION_ON_LOAD
    : PerformanceTransactionName.DATA_TREE_EVALUATION;
  PerformanceTracker.startAsyncTracking(eventName);
  yield put({ type: ReduxActionTypes.START_EVALUATE_TREE });

  const start = performance.now();

  let unevalTree: DataTree;
  let dataTreeMetaPaths: DataTreeMetaPaths;
  try {
    const unevalData: {
      dataTree: DataTree;
      dataTreeMetaPaths: DataTreeMetaPaths;
    } = yield select(getUnevaluatedDataTree);
    unevalTree = unevalData.dataTree;
    dataTreeMetaPaths = unevalData.dataTreeMetaPaths;
  } catch (e: any) {
    throw new Error(`Could not construct dataTree. ${e.message}`);
  }

  let needsStaticAnalysis: boolean;
  const appMode: APP_MODE | undefined = yield select(getAppMode);
  if (!appMode || appMode === APP_MODE.EDIT) {
    // On edit mode, we always need to perform static analysis.
    needsStaticAnalysis = true;
  } else {
    // We are not in edit mode.  We only need to perform a static analysis if we don't have the pageLoad actions cached.
    const cachedData: ReturnType<typeof getPageCachedData> =
      yield select(getPageCachedData);
    needsStaticAnalysis = !cachedData?.pageLoadActions;
  }

  const donePrep = performance.now();

  const request: EvalTreeRequest = {
    unevalTree,
    metaPaths: dataTreeMetaPaths,
    widgetTypeConfigMap: isFirstTime ? widgetTypeConfigMap : undefined,
    replayState,
    options: {
      staticAnalysis: needsStaticAnalysis,
    },
  };

  const response:
    | { responseData: EvalTreeResponse; timeInWorker: string }
    | undefined = yield call(
    worker.requestWithMeta,
    EVAL_WORKER_ACTIONS.EVAL_TREE_REC_EVAL,
    request,
  );
  if (!response?.responseData) {
    // This happens if STOP_EVALUATION is called before the worker responds
    PerformanceTracker.stopAsyncTracking(eventName);
    return;
  }
  const { responseData, timeInWorker } = response;
  const {
    errors,
    dataTree,
    dependencies,
    triggerMap,
    entityDependencyMap,
    entityToApiDepMap,
    logs,
    referenceMeta,
  }: EvalTreeResponse = responseData;

  const doneEval = performance.now();

  logs.forEach((evalLog) => logger.debug(evalLog, undefined, false));

  const doneLog = performance.now();

  evalErrorHandler(errors);

  const doneError = performance.now();

  // Post-evaluation, determine if any entity name has changed for API dep caching
  // we don't care which names are changed, just that there are changes
  const { updateCount, deleteCount } =
    yield checkEvaluationNamesDirty(dataTree);
  const doneMerge = performance.now();

  const isSwitchingPage: ReturnType<typeof getIsFetchingPage> =
    yield select(getIsFetchingPage);

  if (!isSwitchingPage) {
    const { deletedEntities: deletedEntityNames, ...dataTreeUpdates } =
      dataTree;
    // leave a "frozen" state on screen during page switch
    yield put({
      type: ReduxActionTypes.TREE_WILL_UPDATE,
      payload: dataTreeUpdates, // Note that this is the unhydrated tree,
      // this works for now because currently this action only pertains to non-derived properties
      // if the reference manager is modified to operate on arbitrary entity properties, we will need to update this
    });
  }

  const doneUpdate = performance.now();

  if (!isSwitchingPage) {
    // leave a "frozen" state on screen during page switch
    yield put({
      type: ReduxActionTypes.SET_EVALUATED_TREE,
      payload: { dataTree, referenceMeta },
    } satisfies SetEvaluatedTreeAction);
  }

  const doneSet = performance.now();

  yield put({
    type: ReduxActionTypes.SET_EVALUATION_DEPENDENCIES,
    payload: {
      inverseDependencyMap: dependencies,
      actionTriggerMap: triggerMap,
      // entityToApiDepMap is a partial map here, the reducer will merge it with the existing map
      entityToApiDepMap,
      entityDependencyMap,
    },
  });

  const doneAll = performance.now();

  const data = {
    inputChanges:
      getEntityCount(unevalTree) - (unevalTree.deletedEntities?.length ?? 0),
    outputChanges: updateCount + deleteCount,
    timing: {
      a_prep: donePrep - start,
      b_eval: {
        total: doneEval - donePrep,
        on_worker: parseFloat(timeInWorker),
        on_main: doneEval - donePrep - parseFloat(timeInWorker),
      },
      c_log: doneLog - doneEval,
      d_error: doneError - doneEval,
      e_merge: doneMerge - doneError,
      f_treeWillUpdate: doneUpdate - doneMerge,
      g_setTree: doneSet - doneUpdate,
      h_setEvalDep: doneAll - doneSet,
    },
  };

  if (shouldLogPerformance()) {
    const detailedSizes = {
      input: getDetailedSize(unevalTree),
      output: getDetailedSize(dataTree),
      dependencies: getDetailedSize(dependencies),
      triggerMap: getDetailedSize(triggerMap),
    };

    console.groupCollapsed("%cEvaluation Performance", LOG_STYLES.EVALUATION);
    console.log({
      timing: data.timing,
      changes: {
        input: data.inputChanges,
        output: data.outputChanges,
      },
      sizes: Object.fromEntries(
        Object.entries(detailedSizes).map(([key, value]) => [
          key,
          `${value} (${formatBytes(value)})`,
        ]),
      ),
      payloads: {
        input: unevalTree,
        output: dataTree,
        dependencies,
        triggerMap,
        referenceMeta,
      },
    });
    console.groupEnd();
  }

  PerformanceTracker.stopAsyncTracking(eventName, data);
}

function* evaluateActionBindingsSaga(
  action: ReturnType<typeof evaluateBindings>,
): Generator<any, any, any> {
  const response = yield call(
    evaluateActionBindings,
    action.payload.bindings.map((str) => stringToJS(str)),
    action.payload.scope,
  );
  resolveById(action.payload.callbackId, response);
}

export function* clearEvalCache() {
  yield call(worker.request, EVAL_WORKER_ACTIONS.CLEAR_CACHE);

  return true;
}

/**
 * clears all cache keys of a widget
 *
 * @param widgetName
 */
export function* clearEvalPropertyCacheOfWidget(widgetName: string) {
  yield call(
    worker.request,
    EVAL_WORKER_ACTIONS.CLEAR_PROPERTY_CACHE_OF_WIDGET,
    {
      widgetName,
    },
  );
}

export function* reevaluateTree(
  needsApiDepExtraction: boolean,
  needsLayoutSave: boolean,
  replayOptions: Partial<ReplayStateOptions> | undefined,
): Generator<any, any, any> {
  const widgetsMeta: MetaState = yield select(getWidgetsMeta);
  const currentWidgets: CanvasWidgetsReduxState = yield select(getWidgets);
  const replayState = {
    widgets: currentWidgets,
    shouldReplay: replayOptions?.shouldReplay ?? true,
    clearReplayStack: replayOptions?.clearReplayStack ?? false,
    widgetMeta: widgetsMeta,
  } as ReplayState;

  try {
    yield call(evaluateTreeSaga, replayState);

    if (needsApiDepExtraction) {
      const lazyApiBindingExtraction: boolean = yield select(
        selectFlagById,
        Flag.ENABLE_LAZY_API_BINDING_EXTRACTION,
      );
      if (lazyApiBindingExtraction) {
        const appMode: APP_MODE | undefined = yield select(getAppMode);
        const useApisFromCachedData = Boolean(
          appMode && appMode !== APP_MODE.EDIT,
        );
        yield call(extractBindingsForPageLoadAPIsV2, useApisFromCachedData);
      } else {
        yield callSagas([getV2ApiToComponentDepsSaga.apply({})]);
      }
    }
    yield callSagas([getV2ApiBlockDepsSaga.apply({})]);

    if (needsApiDepExtraction || needsLayoutSave) {
      // If the API bindings or a widget was updated, we need to update the page load actions cache
      const wasUpdated: SagaReturnValue<typeof cachePageLoadActionsInfo> =
        yield call(cachePageLoadActionsInfo);
      // if the page load actions cache was updated, we need to force a layout save
      needsLayoutSave ||= wasUpdated;
      if (needsLayoutSave) {
        yield put(requestPageSave());
      }
      // we also need to refetch the autosaves if the versions panel is pinned
      const isLeftPanePinned: boolean = yield select(getIsLeftPanePinned);
      const sidebarKey: SideBarKeys = yield select(getApplicationSidebarKey);
      const applicationId: string = yield select(getCurrentApplicationId);
      if (
        isLeftPanePinned &&
        sidebarKey === SideBarKeys.VERSIONS &&
        applicationId
      ) {
        yield delay(1000);

        yield callSagas([
          fetchCommitsSaga.apply({
            entityId: applicationId,
            entityType: VersionedEntityType.APPLICATION,
          }),
        ]);
      }
    }
  } catch (e: any) {
    // The evaluateTreeSaga previously had a silent crash when creating a blank workflow,
    // so now we log an error and leave the evaluator in a state where it can re-start later.
    logger.error(e);
    yield call(worker.shutdown);
  }
}

const FIRST_EVAL_REDUX_ACTIONS = [
  // Pages
  pageLoadSuccess.type,
];

// actions that nessitate a layout save
const REQUIRES_LAYOUT_SAVE_ACTIONS: string[] = [
  updateLayout.type,
  updatePartialLayout.type,
  setSingleWidget.type,
  renameWidgets.type,
  overwriteScopedStateVars.type,
  overwriteScopedTimers.type,
  overwriteScopedEvents.type,
];

const REQUIRES_API_DEP_EXTRACTION = Object.freeze([
  // Apis
  updateV1ApiSaga.success.type,
  updateV2ApiSaga.success.type,
  renameEmbedProps.type,
  renameCustomEvents.type,
  renameWidgets.type,

  // These actions are called on every CRUD action for page/app DSL entities
  overwriteScopedStateVars.type,
  overwriteScopedTimers.type,
  overwriteScopedEvents.type,
]);

const EVALUATE_REDUX_ACTIONS = [
  ...FIRST_EVAL_REDUX_ACTIONS,
  pageLoadActionsComplete.type,
  stopEvaluation.type,
  restartEvaluation.type,
  // Actions
  runEventHandlersError.type,
  // Apis
  executeV1ApiSaga.success.type,
  executeV1ApiSaga.error.type,
  clearResponseV1Api.type,
  updateV1ApiSaga.success.type,
  deleteV1ApiSaga.success.type,
  // Apis V2
  executeV2ApiSaga.success.type,
  executeV2ApiBlockSaga.success.type,
  executeV2ApiBlockSaga.error.type,
  updateV2ApiSaga.success.type,
  deleteV2ApiSaga.success.type,
  clearResponseV2Api.type,
  // App Data
  ReduxActionTypes.SET_APP_MODE,
  ReduxActionTypes.CURRENT_APPLICATION_NAME_UPDATE,
  ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
  ReduxActionTypes.UPDATE_APP_STORE,
  // Widgets
  ...REQUIRES_LAYOUT_SAVE_ACTIONS,
  deleteWidgetProperty.type,
  updateLocalWidgets.type,
  ReduxActionTypes.RENAME_WIDGETS,
  // Widget Meta
  setMetaProp.type,
  setMetaProps.type,
  resetWidgetMetaProperty.type,
  // Widget Dynamic Height
  ReduxActionTypes.SET_DYNAMIC_WIDGET_PROPS,
  ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_HEIGHT,
  // Global sagas
  renameApplicationEntitySaga.success.type,
  // State Vars
  resetStateVar.type,
  updateStateVarMetaProperties.type,
  setStateVarValue.type,
  setPropertyStateVar.type,
  // Timers
  createTimer.type,
  updateTimers.type,
  renameTimers.type,
  deleteTimer.type,
  startTimer.type,
  stopTimer.type,
  updateTimerMetaProperties.type,
  resetTimerMetaProperties.type,
  // tables
  updateTableRows.type,
  deleteTableRows.type,
  insertTableRows.type,
  // Profiles
  ReduxActionTypes.UPDATE_PROFILES,
  // Theme
  ReduxActionTypes.UPDATE_GENERATED_THEME,
  // embedding
  ReduxActionTypes.UPDATE_EMBED_PROPERTY_META,
  ReduxActionTypes.UPDATE_EMBED_PROPERTY,
  ReduxActionTypes.DELETE_EMBED_PROPERTY,
  ReduxActionTypes.CREATE_EMBED_PROPERTY,
  renameEmbedProps.type,
  renameCustomEvents.type,
  // multi-page
  ReduxActionTypes.UPDATE_DATA_URL,
  createRoute.type,
  ReduxActionTypes.DELETE_ROUTE,
  updateCurrentRoute.type,
  // ai
  setAiChanges.type,
  updateAiChanges.type,
  clearAiChanges.type,
  resetLayoutChanges.type,
  resetAiState.type,
  removeChildInAiChanges.type,
];

// used to prevent first action and second action triggering two evaluation if they are too close (e.g. in a loop that update meta)
const EVALUATE_MINIMUM_DEBOUNCE_DELAY = 10;
const DEFAULT_EVALUATE_DEBOUNCE_DELAY = 100;
const defaultDelays = EVALUATE_REDUX_ACTIONS.reduce(
  (acc, action) => ({ ...acc, [action]: DEFAULT_EVALUATE_DEBOUNCE_DELAY }),
  {} as Record<string, number>,
);

const NO_DEBOUNCE = -1;
const EVALUATE_DEBOUNCE_DELAY = {
  ...defaultDelays,
  [executeV1ApiSaga.success.type]: 10, // A user explicitly runs an api
  [updateV1ApiSaga.success.type]: 300, // Something in our api has changed, when this happens it can happen a lot (such as typing)
  [executeV2ApiSaga.success.type]: 10,
  [executeV2ApiBlockSaga.success.type]: 10,
  [updateV2ApiSaga.success.type]: 300,

  // It's very common that streaming data is updated so we want to minimize the delay
  [resetStateVar.type]: NO_DEBOUNCE,
  [setStateVarValue.type]: NO_DEBOUNCE,
  [setPropertyStateVar.type]: NO_DEBOUNCE,
  [ReduxActionTypes.UPDATE_EMBED_PROPERTY_META]: NO_DEBOUNCE,
};

function* evaluationChangeListenerSaga(evaluationType: EvaluationType) {
  // Apps seem to send their first action after the worker is ready
  // Jobs & workflows send their first action after
  // To be safe, we need _both_ conditions to be true regardless of ordering.
  yield all({
    action: take(FIRST_EVAL_REDUX_ACTIONS),
    start: call(function* () {
      yield call(worker.shutdown);
      yield call(worker.start);
    }),
  });

  widgetTypeConfigMap = WidgetFactory.getWidgetTypeConfigMap();

  const evaluateActionChannel: Channel<ReduxAction<unknown>> =
    yield actionChannel(EVALUATE_REDUX_ACTIONS, buffers.expanding());

  const widgets: CanvasWidgetsReduxState = yield select(getWidgets);
  const widgetMeta: MetaState = yield select(getWidgetsMeta);
  const apis: Record<string, ApiV1> = yield select(selectAllV1Apis);
  const apisV2: ReturnType<typeof selectAllV2Apis> =
    yield select(selectAllV2Apis);
  const initReplayState = {
    apis,
    apisV2,
    widgets,
    shouldReplay: true,
    clearReplayStack: false,
    widgetMeta,
  } as ReplayState;
  try {
    yield call(evaluateTreeSaga, initReplayState, true);
  } catch (e) {
    // The evaluateTreeSaga previously had a silent crash when creating a blank workflow,
    // so now we log an error and leave the evaluator in a state where it can re-start later.
    logger.error(e);
    yield call(worker.shutdown);
    return;
  }

  // Actions will run detached in the background
  // UI indicates that the evaluation is running from an application
  if (evaluationType === "ui") {
    // This extraction requires components to be in the dataTree
    // It will update the api by adding the extracted dependencies
    // The extraction could expose more dependencies that were not evaluated before
    // (possibly in slideouts/modals which are only evaluated on demand)
    // For that reason, we need to evaluate the dataTree again (which will build on the previous evaluation)
    const lazyApiBindingExtraction: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_LAZY_API_BINDING_EXTRACTION,
    );
    if (lazyApiBindingExtraction) {
      const appMode: APP_MODE | undefined = yield select(getAppMode);
      const useApisFromCachedData = Boolean(
        appMode && appMode !== APP_MODE.EDIT,
      );
      yield call(extractBindingsForPageLoadAPIsV2, useApisFromCachedData);
    } else {
      yield callSagas([getV2ApiToComponentDepsSaga.apply({})]);
    }

    const isIframeEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_IFRAME,
    );
    // If the iframe is enabled, we need to wait for it to load before we proceed
    // But if we are viewing an undeployed app, there will be no iframe to wait for
    if (isIframeEnabled && !isEmpty(widgets)) {
      const isIframeLoaded: ReturnType<typeof getIsIframeLoaded> =
        yield select(getIsIframeLoaded);
      if (!isIframeLoaded) {
        // Wait for iframe to render before beginning api extraction
        yield take(ReduxActionTypes.APP_FRAME_LOADED);
      }
    }
    yield put({ type: ReduxActionTypes.EXTRACTED_PAGE_LOAD_DEPS });

    const isMultipageEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_MULTIPAGE,
    );
    let response: GeneratorReturnType<typeof getPageLoadInfo> | null = null;
    if (!isMultipageEnabled) {
      response = yield call(getPageLoadInfo);
    }

    yield call(evaluateTreeSaga, initReplayState);

    if (!isMultipageEnabled && response) {
      // with multi-page this is run by the RouteSagas
      yield spawn(executePageLoadActions, response);
    }
  }

  let lastEvalAttempt = moment();
  while (true) {
    // Wait for at least one action to be taken
    const action: ReduxAction<unknown> = yield take(evaluateActionChannel);
    const actionsQueued: ReduxAction<unknown>[] = yield flush(
      evaluateActionChannel,
    );

    if (
      action.type === stopEvaluation.type ||
      actionsQueued.some((action) => action.type === stopEvaluation.type)
    ) {
      yield call(worker.shutdown);
      return;
    }

    while (true) {
      // We're now in the leading debounced state, all actions from
      // evaluateActionChannel will be ignored apart from STOP_EVALUATION.
      // This loop will run until delay is expired or STOP_EVALUATION is taken.
      // Leading means that we evaluate on the leading edge of the timeout.

      yield put({ type: ReduxActionTypes.START_EVALUATE_QUEUE });
      const actionsDebounce = EVALUATE_DEBOUNCE_DELAY[action.type];
      const msSinceLastEval = moment().diff(lastEvalAttempt);
      const debounceLength = Math.max(
        actionsDebounce === NO_DEBOUNCE ? 0 : EVALUATE_MINIMUM_DEBOUNCE_DELAY,
        actionsDebounce - msSinceLastEval,
      );

      const {
        willEval,
        latestAction,
      }: {
        willEval?: boolean;
        latestAction?: ReduxAction<unknown>;
      } = yield race({
        willEval: debounceLength > 0 ? delay(debounceLength) : true,
        latestAction: take(evaluateActionChannel),
      });

      if (willEval) {
        const actions: ReduxAction<unknown>[] = [
          ...actionsQueued,
          latestAction,
          action,
        ].filter((action): action is ReduxAction<unknown> => Boolean(action));

        const needsApiDepExtraction =
          evaluationType === "ui" &&
          actions.some((action) =>
            REQUIRES_API_DEP_EXTRACTION.includes(action.type),
          );
        const needsLayoutSave = actions.some((action) => {
          if (!REQUIRES_LAYOUT_SAVE_ACTIONS.includes(action.type)) {
            return false;
          }

          return (
            (
              action.payload as Partial<
                ReturnType<typeof updateLayout>["payload"]
              >
            ).savePage !== false
          );
        });
        yield call(reevaluateTree, needsApiDepExtraction, needsLayoutSave, {
          shouldReplay: (action.payload as any)?.shouldReplay,
          clearReplayStack: (action.payload as any)?.clearReplayStack,
        });
        lastEvalAttempt = moment();
        break;
      } else {
        if (latestAction) {
          actionsQueued.push(latestAction);
        }
        lastEvalAttempt = moment();
      }

      if (latestAction && latestAction.type === stopEvaluation.type) {
        yield call(worker.shutdown);
        return;
      }
    }
  }
}

export default function* evaluationSagaListeners() {
  while (true) {
    // always be listening for start_evaluation events
    const action: ReturnType<typeof startEvaluation> = yield take(
      startEvaluation.type,
    );
    while (true) {
      try {
        const { stopEval } = yield race({
          run: call(
            evaluationChangeListenerSaga,
            action.payload.evaluationType,
          ),
          stopEval: take(stopEvaluation.type),
          restartEval: take(restartEvaluation.type),
        });
        if (stopEval) {
          yield call(worker.shutdown);
        }
        // if evaluationChangeListenerSaga finished successfully, break out
        break;
      } catch (e) {
        logger.error(e);
        Sentry.captureException(e);
      }
    }
  }
}

function* undoRedoSaga(action: ReduxAction<UndoRedoPayload>) {
  try {
    Toaster.clear();
    PerformanceTracker.startAsyncTracking(PerformanceTransactionName.UNDO_REDO);
    const workerResponse: {
      logs: any[];
      replay: any;
      replayDSL: {
        widgets: CanvasWidgetsReduxState;
        widgetMeta: MetaState;
      };
    } = yield call(
      worker.request,
      action.payload.operation,
      {} as EvalTreeRequest,
    );
    PerformanceTracker.stopAsyncTracking(PerformanceTransactionName.UNDO_REDO);

    // if there is no change, then don't do anything
    if (!workerResponse) return;

    const { logs, replay, replayDSL } = workerResponse;
    logs && logs.forEach((evalLog: any) => logger.debug(evalLog));
    const isPropertyUpdate = replay.widgets && replay.propertyUpdates;
    if (isPropertyUpdate) yield call(openPropertyPaneSaga, replay);
    if (replay.widgets) {
      yield put(updateLayout(replayDSL.widgets));
    }
    if (replay.widgetMeta) {
      yield all(
        Object.keys(replay.widgetMeta)
          .map((widgetId) => {
            const updates = replay.widgetMeta[widgetId].metaUpdates;
            const propertyValue = get(replayDSL, updates);
            const propertyName = updates[updates.length - 1];
            return put(setMetaProp(widgetId, propertyName, propertyValue));
          })
          .filter(Boolean),
      );
    }

    if (!isPropertyUpdate) yield call(postUndoRedoSaga, replay);
  } catch (e) {
    logger.error(e);
  }
}

export function* undoRedoListenerSaga() {
  yield takeEvery(ReduxActionTypes.UNDO_REDO_OPERATION, undoRedoSaga);
}

export function* evaluateBindingsDirectlyListenerSaga() {
  yield takeEvery(evaluateBindings.type, evaluateActionBindingsSaga);
}
