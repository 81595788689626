import { WidgetTypes, Dimension } from "@superblocksteam/shared";
import { dimensionToGridRows } from "legacy/utils/WidgetPropsUtils";
import { WidgetProps } from "legacy/widgets";
import { TabContainerWidgetProps } from "legacy/widgets/TabsWidget/types";
import { TabsWidgetProps } from "legacy/widgets/TabsWidget/types";
import { generateNewTab } from "legacy/widgets/TabsWidget/utils";
import { fastClone } from "utils/clone";
import { TabMetadata } from "./types";

export const generateTab = ({ widget }: { widget: any }) => {
  const newTab = generateNewTab(widget.tabs);

  // Create the new tab child widget
  const newChildWidget = {
    type: WidgetTypes.CANVAS_WIDGET,
    left: Dimension.gridUnit(0),
    top: Dimension.gridUnit(0),
    height: Dimension.minus(
      dimensionToGridRows(widget.height),
      Dimension.gridUnit(3),
    ).asFirst(), // tabs header takes 3 rows
    width: widget.width,
    widgetId: newTab.widgetId,
    parentId: widget.widgetId,
    tabId: newTab.id,
    tabName: newTab.label,
    canExtend: false,
    detachFromLayout: true,
    children: [],
  };

  return {
    tab: newTab,
    childWidget: newChildWidget,
  };
};

export const initializeTabMetadata = (
  widget: Partial<WidgetProps>,
  numItems: number,
): TabMetadata => {
  const tabWidgetIdToIndex: Record<string, number> = {};
  const tabsWidget = widget as TabsWidgetProps<TabContainerWidgetProps>;
  const currentTabs = fastClone(tabsWidget.tabs || []);
  const currentChildren = fastClone(tabsWidget.children || []);
  for (let i = 0; i < numItems; i++) {
    tabWidgetIdToIndex[currentChildren[i]] = i;
  }
  return {
    tabWidgetIdToIndex,
    currentTabs,
    currentChildren,
    tabsModified: false,
    additionalChangedKeys: [],
  };
};

export const addTab = ({
  tabMetadata,
  index,
  property,
  value,
  dependentChangesByWidgetId,
  widget,
}: {
  tabMetadata: TabMetadata;
  index: number;
  property: string;
  value: any;
  dependentChangesByWidgetId: Record<string, any>;
  widget: Partial<WidgetProps>;
}) => {
  const { tab: newTab, childWidget: newChildWidget } = generateTab({
    widget: {
      ...widget,
      tabs: tabMetadata.currentTabs,
      children: tabMetadata.currentChildren,
    },
  });
  dependentChangesByWidgetId[newChildWidget.widgetId] = newChildWidget;
  if (newTab) {
    // Apply any additional tab properties from the action
    if (property && property !== "id" && value) {
      newTab[property as keyof typeof newTab] = value as string;
    }
    tabMetadata.currentTabs.splice(index, 0, newTab);
    tabMetadata.currentChildren.splice(index, 0, newTab.widgetId);
    tabMetadata.tabsModified = true;
    tabMetadata.additionalChangedKeys.push(`tabs[${index}].${property}`);
    tabMetadata.tabWidgetIdToIndex[newTab.widgetId] = index;
  }
};

export const TAB_CHILD_COMPONENT_PREFIX = "TAB_CHILD_COMPONENT[";
export const updateTab = ({
  tabMetadata,
  index,
  property,
  value,
  widget,
  dependentChangesByWidgetId,
}: {
  tabMetadata: TabMetadata;
  index: number;
  property: string;
  value: any;
  dependentChangesByWidgetId: Record<string, any>;
  widget: Partial<WidgetProps>;
}) => {
  const childtoEdit = widget.children?.[index];

  const tab = tabMetadata.currentTabs.find(
    (tab: any) => tab.widgetId === childtoEdit,
  );
  // everything except label is actually set on the child widget
  if (tab && property === "label") {
    (tab as any).label = value;
    tabMetadata.tabsModified = true;
    tabMetadata.additionalChangedKeys.push(`tabs[${index}].${property}`);
  } else if (tab) {
    const inverseMap = Object.fromEntries(
      Object.entries(tabMetadata.tabWidgetIdToIndex).map(([k, v]) => [v, k]),
    );
    const widgetId = inverseMap[index];
    tabMetadata.tabsModified = true;
    // TODO: When we key changedKeys by widgetId, we dont need this hack
    tabMetadata.additionalChangedKeys.push(
      `${TAB_CHILD_COMPONENT_PREFIX}${index}].${property}`,
    );
    if (widgetId) {
      dependentChangesByWidgetId[widgetId] = {
        ...dependentChangesByWidgetId[widgetId],
        [property]: value,
      };
    }
  }
};

export const removeTab = ({
  tabMetadata,
  index,
  dependentChangesByWidgetId,
  widget,
}: {
  tabMetadata: TabMetadata;
  index: number;
  dependentChangesByWidgetId: Record<string, any>;
  widget: Partial<WidgetProps>;
}) => {
  // look at the widgetId in the OG list of ids as that's what AI will refer to
  const childToRemove = widget.children?.[index];
  if (childToRemove) {
    tabMetadata.currentTabs = tabMetadata.currentTabs.filter(
      (item: any) => item.widgetId !== childToRemove,
    );
    tabMetadata.currentChildren = tabMetadata.currentChildren.filter(
      (id) => id !== childToRemove,
    );
    tabMetadata.tabsModified = true;
    dependentChangesByWidgetId[childToRemove] = null;
  }
};
