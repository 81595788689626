import {
  DeleteApplicationResponseBody,
  GetAllApplicationsResponseBody,
  IHomepageApplicationV2Dto,
  PutApplicationUpdateResponseBody,
} from "@superblocksteam/shared";
import { reduxApiSlice } from "./index";

const extendedApi = reduxApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listApplications: builder.query<IHomepageApplicationV2Dto[], void>({
      query: () => ({
        url: `/v2/applications`,
        method: "GET",
      }),
      transformResponse: (response: { data: GetAllApplicationsResponseBody }) =>
        response.data.applications,
    }),

    updateApplicationMetadata: builder.mutation<
      PutApplicationUpdateResponseBody,
      {
        id: string;
        name?: string;
        folderId?: string | null;
      }
    >({
      query: ({ id, ...updates }) => ({
        url: `/v2/applications/${id}/metadata`,
        method: "PUT",
        body: updates,
      }),
      transformResponse: (response: {
        data: PutApplicationUpdateResponseBody;
      }) => response.data,

      async onQueryStarted({ id, ...updates }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listApplications",
            undefined,
            (draft) => {
              const itemIndex = draft.findIndex((item) => item.id === id);
              if (itemIndex !== -1) {
                draft[itemIndex] = {
                  ...draft[itemIndex],
                  name: updates.name ?? draft[itemIndex].name,
                  folderId:
                    updates.folderId === undefined // specifically test for undefined. A null value is used to indicate the root folder.
                      ? draft[itemIndex].folderId
                      : updates.folderId,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteApplication: builder.mutation<
      DeleteApplicationResponseBody,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/v2/applications/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            "listApplications",
            undefined,
            (draft) => {
              const index = draft.findIndex((item) => item.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useListApplicationsQuery,
  useUpdateApplicationMetadataMutation,
  useDeleteApplicationMutation,
} = extendedApi;
