import { ScheduleConfig, ViewDatasourceDto } from "@superblocksteam/shared";
import { Input, Space, Typography } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { FullWidthSpace } from "components/ui/Space";
import { WizardStep } from "components/ui/WizardFlow";
import useCreateNewApi, {
  CreateApiSuccessOptions,
} from "hooks/api/useCreateNewApi";
import { DocsPage, DOCS_PAGE_URL } from "legacy/constants/routes";

import ScheduleSelector from "pages/Editors/ApiEditor/WorkflowEditor/ScheduleConfiguration/ScheduleSelector";

import { NEW_INTEGRATION_URL } from "pages/routes";

import { ApiTriggerType } from "store/slices/apis";

import { getPluginById } from "utils/integrations";
import { ApiIntegrations } from "./ApiIntegrations";
import { DatasourceList, DatasourceItem } from "./CommonComponents";
import {
  CreateSteps,
  createStepShortcuts,
  useApiDatasources,
  type CommonStepProps,
} from "./common";
import { getJobUrl } from "./utils";

export const CreateJobSteps = (
  props: CommonStepProps & {
    userOrganizationId: string; // Why would this be different from organization.id?;
    organizationId: string;
    selectedFolderId?: string;
    selectedName: string;
  },
) => {
  const { activeStep, userOrganizationId, selectedFolderId, selectedName } =
    props;

  const navigate = useNavigate();
  const [selectedSchedule, setSelectedSchedule] = useState(
    ScheduleConfig.default(),
  );

  const { searchDatasources, setDatasourcesSearch } = useApiDatasources(
    userOrganizationId,
    activeStep === CreateSteps.JOB_FIRST_ACTION,
  );

  const createNewJob = useCreateNewApi(
    {
      triggerType: ApiTriggerType.SCHEDULE,
    },
    CreateApiSuccessOptions.NONE,
  );

  const handleCreateJob = useCallback(
    async (selectedFirstStep: undefined | ViewDatasourceDto) => {
      const resp = await createNewJob({
        apiName: selectedName,
        folder: selectedFolderId,
        datasource: selectedFirstStep,
        plugin: getPluginById(selectedFirstStep?.pluginId),
        schedule: selectedSchedule,
      });
      if (!resp) return;
      navigate({ pathname: getJobUrl(resp.id, resp.blockName) });
    },
    [createNewJob, navigate, selectedFolderId, selectedName, selectedSchedule],
  );

  const chooseScheduleAndNotificationStep = (
    <Space direction="vertical" size={20}>
      <Typography.Text>
        Scheduled Jobs execute business logic across your data sources and APIs
        on a recurring interval every minute, hours, day or week.{" "}
        <Typography.Link
          href={DOCS_PAGE_URL(DocsPage.SCHEDULED_JOBS)}
          target="_blank"
        >
          Learn the basics
        </Typography.Link>
      </Typography.Text>
      <ScheduleSelector
        initConfig={selectedSchedule}
        onConfigChange={async (newConfig: ScheduleConfig): Promise<void> => {
          setSelectedSchedule(newConfig);
        }}
      />
    </Space>
  );

  const newIntegrationListItem = useMemo(
    () => (
      <DatasourceItem>
        <Typography.Link href={NEW_INTEGRATION_URL("")} target="_blank">
          + New Integration
        </Typography.Link>
      </DatasourceItem>
    ),
    [],
  );

  const chooseFirstStepJob = (
    <FullWidthSpace direction="vertical" size={20}>
      <Typography.Text>
        Scheduled Job steps allow you to query data sources or execute code on a
        schedule
      </Typography.Text>
      <Input
        autoFocus
        placeholder="Filter integrations"
        onChange={(e) => {
          setDatasourcesSearch(e.target.value);
        }}
      />
      <DatasourceList>
        {newIntegrationListItem}
        <ApiIntegrations
          searchDatasources={searchDatasources}
          handleClickIntegration={handleCreateJob}
        />
      </DatasourceList>
    </FullWidthSpace>
  );

  return (
    <>
      <WizardStep {...stepIds[CreateSteps.JOB_SCHEDULE]}>
        {chooseScheduleAndNotificationStep}
      </WizardStep>
      <WizardStep
        {...stepIds[CreateSteps.JOB_FIRST_ACTION]}
        canSkip
        onSkip={() => {
          handleCreateJob(undefined /* selected first step */);
        }}
      >
        {chooseFirstStepJob}
      </WizardStep>
    </>
  );
};

const order = [
  CreateSteps.CHOOSE_ENTITY,
  CreateSteps.JOB_SCHEDULE,
  CreateSteps.JOB_FIRST_ACTION,
] as const;
const stepIds = createStepShortcuts(order);
