import equal from "@superblocksteam/fast-deep-equal";
import { DEFAULT_MENU_ITEM } from "legacy/components/propertyControls/AddMenuItemControl";
import { WidgetProps } from "legacy/widgets";
import { ManualMenuItem } from "legacy/widgets/MenuWidget/types";
import { AppState } from "store/types";
import { MenuMetadata } from "./types";

export const initializeMenuMetadata = (
  widget: Partial<WidgetProps>,
  numItems: number,
): MenuMetadata => {
  const metadata: MenuMetadata = {
    indexMap: {},
    menuItemChanges: {},
  };
  for (let i = 0; i < numItems; i++) {
    metadata.indexMap[i] = i;
    metadata.menuItemChanges[i] = null;
  }
  return metadata;
};

export const addMenuItem = ({
  menuMetadata,
  index,
  property,
  value,
}: {
  menuMetadata: MenuMetadata;
  index: number;
  property: string;
  value: any;
}) => {
  menuMetadata.menuItemChanges[index] = {
    status: "added",
    updates: {
      [property]: value,
    },
  };
};

export const updateMenuItem = ({
  menuMetadata,
  index,
  property,
  value,
}: {
  menuMetadata: MenuMetadata;
  index: number;
  property: string;
  value: any;
}) => {
  menuMetadata.menuItemChanges[index] = {
    status: menuMetadata.menuItemChanges[index]?.status ?? "updated",
    updates: {
      ...((menuMetadata.menuItemChanges[index] as any)?.updates ?? {}),
      [property]: value,
    },
  };
};

export const removeMenuItem = ({
  menuMetadata,
  index,
}: {
  menuMetadata: MenuMetadata;
  index: number;
}) => {
  menuMetadata.menuItemChanges[index] = {
    status: "removed",
  };
};

export const applyMenuMetadata = ({
  originalMenuItems,
  menuMetadata,
}: {
  originalMenuItems: ManualMenuItem[];
  menuMetadata: MenuMetadata;
}): {
  updatedMenuItems: ManualMenuItem[];
  metadata: MenuMetadata;
  hasUpdates: boolean;
} => {
  const updatedMenuItems: ManualMenuItem[] = [];
  const menuItemChanges = menuMetadata.menuItemChanges;
  const keys = Object.keys(menuItemChanges);
  keys.sort();
  const indexMap: Record<number, number> = {};
  if (Object.values(menuItemChanges).every((value) => value == null)) {
    return {
      updatedMenuItems: originalMenuItems,
      metadata: menuMetadata,
      hasUpdates: false,
    };
  }
  for (const key of keys) {
    const index = parseInt(key);
    const metadata = menuItemChanges[index];
    if (metadata == null) {
      // keep the OG item
      updatedMenuItems.push(originalMenuItems[index]);
      indexMap[index] = updatedMenuItems.length - 1;
    } else if (metadata.status === "removed") {
      // do nothing (item is removed)
    } else if (metadata.status === "added") {
      // update the item
      updatedMenuItems.push({ ...DEFAULT_MENU_ITEM, ...metadata.updates });
      indexMap[index] = updatedMenuItems.length - 1;
    } else if (metadata.status === "updated") {
      // update the item
      updatedMenuItems.push({
        ...originalMenuItems[index],
        ...metadata.updates,
      });
      indexMap[index] = updatedMenuItems.length - 1;
    }
  }
  return {
    updatedMenuItems,
    metadata: {
      ...menuMetadata,
      indexMap, // index map maps the index in the menuItemChanges metadata to the index in the updatedMenuItems array
    },
    hasUpdates: true,
  };
};

function isValidPermutation(arr: number[]) {
  // If array is empty, it's technically valid
  if (arr.length === 0) return true;

  const n = arr.length;
  const seen = new Array(n).fill(false);

  // Mark each number we see
  for (const num of arr) {
    // Check if number is out of range
    if (num < 0 || num >= n) {
      return false;
    }
    // Check if we've seen this number before
    if (seen[num]) {
      return false;
    }
    seen[num] = true;
  }

  // At this point, all numbers must have been seen exactly once
  return true;
}

export function getMenuMetadataChanges(
  properties: any,
  updates: Record<string, unknown>,
  aiState: AppState["ai"],
  widget: WidgetProps,
): Partial<MenuMetadata> {
  if (!updates?.manualChildren) {
    return {};
  }
  const metadata = aiState.selectedWidgetId
    ? aiState.metadataByWidgetId?.[aiState.selectedWidgetId]
    : undefined;
  if (!metadata || !(metadata as MenuMetadata).indexMap) return {};
  const menuMetadta = metadata as MenuMetadata;
  const originalIndexMap = menuMetadta.indexMap;
  const originalMenuItemChanges = menuMetadta.menuItemChanges;
  if (!originalIndexMap) {
    return {};
  }
  const originalChildren: Array<unknown> = Array.isArray(
    properties.manualChildren,
  )
    ? properties.manualChildren.filter(Boolean)
    : [];
  const updatesChildren = Array.isArray(updates.manualChildren)
    ? updates.manualChildren.filter(Boolean)
    : [];
  // check if an item was added to updates (i.e. an deletion was undone)
  if (
    originalMenuItemChanges &&
    updatesChildren.length > originalChildren.length
  ) {
    const addedItem = updatesChildren.find(
      (item) => !originalChildren.includes(item),
    );
    if (addedItem) {
      // find the index in widget.manualChildren
      const index = (
        (widget as any).manualChildren as Array<unknown>
      ).findIndex((item) => item === addedItem);

      if (index === -1) {
        return {};
      }

      // update menuItemChanges & add to indexMap
      const updatedMenuItemChanges = { ...originalMenuItemChanges };
      updatedMenuItemChanges[index] = null; // not removed any more

      return {
        menuItemChanges: updatedMenuItemChanges,
        indexMap: {
          ...originalIndexMap,
          [index]: updatesChildren.length - 1,
        },
      };
    }
  } else if (
    originalMenuItemChanges &&
    updatesChildren.length < originalChildren.length
  ) {
    // check if an item was removed from updates (i.e. an addition was undone)
    const removedItemIndex = originalChildren.findIndex(
      (item: unknown) =>
        !(updatesChildren as Array<unknown>).some((i) => equal(i, item)),
    );
    if (removedItemIndex !== -1) {
      const inverseOriginalIndexMap = Object.fromEntries(
        Object.entries(originalIndexMap).map(([key, value]) => [value, key]),
      ) as unknown as Record<number, number>;
      const keyToDelete = inverseOriginalIndexMap[removedItemIndex];
      // 1. remove the item from metadata.menuItemChanges and metadata.indexMap
      const updatedMenuItemChanges = { ...originalMenuItemChanges };
      delete updatedMenuItemChanges[keyToDelete];
      const updatedIndexMap = { ...originalIndexMap };
      delete updatedIndexMap[keyToDelete];
      // 2. adjust all values greater than removedItemIndex to be decremented by 1
      for (const [key, value] of Object.entries(updatedIndexMap)) {
        if (value > removedItemIndex) {
          updatedIndexMap[parseInt(key, 10)] = value - 1;
        }
      }
      return {
        menuItemChanges: updatedMenuItemChanges,
        indexMap: updatedIndexMap,
      };
    }
  }

  // if the order of manualChildren is different, then we need to update the indexMap to reflect these changes
  const updatedOrder = (updatesChildren as Array<unknown>).map(
    (item, index) => {
      // find the index of the item in the original order. we can use === because the items are the same objects
      const originalIndex = originalChildren.findIndex((i) => i === item);
      return originalIndex === -1 ? index : originalIndex;
    },
  );
  if (!isValidPermutation(updatedOrder)) {
    return {};
  }
  // now we need to update the indexMap
  const updatedIndexMap: Record<number, number> = {};
  const inverseOriginalIndexMap = Object.fromEntries(
    Object.entries(originalIndexMap).map(([key, value]) => [value, key]),
  ) as unknown as Record<number, number>;
  for (let i = 0; i < updatedOrder.length; i++) {
    const newIndex = i;
    const previousIndex = updatedOrder[i];

    // look for the originalIndex in the originalIndexMap (as a value)
    const originalIndexInOriginalIndexMap =
      inverseOriginalIndexMap[previousIndex];

    if (originalIndexInOriginalIndexMap == null) {
      continue;
    }
    updatedIndexMap[originalIndexInOriginalIndexMap] = newIndex;
  }
  return { indexMap: updatedIndexMap };
}
