import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fastClone } from "utils/clone";
import {
  ComponentEditFeedbackAction,
  getAiWidgetEditActionsStream,
  sendAiWidgetEditActionsFeedback,
} from "./client";
import hardCodedPromptActions from "./hardCodedPromptActions";
import { DiscardedEdit } from "./processActionsIntoChanges";
import { ComponentEditAction, EditMetadata, SortedProperties } from "./types";

const PROCESSING_INTERVAL = 300;

const TEST_ACTIONS: any[] = [
  // run this on a column
  // { properties: ["layout", "distribution", "spacing"] },
  // {
  //   action: "set",
  //   property: "layout",
  //   value: "VSTACK",
  // },
  // {
  //   action: "set",
  //   property: "alignment",
  //   value: "CENTER",
  // },
  // {
  //   action: "set",
  //   property: "spacing",
  //   value: {
  //     mode: "px",
  //     value: 0,
  //   },
  // },
  // run this on a text widget (or something similar)
  { properties: ["width", "maxWidth", "minWidth"] },
  {
    action: "set",
    property: "width",
    value: {
      mode: "fitContent",
      value: 24,
    },
  },
  {
    action: "set",
    property: "maxWidth",
    value: {
      mode: "px",
      value: 200,
    },
  },
  {
    action: "set",
    property: "minWidth",
    value: {
      mode: "px",
      value: 10,
    },
  },
  // { properties: ["height", "maxHeight", "minHeight"] },
  // {
  //   action: "set",
  //   property: "height",
  //   value: {
  //     mode: "fitContent",
  //     value: 24,
  //   },
  // },
  // {
  //   action: "set",
  //   property: "maxHeight",
  //   value: {
  //     mode: "px",
  //     value: 140,
  //   },
  // },
  // {
  //   action: "set",
  //   property: "minHeight",
  //   value: {
  //     mode: "px",
  //     value: 20,
  //   },
  // },
];

const AI_PROMPT_TIMEOUT_DEFAULT = 300;

const PREDEFINED_PROMPT_RESPONSES = [
  {
    rawPrompt:
      "connect the data to creditCardApplications and set the table header based on the data.",
    prompt:
      'connect the data to <api name="creditCardApplications"> and set the table header based on the data',
    actions: hardCodedPromptActions.connectData,
  },
  {
    rawPrompt:
      "Remove the download and search bar. freeze the business name and application timestamp to the left side. Also, add in pills for industry_category and status. Make the status and  format the date in EU format in words and sort by most up to date.",
    prompt:
      "Remove the download and search bar. freeze the business name and application timestamp to the left side. Also, add in pills for industry_category and status. Make the status and  format the date in EU format in words and sort by most up to date",
    actions: hardCodedPromptActions.formatTable,
  },
  // Allow prompt with and without the bad extra space vs. not (space is here: "Make the status and[space][space]format...")
  {
    rawPrompt:
      "Remove the download and search bar. freeze the business name and application timestamp to the left side. Also, add in pills for industry_category and status. Make the status and format the date in EU format in words and sort by most up to date.",
    prompt:
      "Remove the download and search bar. freeze the business name and application timestamp to the left side. Also, add in pills for industry_category and status. Make the status and format the date in EU format in words and sort by most up to date",
    actions: hardCodedPromptActions.formatTable,
  },
  {
    rawPrompt:
      "Add a Flag transaction button in a column, but only enable it if the user is on the RiskOps team.",
    prompt:
      'Add a Flag transaction button in a column, but only enable it if the user is on the <group name="RiskOps"> team',
    actions: hardCodedPromptActions.flagTransaction,
  },
];

const formatPredefinedPrompt = (prompt: string) => {
  return prompt.toLowerCase().trim().replace(/\.$/, "");
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const initialState: {
  isLoading: boolean;
  selectedWidgetId?: string;
  initialPosition?: { x: number; y: number };
  actions?: Array<any>;
  changedKeys?: Record<string, string[]>;
  dataTreeChanges?: Record<string, Record<string, unknown> | null>;
  initialDataTreeChanges?: Record<string, Record<string, unknown> | null>;
  propertiesToChange?: Record<string, string[]>;

  widgetRename?: string;
  error?: string;
  actionsRequestId?: string;
  discardedEdits?: DiscardedEdit[];
  noResultsResponse?: {
    summary: string;
    suggestion?: string;
  };
  metadataByWidgetId?: Record<string, EditMetadata>;
  historyByWidgetId?: Record<string, string[]>;
  layoutChangesByWidgetId?: Record<string, Record<string, unknown>>;
  preAiLayoutValuesByWidgetId?: Record<string, Record<string, unknown>>;
} = {
  isLoading: false,
};

type NoResultsResponse = {
  summary: string;
  suggestion?: string;
};

type GetWidgetEditsPayload = {
  component: SortedProperties;
  parent: SortedProperties;
  prompt: string;
  rawPrompt: string;
  context: Record<string, any>;
  widgetId: string;
  aiServiceURL: string;
  allEntities: Array<string>;
  sessionId: string;
};

export const processAiActions = createAction<{
  actions: ComponentEditAction[];
  existingWidgetId: string;
}>("ai/processAiActions");

export const updateAiChanges = createAction<
  | {
      updates: Record<string, unknown>;
      properties: Record<string, unknown>;
    }
  | {
      rename: string;
    }
>("ai/updateAiChanges");

export const removeChildInAiChanges = createAction<{
  widgetId: string;
}>("ai/removeChildInAiChanges");

export const restoreDeletedChildInAiChanges = createAction<{
  childId: string;
}>("ai/restoreDeletedChildInAiChanges");

export const processAiProperties = createAction<{
  widgetId: string;
  properties: string[];
  widgetType: string;
  noResultsResponse?: NoResultsResponse;
}>("ai/processAiProperties");

export const updateAiDynamicProperties = createAction<{
  itemId: string;
  propertyName: string;
  isDynamicProperty: boolean;
}>("ai/updateAiDynamicProperties");

export const updateLayoutFromAi = createAction("ai/updateLayoutFromAi");

export const clearAiChanges = createAction<{
  isAccept?: boolean;
  shouldClose: boolean;
}>("ai/clearAiChanges");

export const getWidgetEditActionsStream = createAsyncThunk<
  { actions: ComponentEditAction[] },
  GetWidgetEditsPayload
>(
  "ai/getWidgetEditActionsStream",
  async (
    {
      component,
      parent,
      prompt,
      rawPrompt,
      context,
      widgetId,
      aiServiceURL,
      allEntities,
      sessionId,
    }: GetWidgetEditsPayload,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setIsLoading(true));
    thunkAPI.dispatch(updateHistoryByWidgetId({ widgetId, prompt }));

    const actions: ComponentEditAction[] = [];
    let batchedActions: ComponentEditAction[] = [];
    let processingInterval: NodeJS.Timeout | null = null;

    const startProcessingInterval = () => {
      processingInterval = setInterval(() => {
        if (batchedActions.length > 0) {
          actions.push(...batchedActions);
          thunkAPI.dispatch(
            processAiActions({
              actions: fastClone(actions),
              existingWidgetId: widgetId,
            }),
          );
          batchedActions = [];
        }
      }, PROCESSING_INTERVAL);
    };

    const onMessage = (message: any) => {
      const event = message;
      if (event.summary) {
        thunkAPI.dispatch(
          processAiProperties({
            widgetId,
            properties: [],
            noResultsResponse: {
              summary: event.summary,
              suggestion: event.suggestion?.trim(),
            },
            widgetType: component.staticProperties.widgetType ?? "",
          }),
        );
      } else if (event.properties) {
        thunkAPI.dispatch(
          processAiProperties({
            widgetId,
            properties: event.properties,
            widgetType: component.staticProperties.widgetType ?? "",
          }),
        );
      } else if (event) {
        if (!processingInterval) {
          startProcessingInterval();
        }
        batchedActions.push(event);
      }
      thunkAPI.dispatch(addAction(event));
    };

    const matchingPredefinedResponse = PREDEFINED_PROMPT_RESPONSES.find(
      (response) =>
        formatPredefinedPrompt(rawPrompt).includes(
          formatPredefinedPrompt(response.rawPrompt),
        ),
    );

    if (prompt === "test") {
      for (const action of TEST_ACTIONS) {
        onMessage(action);
        await sleep(500);
      }
    } else if (matchingPredefinedResponse) {
      for (const action of matchingPredefinedResponse.actions) {
        const localStorageTimeout = Number(
          localStorage.getItem("ai_prompt_timeout"),
        );
        const timeoutToUse =
          !isNaN(localStorageTimeout) && localStorageTimeout > 0
            ? localStorageTimeout
            : AI_PROMPT_TIMEOUT_DEFAULT;

        await sleep(Math.abs(timeoutToUse));
        onMessage(action);
      }
    } else {
      await getAiWidgetEditActionsStream({
        aiServiceURL,
        component,
        parent,
        prompt,
        context,
        sessionId,
        signal: thunkAPI.signal,
        entityNames: allEntities,
        onResponse: (response: Response) => {
          // get the header from the response
          // Check if response.headers exists before trying to access it
          const actionsRequestId = response?.headers?.get(
            "x-superblocks-feedback-id",
          );
          if (actionsRequestId) {
            thunkAPI.dispatch(setActionsRequestId(actionsRequestId));
          }
        },
        onError: (error: string, code?: string) => {
          if (code === "MESSAGE_ERROR") {
            thunkAPI.dispatch(
              setError({
                error,
              }),
            );
          } else {
            if (processingInterval) {
              clearInterval(processingInterval);
            }
            thunkAPI.dispatch(
              setError({
                error,
              }),
            );
          }
        },
        onMessage,
      });
    }

    // clean up the interval and process any remaining batched actions
    if (processingInterval) {
      clearInterval(processingInterval);
      if (batchedActions.length > 0) {
        actions.push(...batchedActions);
        thunkAPI.dispatch(
          processAiActions({
            actions: fastClone(actions),
            existingWidgetId: widgetId,
          }),
        );
      }
    }

    thunkAPI.dispatch(setIsLoading(false));

    return {
      actions,
    };
  },
);

export const sendWidgetEditActionsFeedback = createAsyncThunk<
  void,
  ComponentEditFeedbackAction & {
    aiServiceURL: string;
  }
>("ai/sendAiWidgetEditActionsFeedback", async (payload, thunkAPI) => {
  sendAiWidgetEditActionsFeedback(payload);
});

export const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    resetAiState: (state, action: { payload: { shouldClose: boolean } }) => {
      state.actionsRequestId = undefined;
      state.changedKeys = undefined;
      state.dataTreeChanges = undefined;
      state.initialDataTreeChanges = undefined;
      state.isLoading = false;
      state.actions = undefined;
      state.widgetRename = undefined;
      state.propertiesToChange = undefined;
      state.error = undefined;
      state.discardedEdits = undefined;
      state.metadataByWidgetId = undefined;
      state.noResultsResponse = undefined;
      state.layoutChangesByWidgetId = undefined;
      state.preAiLayoutValuesByWidgetId = undefined;
      if (action.payload.shouldClose) {
        state.selectedWidgetId = undefined;
        state.initialPosition = undefined;
      }
    },
    resetLayoutChanges: (state) => {
      state.layoutChangesByWidgetId = undefined;
      state.preAiLayoutValuesByWidgetId = undefined;
    },
    setAiChanges: (
      state,
      action: {
        payload: {
          changedKeys?: Record<string, string[]>;
          dataTreeChanges?: Record<string, Record<string, unknown> | null>;
          rename?: string;
          discardedEdits: DiscardedEdit[];
          metadataByWidgetId?: Record<string, EditMetadata>;
          layoutChangesByWidgetId?: Record<string, Record<string, unknown>>;
          preAiLayoutValuesByWidgetId?: Record<string, Record<string, unknown>>;
        };
      },
    ) => {
      const {
        changedKeys,
        dataTreeChanges,
        rename,
        discardedEdits,
        metadataByWidgetId,
        layoutChangesByWidgetId,
        preAiLayoutValuesByWidgetId,
      } = action.payload;
      state.changedKeys = changedKeys;
      state.dataTreeChanges = dataTreeChanges;
      state.initialDataTreeChanges = dataTreeChanges;
      state.widgetRename = rename;
      state.discardedEdits = discardedEdits;
      state.metadataByWidgetId = metadataByWidgetId ?? state.metadataByWidgetId;
      state.layoutChangesByWidgetId =
        layoutChangesByWidgetId ?? state.layoutChangesByWidgetId;
      state.preAiLayoutValuesByWidgetId =
        preAiLayoutValuesByWidgetId ?? state.preAiLayoutValuesByWidgetId;
    },
    openAiModal: (
      state,
      action: {
        payload: { widgetId: string; position: { x: number; y: number } };
      },
    ) => {
      state.selectedWidgetId = action.payload.widgetId;
      state.initialPosition = action.payload.position;
      // clear all the previous changes
      state.actionsRequestId = undefined;
      state.changedKeys = undefined;
      state.dataTreeChanges = undefined;
      state.initialDataTreeChanges = undefined;
      state.isLoading = false;
      state.actions = undefined;
      state.widgetRename = undefined;
      state.propertiesToChange = undefined;
      state.error = undefined;
      state.actionsRequestId = undefined;
      state.discardedEdits = undefined;
      state.metadataByWidgetId = undefined;
      state.noResultsResponse = undefined;
      state.layoutChangesByWidgetId = undefined;
      state.preAiLayoutValuesByWidgetId = undefined;
    },
    setIsLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
      if (action.payload) {
        state.error = undefined;
      }
    },
    setError: (state, action: { payload: { error: string } }) => {
      state.error = action.payload.error;
    },
    setPropertiesToChange: (
      state,
      action: { payload: Record<string, string[]> },
    ) => {
      state.propertiesToChange = action.payload;
    },
    setNoResultsResponse: (state, action: { payload: NoResultsResponse }) => {
      state.noResultsResponse = action.payload;
    },
    addAction: (state, action: { payload: ComponentEditAction }) => {
      state.actions = [...(state.actions ?? []), action.payload];
    },
    setActionsRequestId: (state, action: { payload: string }) => {
      state.actionsRequestId = action.payload;
    },
    updateHistoryByWidgetId: (
      state,
      action: { payload: { widgetId: string; prompt: string } },
    ) => {
      state.historyByWidgetId = {
        ...state.historyByWidgetId,
        [action.payload.widgetId]: [
          action.payload.prompt,
          ...(state.historyByWidgetId?.[action.payload.widgetId] ?? []),
        ],
      };
    },
  },
});

export const {
  resetAiState,
  openAiModal,
  setAiChanges,
  setIsLoading,
  setPropertiesToChange,
  setNoResultsResponse,
  resetLayoutChanges,
} = aiSlice.actions;
const { setError, setActionsRequestId, addAction, updateHistoryByWidgetId } =
  aiSlice.actions;
