import React, { ReactElement } from "react";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";
import HomePage from "./HomePage";
import HomePageLegacy from "./HomePageLegacy";

export default function Home(): ReactElement {
  const enableNewHomePage = useFeatureFlag(Flag.ENABLE_NEW_HOME_PAGE);

  return enableNewHomePage ? <HomePage /> : <HomePageLegacy />;
}
