import { useCallback } from "react";
import React from "react";
import { convertAnyDateToNumber } from "store/slices/apisV2";
import { useRenameWorkflowOrScheduledJobMutation } from "store/slices/reduxApi/workflowsAndScheduledJobs";
import RenameEntityModal, { RenameEntityModalProps } from "./RenameEntityModal";

export const RenameWorkflowModal = ({
  entity,
  open,
  closeModal,
}: RenameEntityModalProps) => {
  const workflowId = entity?.id;
  const [renameWorkflow] = useRenameWorkflowOrScheduledJobMutation();

  const lastSuccessfulWriteForApi = convertAnyDateToNumber(entity?.updated);
  const handleWorkflowRename = useCallback(
    async (newName: string) => {
      if (!workflowId) {
        return;
      }
      await renameWorkflow({
        id: workflowId,
        name: newName,
        lastSuccessfulWrite: lastSuccessfulWriteForApi,
      }).unwrap();
    },
    [workflowId, renameWorkflow, lastSuccessfulWriteForApi],
  );

  return (
    <RenameEntityModal
      open={open}
      closeModal={closeModal}
      entity={entity}
      onRename={handleWorkflowRename}
      title="Enter a new workflow name"
      entityLabel="workflow"
    />
  );
};

export default RenameWorkflowModal;
