import React, { useCallback } from "react";
import { DeleteModal } from "components/ui/Modal";
import { useDeleteWorkflowsOrJobsMutation } from "store/slices/reduxApi/workflowsAndScheduledJobs";
import { styleAsClass } from "styles/styleAsClass";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { Entity } from "../shared";

const BoldText = styleAsClass`
  font-weight: 700 !important;
`;

const DeleteScheduledJobModal = ({
  open,
  closeModal,
  entity,
}: {
  open: boolean;
  closeModal: () => void;
  entity: Entity | undefined;
}) => {
  const wfId = entity?.id;
  const [deleteJob, { isLoading: isDeleting }] =
    useDeleteWorkflowsOrJobsMutation();

  const handleDelete = useCallback(async () => {
    if (!wfId) {
      return;
    }
    try {
      await deleteJob({ id: wfId }).unwrap();
      sendSuccessUINotification({
        message: "Scheduled job deleted",
      });
    } catch (e: any) {
      sendErrorUINotification({
        message: e?.message ?? "Failed to delete scheduled job",
      });
    } finally {
      closeModal();
    }
  }, [wfId, deleteJob, closeModal]);

  const handleCancel = useCallback(() => {
    if (isDeleting) return;
    closeModal();
  }, [isDeleting, closeModal]);

  return (
    <DeleteModal
      open={open}
      title="Delete Scheduled Job"
      onCancel={handleCancel}
      onDelete={handleDelete}
      isDeleting={isDeleting}
      dataTestName="job"
      disableDelete={false}
    >
      <p>
        You are about to delete <b className={BoldText}>{entity?.name}</b>. The
        job will be turned off.
      </p>
      <p>Are you sure you want to continue?</p>
    </DeleteModal>
  );
};

export default DeleteScheduledJobModal;
