import React from "react";
import styled from "styled-components";
import { ReactComponent as ButtonIcon } from "assets/icons/widgets/button.svg";
import { ReactComponent as ChartIcon } from "assets/icons/widgets/chart.svg";
import { ReactComponent as ChatIcon } from "assets/icons/widgets/chat.svg";
import { ReactComponent as CheckboxIcon } from "assets/icons/widgets/checkbox.svg";
import { ReactComponent as CodeIcon } from "assets/icons/widgets/code.svg";
import { ReactComponent as ContainerIcon } from "assets/icons/widgets/container.svg";
import { ReactComponent as CustomIcon } from "assets/icons/widgets/custom.svg";
import { ReactComponent as DatepickerIcon } from "assets/icons/widgets/datepicker.svg";
import { ReactComponent as DiffIcon } from "assets/icons/widgets/diff.svg";
import { ReactComponent as DropDownIcon } from "assets/icons/widgets/dropdown.svg";
import { ReactComponent as FormIcon } from "assets/icons/widgets/form.svg";
import { ReactComponent as IconIcon } from "assets/icons/widgets/icon.svg";
import { ReactComponent as IFrameIcon } from "assets/icons/widgets/iframe.svg";
import { ReactComponent as ImageIcon } from "assets/icons/widgets/image.svg";
import { ReactComponent as InputIcon } from "assets/icons/widgets/input.svg";
import { ReactComponent as ListIcon } from "assets/icons/widgets/listview.svg";
import { ReactComponent as MapIcon } from "assets/icons/widgets/map.svg";
import { ReactComponent as MenuIcon } from "assets/icons/widgets/menu.svg";
import { ReactComponent as ModalIcon } from "assets/icons/widgets/modal.svg";
import { ReactComponent as PageIcon } from "assets/icons/widgets/page.svg";
import { ReactComponent as PDFViewerIcon } from "assets/icons/widgets/pdf-viewer.svg";
import { ReactComponent as RadioGroupIcon } from "assets/icons/widgets/radio.svg";
import { ReactComponent as SlideoutIcon } from "assets/icons/widgets/slideout.svg";
import { ReactComponent as TableIcon } from "assets/icons/widgets/table.svg";
import { ReactComponent as TabsIcon } from "assets/icons/widgets/tabs.svg";
import { ReactComponent as TextIcon } from "assets/icons/widgets/text.svg";
import { ReactComponent as VideoIcon } from "assets/icons/widgets/video.svg";
import { ReactComponent as SpinnerIcon } from "legacy/assets/icons/widget/alert.svg";
import { ReactComponent as CalloutIcon } from "legacy/assets/icons/widget/callout.svg";
import { ReactComponent as CollapseIcon } from "legacy/assets/icons/widget/collapse.svg";
import { ReactComponent as FilePickerIcon } from "legacy/assets/icons/widget/filepicker.svg";
import { ReactComponent as KeyValueIcon } from "legacy/assets/icons/widget/keyvalue.svg";
import { ReactComponent as LinkIcon } from "legacy/assets/icons/widget/link.svg";
import { ReactComponent as RichTextEditorIcon } from "legacy/assets/icons/widget/rich-text.svg";
import { ReactComponent as SwitchIcon } from "legacy/assets/icons/widget/switch.svg";
import { IconProps, IconWrapper } from "legacy/constants/IconConstants";
import { WidgetType } from "legacy/constants/WidgetConstants";

const IconWrapperNoFill = styled(IconWrapper)`
  svg > .no-fill {
    fill: transparent !important;
  }
`;

export const WidgetIcons: {
  [key: string | WidgetType]: (props: IconProps) => JSX.Element;
} = {
  SPINNER_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <SpinnerIcon />
    </IconWrapper>
  ),
  BUTTON_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ButtonIcon />
    </IconWrapper>
  ),
  CHECKBOX_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <CheckboxIcon />
    </IconWrapper>
  ),
  SWITCH_WIDGET: (props: IconProps) => (
    <IconWrapperNoFill {...props}>
      <SwitchIcon />
    </IconWrapperNoFill>
  ),
  COLLAPSE_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <CollapseIcon />
    </IconWrapper>
  ),
  CONTAINER_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ContainerIcon />
    </IconWrapper>
  ),
  CANVAS_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ContainerIcon />
    </IconWrapper>
  ),
  PAGE_WIDGET: (props: IconProps) => (
    <IconWrapperNoFill {...props}>
      <PageIcon />
    </IconWrapperNoFill>
  ),
  SECTION_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ContainerIcon />
    </IconWrapper>
  ),
  DATE_PICKER_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <DatepickerIcon />
    </IconWrapper>
  ),
  TABLE_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <TableIcon />
    </IconWrapper>
  ),
  VIDEO_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <VideoIcon />
    </IconWrapper>
  ),
  DROP_DOWN_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <DropDownIcon />
    </IconWrapper>
  ),
  RADIO_GROUP_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <RadioGroupIcon />
    </IconWrapper>
  ),
  INPUT_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <InputIcon />
    </IconWrapper>
  ),
  RICH_TEXT_EDITOR_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <RichTextEditorIcon />
    </IconWrapper>
  ),
  TEXT_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <TextIcon />
    </IconWrapper>
  ),
  IMAGE_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ImageIcon />
    </IconWrapper>
  ),
  ICON_WIDGET: (props: IconProps) => (
    <IconWrapperNoFill {...props}>
      <IconIcon />
    </IconWrapperNoFill>
  ),
  FILE_PICKER_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <FilePickerIcon />
    </IconWrapper>
  ),
  TABS_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <TabsIcon />
    </IconWrapper>
  ),
  CHART_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ChartIcon />
    </IconWrapper>
  ),
  FORM_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <FormIcon />
    </IconWrapper>
  ),
  MAP_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <MapIcon />
    </IconWrapper>
  ),
  MODAL_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ModalIcon />
    </IconWrapper>
  ),
  SLIDEOUT_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <SlideoutIcon />
    </IconWrapper>
  ),
  FORM_BUTTON_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ButtonIcon />
    </IconWrapper>
  ),
  GRID_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <ListIcon />
    </IconWrapper>
  ),
  CODE_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <CodeIcon />
    </IconWrapper>
  ),
  PDF_VIEWER_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <PDFViewerIcon />
    </IconWrapper>
  ),
  IFRAME_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <IFrameIcon />
    </IconWrapper>
  ),
  DIFF_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <DiffIcon />
    </IconWrapper>
  ),
  CHAT_WIDGET: (props: IconProps) => (
    <IconWrapperNoFill {...props}>
      <ChatIcon />
    </IconWrapperNoFill>
  ),
  CALLOUT_WIDGET: (props: IconProps) => (
    <IconWrapperNoFill {...props}>
      <CalloutIcon />
    </IconWrapperNoFill>
  ),
  MENU_WIDGET: (props: IconProps) => (
    <IconWrapper {...props}>
      <MenuIcon />
    </IconWrapper>
  ),
  LINK_WIDGET: (props: IconProps) => (
    <IconWrapperNoFill {...props}>
      <LinkIcon />
    </IconWrapperNoFill>
  ),
  // fallback icon for all custom widgets
  CUSTOM_WIDGET: (props: IconProps) => (
    <IconWrapperNoFill {...props}>
      <CustomIcon />
    </IconWrapperNoFill>
  ),
  KEY_VALUE_WIDGET: (props: IconProps) => (
    <IconWrapperNoFill {...props}>
      <KeyValueIcon />
    </IconWrapperNoFill>
  ),
};

export const getWidgetIcon = (widgetType: WidgetType) => {
  return WidgetIcons[widgetType] || WidgetIcons.CUSTOM_WIDGET;
};
