import { merge } from "lodash";
import { put, select } from "redux-saga/effects";
import { updatePartialLayout } from "legacy/actions/pageActions";
import { getWidgets } from "legacy/selectors/sagaSelectors";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { fastClone } from "utils/clone";
import { selectAiState } from "../selectors";

export function* updateLayoutFromAiSaga() {
  const aiState: ReturnType<typeof selectAiState> = yield select(selectAiState);
  if (!aiState || !aiState.selectedWidgetId) {
    return;
  }

  if (
    !aiState.layoutChangesByWidgetId ||
    !Object.keys(aiState.layoutChangesByWidgetId).length
  ) {
    return;
  }
  const canvasWidgets: CanvasWidgetsReduxState = yield select(getWidgets);
  const editableWidgets = fastClone(canvasWidgets);

  // apply aichanges to canvasWidgets
  const updatedWidgets = merge(
    editableWidgets,
    aiState.layoutChangesByWidgetId,
  );

  yield put(updatePartialLayout(updatedWidgets, false));
}
