import { WidgetTypes } from "@superblocksteam/shared";
import { get, set, mergeWith, omit } from "lodash";
import {
  PropertyPaneConfig,
  PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import { WidgetFactory } from "legacy/widgets";
import BaseWidget from "legacy/widgets/BaseWidget";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import ButtonWidget from "legacy/widgets/ButtonWidget/ButtonWidget";
import { MENU_ITEM_PROPERTIES } from "legacy/widgets/MenuWidget/MenuItemProperties";
import { OMITTED_PROPS } from "store/slices/ai/constants";
import { AllFlags } from "store/slices/featureFlags/models/Flags";
import type { GeneratedTheme } from "legacy/themes/types";

/**
 * Sets a nested property value in an object using a dotted path notation.
 * Creates intermediate objects if they don't exist.
 * Throws error if attempting to modify frozen objects.
 */
export const setExpandedNestedProperty = (
  obj: Record<string, any>,
  dottedPropertyPath: string,
  value: any,
): void => {
  if (typeof dottedPropertyPath !== "string") {
    return;
  }
  const parts = dottedPropertyPath.split(".");
  const lastPart = parts.pop();

  if (!lastPart) {
    throw new Error(
      "Invalid property path: cannot end with a dot or be empty.",
    );
  }

  if (obj == null) {
    throw new Error("Cannot modify nullish object");
  }

  const parentPath = parts.join(".");

  if (parentPath) {
    // Check if parent object is frozen before attempting to modify
    const currentParent = get(obj, parts[0]);
    if (currentParent && Object.isFrozen(currentParent)) {
      throw new Error(`Cannot modify frozen object at path: ${parts[0]}`);
    }

    // Ensure the parent object exists
    const parentObject = get(obj, parentPath, {});

    // Check if the specific property is frozen
    if (parentObject && Object.isFrozen(parentObject)) {
      throw new Error(`Cannot modify frozen object at path: ${parentPath}`);
    }

    set(parentObject, lastPart, value);
    set(obj, parentPath, parentObject);
  } else {
    // Check if root object is frozen
    if (obj && Object.isFrozen(obj)) {
      throw new Error(`Cannot modify frozen object`);
    }
    set(obj, dottedPropertyPath, value);
  }
};

type FlattenedObject = Record<string, any>;
export const flattenObject = (
  obj: Record<string, any>,
  parent: string = "",
  result: FlattenedObject = {},
): FlattenedObject => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const fullPath = parent ? `${parent}.${key}` : key;
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], fullPath, result);
      } else {
        result[fullPath] = obj[key];
      }
    }
  }
  return result;
};

export const unflattenObject = (
  obj: Record<string, any>,
): Record<string, any> => {
  const result = {};

  Object.entries(obj).forEach(([path, value]) => {
    set(result, path, value);
  });

  return result;
};

// Handles adding a value to the widgetThemeValues object,
// and also handles flattening the value if it's an object
const addValueToThemeAndDefaults = (
  value: any,
  widgetThemeValues: Record<string, any>,
  pathToSet: string,
) => {
  if (typeof value !== "object" || value == null || Array.isArray(value)) {
    widgetThemeValues[pathToSet] = value;
    return;
  }

  const flattened = flattenObject(value);
  Object.entries(flattened).forEach(([flattenedKey, flattenedValue]) => {
    widgetThemeValues[`${pathToSet}.${flattenedKey}`] = flattenedValue;
  });
};

const getWidgetPropertyDefaultValue = ({
  props,
  propertyName,
  itemProperties,
  flags,
  theme,
}: {
  props: any;
  propertyName: string;
  itemProperties: any;
  flags: any;
  theme: any;
}) => {
  let defaultValue = props.defaultValue;

  if (props.defaultValueFn) {
    defaultValue = props.defaultValueFn({
      propertyName,
      props: itemProperties,
      flags,
      theme,
    });
  }

  return defaultValue;
};

export const getWidgetPropertyThemeValue = ({
  props,
  propertyName,
  itemProperties,
  flags,
  theme,
  widgets,
}: {
  props: any; // the property config
  propertyName: string; // the widget property name
  itemProperties: any; // the widget DSL (properties with values)
  flags: any;
  theme: any;
  widgets: Record<string, any>;
}) => {
  let properties = itemProperties;
  if (props.getTargetWidgetId) {
    const targetId = props.getTargetWidgetId(itemProperties);
    if (targetId && widgets[targetId]) {
      properties = widgets[targetId];
    }
  }
  const themeValueResp =
    typeof props.themeValue === "function"
      ? props.themeValue({
          theme,
          props: properties,
          flags,
          propertyName,
        })
      : { value: props.themeValue, treatAsNull: false };
  const themeValue = themeValueResp?.value;
  return themeValue;
};

const startsWithAnyOf = (str: string, prefixes: string[]) => {
  return prefixes.some((prefix) => str.startsWith(prefix));
};

const valueNeedsThemePrefix = (value: string) => {
  return (
    typeof value === "string" &&
    startsWithAnyOf(value, ["colors", "typographies"])
  );
};

const prefixValueWithThemeIfNeeded = (value: string) => {
  return valueNeedsThemePrefix(value) ? `{{ theme.${value} }}` : value;
};

const destructureThemeValue = (themeValue: any): any => {
  if (
    themeValue != null &&
    typeof themeValue === "object" &&
    themeValue.value != null
  ) {
    return themeValue.value;
  }
  return themeValue;
};

const deleteTreatAsNulls = (obj: Record<string, any>) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value?.treatAsNull) {
      delete obj[key];
    }
  });
};

const WIDGETS_DEFAULT_VALUE_SHOULD_BE_INCLUDED: Record<string, string[]> = {
  BUTTON_WIDGET: ["textProps.textStyle"],
  CONTAINER_WIDGET: ["borderRadius"],
};

const PROPERTIES_WITH_DEFAULT_VALUES_INCLUDED: string[] = [
  "margin",
  "padding",
  "buttonStyle",
];

const generateBorder = ({ color, width }: { color: string; width: number }) => {
  return ["left", "right", "top", "bottom"].reduce(
    (acc, side) => {
      acc[side] = {
        style: "solid",
        color,
        width: {
          mode: "px",
          value: width,
        },
      };
      return acc;
    },
    {} as Record<string, any>,
  );
};

const UNSET_PROPERTY_DEFAULT_VALUES: Record<string, boolean> = {
  isVisible: true,
  collapseWhenHidden: true,
  animateLoading: true,
  isDisabled: false,
  isRequired: false,
  isVertical: true,
};

const getPropertyPaneConfig = (
  widgetClass: typeof BaseWidget,
): PropertyPaneConfig[] => {
  if (widgetClass.prototype.getWidgetType() === "CUSTOM_WIDGET") {
    // Custom widgets do not have statically defined properties.
    return [];
  }

  let newConfig: PropertyPaneConfig[] | undefined;
  if (widgetClass.getNewPropertyPaneConfig) {
    newConfig = widgetClass.getNewPropertyPaneConfig();
  }

  if (newConfig && newConfig.length > 0) {
    return newConfig;
  } else if (widgetClass.getPropertyPaneConfig) {
    return widgetClass.getPropertyPaneConfig();
  }

  return [];
};

export const getWidgetThemeAndDefaultValues = ({
  widgetClass,
  widget,
  flags,
  theme,
  widgets,
}: {
  widgetClass: typeof BaseWidget;
  widget: Record<string, any>;
  flags: Partial<AllFlags>;
  theme: GeneratedTheme;
  widgets: Record<string, any>;
}) => {
  if (
    !widgetClass ||
    (!widgetClass.getPropertyPaneConfig &&
      !widgetClass.getNewPropertyPaneConfig)
  ) {
    return {};
  }

  const widgetThemeValues: Record<string, any> = {};
  const getThemeValueForConfig = (
    propertyConfig: PropertyPaneControlConfig,
    parentPath?: string,
  ) => {
    if (propertyConfig.propertyName === "primaryColumns") {
      // we handle each primary column individually, don't override this with null
      return;
    }

    if (typeof propertyConfig.propertyName !== "string") {
      return;
    }
    const pathToSet = parentPath
      ? `${parentPath}.${propertyConfig.propertyName}`
      : propertyConfig.propertyName;

    if (propertyConfig.themeValue != null) {
      const themeValue = getWidgetPropertyThemeValue({
        props: propertyConfig,
        propertyName: propertyConfig.propertyName,
        itemProperties: widget,
        flags,
        theme,
        widgets,
      });

      const defaultValue = getWidgetPropertyDefaultValue({
        props: propertyConfig,
        propertyName: propertyConfig.propertyName,
        itemProperties: widget,
        flags,
        theme,
      });
      const isATextStyleProperty =
        propertyConfig.propertyName.endsWith("textStyle");

      let finalThemeValue: any;

      // Some of our text style properties have default values that have the variant the component is using for the property, and the theme value has the color (or vice versa) so we need to merge them and use that value here
      // TODO: We should figure out why, and if that's required, and if not fix it by only using themeValue
      if (isATextStyleProperty) {
        let typographyValue: any = {};
        const typography = get(
          widget,
          `${propertyConfig.propertyName}.variant`,
          get(defaultValue, "variant"),
        );
        if (typography && typography !== SB_CUSTOM_TEXT_STYLE) {
          typographyValue = omit(
            get(theme, `typographies.${typography}`, {}),
            "textColor", // we don't want to override the text color
          );
        }
        finalThemeValue = mergeWith(
          {},
          defaultValue,
          { ...themeValue, ...typographyValue },
          (objValue, srcValue) => {
            if (srcValue !== undefined) {
              return srcValue;
            }
            return objValue;
          },
        );

        // Destructure the themeValue value into the right DSL value
        // which means removing treatAsNull, using .value directly,
        // and prefixing with theme if needed
        if (finalThemeValue["textColor.default"]) {
          finalThemeValue["textColor.default"] =
            prefixValueWithThemeIfNeeded(
              destructureThemeValue(finalThemeValue["textColor.default"]),
            ) ?? null;
        } else {
          finalThemeValue["textColor.default"] = null;
        }
      }
      // Is there a default value that should be used "as a theme value"?
      else if (
        WIDGETS_DEFAULT_VALUE_SHOULD_BE_INCLUDED[widget.type]?.includes(
          propertyConfig.propertyName,
        ) &&
        defaultValue != null
      ) {
        finalThemeValue = prefixValueWithThemeIfNeeded(
          destructureThemeValue(defaultValue),
        );
      }
      // Otherwise, use the theme value
      else if (themeValue != null) {
        finalThemeValue = prefixValueWithThemeIfNeeded(themeValue);
      }

      if (finalThemeValue !== undefined) {
        // if the finalThemeValue is an object, ensure we
        // delete any treatAsNull properties anywhere in the object
        if (typeof finalThemeValue === "object") {
          deleteTreatAsNulls(finalThemeValue);
        }

        addValueToThemeAndDefaults(
          finalThemeValue,
          widgetThemeValues,
          pathToSet,
        );
      }
    } else if (
      PROPERTIES_WITH_DEFAULT_VALUES_INCLUDED.includes(
        propertyConfig.propertyName,
      ) &&
      propertyConfig.defaultValue != null
    ) {
      const defaultValue = getWidgetPropertyDefaultValue({
        props: propertyConfig,
        propertyName: propertyConfig.propertyName,
        itemProperties: widget,
        flags,
        theme,
      });

      addValueToThemeAndDefaults(defaultValue, widgetThemeValues, pathToSet);
    } else if (propertyConfig.propertyName === "border") {
      // Border has an exception because we need to populate the border with 0 width
      // and also keep the color from the default

      const defaultBorder = getWidgetPropertyDefaultValue({
        props: propertyConfig,
        propertyName: propertyConfig.propertyName,
        itemProperties: widget,
        flags,
        theme,
      });
      const zeroBorder = generateBorder({
        color: defaultBorder.left.color,
        width: 0,
      });
      addValueToThemeAndDefaults(zeroBorder, widgetThemeValues, pathToSet);
    } else if (!OMITTED_PROPS.includes(propertyConfig.propertyName)) {
      // otherwise include a default value for the property
      let defaultValue: any = null;

      if (
        UNSET_PROPERTY_DEFAULT_VALUES[propertyConfig.propertyName] !== undefined
      ) {
        defaultValue =
          UNSET_PROPERTY_DEFAULT_VALUES[propertyConfig.propertyName];
      } else if (propertyConfig.isTriggerProperty) {
        defaultValue = [];
      }

      addValueToThemeAndDefaults(defaultValue, widgetThemeValues, pathToSet);
    }
  };

  forEachWidgetProperty({
    widgetClass,
    widget,
    callbackFn: getThemeValueForConfig,
  });

  const filteredWidgetThemeValues = Object.fromEntries(
    Object.entries(widgetThemeValues).filter(
      ([_, value]) => value !== undefined,
    ),
  );

  return filteredWidgetThemeValues;
};

const processProperties = ({
  propertyPaneConfig,
  widget,
  parentPath,
  callbackFn,
}: {
  propertyPaneConfig: PropertyPaneConfig[];
  widget: Partial<WidgetProps>;
  parentPath?: string;
  callbackFn: (
    propertyConfig: PropertyPaneControlConfig,
    parentPath?: string,
  ) => void;
}) => {
  for (const section of propertyPaneConfig) {
    if (section.children) {
      for (const propertyConfig of section.children as any) {
        if (propertyConfig.propertyName === "manualChildren") {
          const manualChildren = (widget as any)?.manualChildren ?? [];
          manualChildren.forEach((_child: any, index: number) => {
            processProperties({
              propertyPaneConfig: MENU_ITEM_PROPERTIES,
              widget: (widget as any).manualChildren[index],
              parentPath: `${propertyConfig.propertyName}[${index}]`,
              callbackFn,
            });
          });
        } else if (
          propertyConfig.panelConfig &&
          !propertyConfig.isTriggerProperty
        ) {
          const panelChildren = propertyConfig.panelConfig.children;
          if (propertyConfig.propertyName === "primaryColumns") {
            const primaryColumnIds = Object.keys(
              (widget as any).primaryColumns ?? {},
            );
            primaryColumnIds.forEach((id) => {
              processProperties({
                propertyPaneConfig: panelChildren,
                widget,
                parentPath: `${propertyConfig.propertyName}.${id}`,
                callbackFn,
              });
            });
          } else {
            const { panelIdPropertyName } = (
              propertyConfig as PropertyPaneControlConfig
            ).panelConfig!;

            const subPath = get(
              widget,
              `${propertyConfig.propertyName}.${panelIdPropertyName}`,
            );
            const nestedParentPath = subPath
              ? `${propertyConfig.propertyName}.${subPath}`
              : propertyConfig.propertyName;
            processProperties({
              propertyPaneConfig: panelChildren,
              widget,
              parentPath: nestedParentPath,
              callbackFn,
            });
            callbackFn(propertyConfig, parentPath);
          }
        }

        if (propertyConfig.propertyName) {
          callbackFn(propertyConfig, parentPath);
        }
        if (propertyConfig.children) {
          for (const child of propertyConfig.children) {
            if (child.propertyName) {
              callbackFn(child, parentPath);
            }
          }
        }
      }
    }
  }
};

export const forEachWidgetProperty = ({
  widgetClass,
  widget,
  callbackFn,
}: {
  widgetClass: typeof BaseWidget;
  widget: Partial<WidgetProps>;
  callbackFn: (
    propertyConfig: PropertyPaneControlConfig,
    parentPath?: string,
  ) => void;
}) => {
  if (
    !widgetClass ||
    (!widgetClass.getPropertyPaneConfig &&
      !widgetClass.getNewPropertyPaneConfig)
  ) {
    return;
  }
  const propertyPaneConfig = getPropertyPaneConfig(widgetClass);

  processProperties({
    propertyPaneConfig,
    widget,
    callbackFn,
  });
};

export const getWidgetClassForType = (type: WidgetTypes) => {
  if (!type) return ButtonWidget;
  return WidgetFactory.getWidgetClasses()[type] ?? ButtonWidget;
};

export const isPropertyRemovableOrResettable = (
  property: PropertyPaneControlConfig,
  widget: Partial<WidgetProps>,
  context: { theme: GeneratedTheme; featureFlags: Partial<AllFlags> },
) => {
  if (typeof property.propertyName !== "string") {
    return { isRemovable: false, isResettable: false };
  }
  const themeValueResp =
    typeof property.themeValue === "function"
      ? property.themeValue({
          theme: context.theme,
          props: widget,
          flags: context.featureFlags,
          propertyName: property.propertyName,
        })
      : { value: property.themeValue, treatAsNull: false };
  const isResettable =
    themeValueResp?.value !== undefined && !themeValueResp?.treatAsNull;
  const isRemovable =
    !isResettable &&
    property.isRemovable &&
    (property.visibility === "IF_VALUE" || property.visibility === "SHOW_NAME");

  return {
    isRemovable,
    isResettable,
  };
};
