import { call, put, select } from "redux-saga/effects";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { fastClone } from "utils/clone";
import { selectAiState } from "../selectors";
import { setAiChanges } from "../slice";
import { removeChildInAiChanges } from "../slice";
import { applyWidgetHooksToAiEdits } from "./utils";

export function* removeChildInAiChangesSaga(
  action: ReturnType<typeof removeChildInAiChanges>,
): Generator<any, void, any> {
  // If it's a new child from ai:
  // 1. We need to delete from the dataTreeChanges
  // 2. We need to remove from dataTreeChanges.children
  // If it's an existing child:
  // 1. We need to ensure it's reflected in the dataTreeChanges.children for the selected ai widget so gets removed

  const {
    dataTreeChanges,
    changedKeys,
    discardedEdits,
    selectedWidgetId,
  }: ReturnType<typeof selectAiState> = yield select(selectAiState);

  const { widgetId } = action.payload;
  if (!selectedWidgetId) {
    return;
  }

  const existingWidget: ReturnType<typeof getWidget> = yield select((state) =>
    getWidget(state, selectedWidgetId ?? ""),
  );

  const newDataTreeChanges = fastClone(dataTreeChanges || {});
  newDataTreeChanges[widgetId] = null;

  const newDataTreeChangesForSelectedWidget = fastClone(
    newDataTreeChanges[selectedWidgetId] || {},
  );

  if (newDataTreeChangesForSelectedWidget.children) {
    newDataTreeChangesForSelectedWidget.children = (
      newDataTreeChangesForSelectedWidget.children as string[]
    ).filter((child: string) => child !== widgetId);
  } else {
    // currently children is not set, so we need to set it to the current value from redux
    // and then remove the widgetId from it
    newDataTreeChangesForSelectedWidget.children = (
      existingWidget.children as string[]
    ).filter((child: string) => child !== widgetId);
  }

  yield call(applyWidgetHooksToAiEdits, {
    changes: newDataTreeChanges,
    existingWidget,
  });

  yield put(
    setAiChanges({
      changedKeys: changedKeys || {},
      dataTreeChanges: {
        ...newDataTreeChanges,
        [selectedWidgetId]: newDataTreeChangesForSelectedWidget,
      },
      discardedEdits: discardedEdits || [],
    }),
  );
}
