import { set } from "lodash";
import { put, select } from "redux-saga/effects";
import { WidgetTypes } from "legacy/constants/WidgetConstants";
import { getWidget } from "legacy/selectors/sagaSelectors";
import {
  type TabsWidgetProps,
  TabContainerWidgetProps,
} from "legacy/widgets/TabsWidget/types";
import { fastClone } from "utils/clone";
import { selectAiState } from "../selectors";
import { setAiChanges, restoreDeletedChildInAiChanges } from "../slice";

export function* restoreDeletedChildInAiChangesSaga(
  action: ReturnType<typeof restoreDeletedChildInAiChanges>,
) {
  const {
    changedKeys,
    dataTreeChanges,
    widgetRename,
    discardedEdits,
    selectedWidgetId,
  }: ReturnType<typeof selectAiState> = yield select(selectAiState);

  const { childId } = action.payload;

  const existingWidget: ReturnType<typeof getWidget> = yield select((state) =>
    getWidget(state, selectedWidgetId ?? ""),
  );

  const existingChildWidget: ReturnType<typeof getWidget> = yield select(
    (state) => getWidget(state, childId ?? ""),
  );

  if (!existingWidget || !existingChildWidget) {
    throw new Error("Widget or child widget not found");
  }

  // Restore the child in the children changes key
  const newChanges = fastClone(dataTreeChanges || {}) as Record<
    string,
    Record<string, any> | null
  >;
  const newChangesForExistingWidget = fastClone(
    newChanges?.[existingWidget.widgetId] || {},
  ) as Record<string, any>;

  if (newChangesForExistingWidget) {
    set(newChangesForExistingWidget, "children", [
      ...((newChangesForExistingWidget?.children ?? []) as string[]),
      childId,
    ]);
  }

  // If the selectedWidget is a tabs widget, we need to add the tab back too
  if (existingWidget.type === WidgetTypes.TABS_WIDGET) {
    const tabsWidget =
      existingWidget as TabsWidgetProps<TabContainerWidgetProps>;

    // find the original tab
    const originalTab = tabsWidget.tabs?.find(
      (tab) => tab.widgetId === childId,
    );

    // find the index of the original tab
    const originalTabIndex = tabsWidget.tabs?.findIndex(
      (tab) => tab.widgetId === childId,
    );

    if (originalTab && originalTabIndex !== -1) {
      if (!newChangesForExistingWidget.tabs) {
        set(newChangesForExistingWidget, "tabs", []);
      }
      (newChangesForExistingWidget.tabs as any[]).splice(
        originalTabIndex,
        0,
        originalTab,
      );
    }
  }

  // Restore the child in the changes
  // undefined key means it wont get deleted when we accept
  delete newChanges[childId];

  yield put(
    setAiChanges({
      dataTreeChanges: {
        ...newChanges,
        [existingWidget.widgetId]: newChangesForExistingWidget,
      },
      changedKeys: changedKeys ?? {},
      discardedEdits: discardedEdits ?? [],
      rename: widgetRename,
    }),
  );
}
