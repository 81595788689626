import {
  ActionTypeEnum,
  ENVIRONMENT_PRODUCTION,
  ScheduleState,
} from "@superblocksteam/shared";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Cell, SortingRule } from "react-table";
import { ReactComponent as PencilIcon } from "assets/icons/common/edit.svg";
import { ReactComponent as AppIcon } from "assets/icons/home/app_small.svg";
import { ReactComponent as FolderIcon } from "assets/icons/home/folder.svg";
import { ReactComponent as JobIcon } from "assets/icons/home/job_small.svg";
import { ReactComponent as WorkflowIcon } from "assets/icons/home/workflow_small.svg";
import Link, { UnderlinedLinkClass } from "components/ui/Link";
import RecommendedTable, {
  type ActionMenuItem,
  DEFAULT_TABLE_PAGE_SIZE,
  RecColumn,
  RecommendedTableRef,
  QuickActionItem,
} from "components/ui/RecommendedTable";
import TruncatedTextTooltip from "components/ui/TruncatedTextTooltip";
import {
  CREATE_APPLICATIONS,
  CREATE_JOBS,
  CREATE_WORKFLOWS,
  MANAGE_FOLDERS,
} from "constants/rbac";
import { useSaga } from "hooks/store";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import useLocalStorageState from "hooks/ui/useLocalStorageState";
import {
  EventType,
  ExecuteActionCallStack,
} from "legacy/constants/ActionConstants";
import {
  SCHEDULED_JOB_CONFIGURE_URL,
  HomeModalRoutes,
  WORKFLOW_CONFIGURE_URL,
} from "legacy/constants/routes";
import { extractCurrentBranchFromSearchStringOrStorage } from "pages/Repositories/utils";
import { cloneApiSaga } from "store/slices/apis";
import { executeV2ApiSaga, fetchV2ApiSaga } from "store/slices/apisV2";
import { duplicateApplicationInit } from "store/slices/homepage/slice";
import { useUpdateScheduleStateMutation } from "store/slices/reduxApi/workflowsAndScheduledJobs";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { EntityType } from "utils/entity";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { MoveEntitySearchableList } from "./MoveEntitySearchableList";
import {
  Entity,
  FolderIdToNameMap,
  getFolderNameFromFolderIdToNameMap,
} from "./shared";
// We only have scheduled jobs running in production at this time.
const SCHEDULED_JOB_ENVIRONMENT = ENVIRONMENT_PRODUCTION;

type ColType = RecColumn<Entity>;

const NAME_MAX_WIDTH = 400;
const ICON_AND_PADDING_WIDTH = 30;

const NameStyle = styleAsClass`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NameWrapperStyle = styleAsClass`
  max-width: ${String(NAME_MAX_WIDTH + ICON_AND_PADDING_WIDTH)}px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DeleteLabel = <div style={{ color: colors.DANGER }}>Delete</div>;

const renderTime = ({ value }: { value: Date | null | undefined }) =>
  value ? (
    <div>{moment(value).format("MMMM D, YYYY")}</div>
  ) : (
    <div style={{ color: colors.GREY_200 }}>–</div>
  );

const renderName = ({
  value,
  cell,
  locationSearch,
}: {
  value?: string;
  cell: Cell<Entity, string | undefined>;
  locationSearch: string;
}) => {
  let link = "";
  let icon = null;

  switch (cell.row.original.type) {
    case EntityType.APPLICATION:
      link = cell.row.original.deployUrl ?? "";
      icon = <AppIcon />;
      break;
    case EntityType.WORKFLOW:
      link = cell.row.original.editUrl ?? "";
      icon = <WorkflowIcon />;
      break;
    case EntityType.SCHEDULED_JOB:
      link = cell.row.original.editUrl ?? "";
      icon = <JobIcon />;
      break;
    case "folder":
      link = `/folders/${cell.row.original.id}${locationSearch}`;
      icon = <FolderIcon />;
      break;
  }
  return (
    <div className={NameWrapperStyle}>
      {icon}
      <div style={{ maxWidth: NAME_MAX_WIDTH }}>
        <Link to={link} className={UnderlinedLinkClass}>
          <TruncatedTextTooltip
            text={value ?? ""}
            className={`${NameStyle} ${UnderlinedLinkClass}`}
          />
        </Link>
      </div>
    </div>
  );
};

const renderCreator = ({
  value,
}: {
  value: { id: string; name: string } | undefined;
}) => {
  return <div>{value?.name ?? ""}</div>;
};

const EntityTable = ({
  entities,
  loading,
  totalCount,
  setFocusedEntity,
  openModal,
  showFolderLocation,
  isInRoot,
  folderIdToNameMap,
  onMultiRowsSelectChange,
  enableHomePageBulkEdit,
  entityTableRef,
}: {
  entities: Entity[];
  loading: boolean;
  totalCount: number;
  setFocusedEntity: React.Dispatch<React.SetStateAction<Entity | undefined>>;
  openModal: (modal: HomeModalRoutes) => void;
  showFolderLocation: boolean;
  isInRoot: boolean;
  folderIdToNameMap: FolderIdToNameMap;
  onMultiRowsSelectChange: (selectedEntities: Entity[]) => void;
  enableHomePageBulkEdit: boolean;
  entityTableRef: React.RefObject<RecommendedTableRef<Entity>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [
    canMangeFolder,
    canCreateApplication,
    canCreateWorkflow,
    canCreateScheduledJob,
  ] = useAuthorizationCheck([
    MANAGE_FOLDERS,
    CREATE_APPLICATIONS,
    CREATE_WORKFLOWS,
    CREATE_JOBS,
  ]);

  const [executeV2Api] = useSaga(executeV2ApiSaga);
  const [fetchV2Api] = useSaga(fetchV2ApiSaga);

  const handleRunJob = useCallback(
    async (entity: Entity): Promise<void> => {
      try {
        const callStack: ExecuteActionCallStack = [
          {
            type: EventType.ON_RUN_CLICK,
            propertyPath: `<${entity.name ?? "API"}>.<run now>`,
          },
        ];
        const branch = extractCurrentBranchFromSearchStringOrStorage(
          location.search,
          entity.id,
          navigate,
        );
        await fetchV2Api({
          apiId: entity.id,
          environment: SCHEDULED_JOB_ENVIRONMENT,
          branch,
        });
        await executeV2Api({
          apiId: entity.id,
          environment: SCHEDULED_JOB_ENVIRONMENT,
          viewMode: true,
          eventType: EventType.ON_RUN_CLICK,
          params: [],
          notifyOnSystemError: true,
          manualRun: true,
          callStack,
          includeOutputs: false,
        });
        sendSuccessUINotification({
          message: `Successfully ran scheduled job ${entity.name}`,
        });
      } catch (error: any) {
        sendErrorUINotification({
          message: `Failed to run scheduled job ${entity.name}: ${error.message}`,
        });
      }
    },
    [fetchV2Api, executeV2Api, location.search, navigate],
  );

  const [updateScheduleState] = useUpdateScheduleStateMutation();

  const handlePauseResume = useCallback(
    async (entity: Entity) => {
      try {
        await updateScheduleState({
          id: entity.id,
          scheduleState:
            entity.scheduleState === ScheduleState.ACTIVE
              ? ScheduleState.PAUSED
              : ScheduleState.ACTIVE,
        }).unwrap();
        sendSuccessUINotification({
          message: `Successfully ${
            entity.scheduleState === ScheduleState.ACTIVE ? "paused" : "resumed"
          } scheduled job ${entity.name}`,
        });
      } catch (error: any) {
        sendErrorUINotification({
          message: `Failed to ${
            entity.scheduleState === ScheduleState.ACTIVE ? "pause" : "resume"
          } scheduled job ${entity.name}: ${error.message}`,
        });
      }
    },
    [updateScheduleState],
  );

  const handleDuplicateApplication = useCallback(
    (applicationId: any) => {
      dispatch(duplicateApplicationInit({ applicationId }));
    },
    [dispatch],
  );

  const [cloneApi] = useSaga(cloneApiSaga);
  const handleCloneApi = useCallback(
    async (apiId: string, isWorkflow: boolean) => {
      const clonedApi = await cloneApi({ id: apiId });
      // Navigate to cloned api.
      navigate({
        pathname: isWorkflow
          ? WORKFLOW_CONFIGURE_URL(clonedApi.id)
          : SCHEDULED_JOB_CONFIGURE_URL(clonedApi.id),
      });
    },
    [cloneApi, navigate],
  );

  const renderLocation = useCallback(
    ({ value }: { value: string | null | undefined }) => {
      const folderName = getFolderNameFromFolderIdToNameMap(
        value,
        folderIdToNameMap,
      );
      return folderName ? (
        <div className={NameWrapperStyle}>
          <FolderIcon />
          <Link to={`/folders/${value}`} className={UnderlinedLinkClass}>
            <div style={{ maxWidth: NAME_MAX_WIDTH }}>
              <TruncatedTextTooltip text={folderName} className={NameStyle} />
            </div>
          </Link>
        </div>
      ) : null;
    },
    [folderIdToNameMap],
  );

  const renderNameWithSearchString = useCallback(
    ({
      value,
      cell,
    }: {
      value?: string;
      cell: Cell<Entity, string | undefined>;
    }) => {
      return renderName({ value, cell, locationSearch: location.search });
    },
    [location.search],
  );

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: renderNameWithSearchString,
      },
      {
        Header: "Location",
        accessor: "folderId",
        Cell: renderLocation,
        hidden: !showFolderLocation || !isInRoot,
      },
      {
        Header: "Created by",
        accessor: "creator",
        Cell: renderCreator,
        hidden: true,
      },
      {
        Header: "Last modified",
        accessor: "updated",
        Cell: renderTime,
      },
      {
        Header: "Last deployed",
        accessor: "lastDeployedAt",
        Cell: renderTime,
      },
    ],
    [renderLocation, showFolderLocation, isInRoot, renderNameWithSearchString],
  );

  const menuItems = useCallback(
    (entity: Entity): ActionMenuItem[] => {
      const canView = entity.permissions?.includes(ActionTypeEnum.VIEW);
      const canUpdate = entity.permissions?.includes(ActionTypeEnum.UPDATE);
      const canDelete = entity.permissions?.includes(ActionTypeEnum.DELETE);
      const canShare = entity.permissions?.includes(ActionTypeEnum.SHARE);
      const canManageScheduledJob = entity.permissions?.includes(
        ActionTypeEnum.MANAGE_SCHEDULE,
      );
      const canRunScheduledJob = entity.permissions?.includes(
        ActionTypeEnum.RUN,
      );

      const copyLink = {
        key: "copy-link",
        label: "Copy link",
        onClick: () => {
          const basedUrl = window.location.origin;
          const url = `${basedUrl}${entity.deployUrl}`;
          navigator.clipboard.writeText(url);
        },
      };

      const openInEditor = {
        key: "open-in-editor",
        label: "Open in editor",
        onClick: () => {
          navigate({
            pathname: entity.editUrl ?? "",
          });
        },
      };

      const moveToFolder: ActionMenuItem = {
        key: "move-to-folder",
        label: "Move to folder",
        children: [
          {
            label: <MoveEntitySearchableList entity={entity} />,
            key: "move-to-folder-popup",
          },
        ],
        popupClassName: "reset-submenu-styles",
      };

      const shareButton: ActionMenuItem = {
        key: "share",
        label: "Sharing & Permissions",
        onClick: () => {
          setFocusedEntity(entity);
          openModal(HomeModalRoutes.SHARE_PERMISSIONS);
        },
      };

      switch (entity.type) {
        case EntityType.APPLICATION: {
          const part1 = [
            ...(canView && entity.isDeployed
              ? [
                  {
                    key: "open",
                    label: "Open",
                    onClick: () => {},
                  },
                  copyLink,
                ]
              : []),
          ];
          const part2 = canUpdate ? [openInEditor] : [];

          const part3 = [
            ...(canCreateApplication && canUpdate
              ? [
                  {
                    key: "duplicate",
                    label: "Duplicate",
                    onClick: () => {
                      handleDuplicateApplication(entity.id);
                    },
                  },
                ]
              : []),

            ...(canUpdate
              ? [
                  {
                    key: "rename",
                    label: "Rename",
                    onClick: () => {
                      setFocusedEntity(entity);
                      openModal(HomeModalRoutes.RENAME_APPLICATION);
                    },
                  },
                  moveToFolder,
                ]
              : []),

            ...(canDelete
              ? [
                  {
                    key: "delete",
                    label: DeleteLabel,
                    onClick: () => {
                      setFocusedEntity(entity);
                      openModal(HomeModalRoutes.DELETE_APPLICATION);
                    },
                  },
                ]
              : []),
            ...(canShare
              ? ([
                  {
                    type: "divider",
                  },
                  shareButton,
                ] as ActionMenuItem[])
              : []),
          ];

          return [
            ...part1,
            ...(part1.length > 0 && part2.length > 0
              ? [{ type: "divider" }]
              : []),
            ...part2,
            ...(part2.length > 0 && part3.length > 0
              ? [{ type: "divider" }]
              : []),
            ...part3,
          ] as ActionMenuItem[];
        }
        case EntityType.WORKFLOW: {
          const part1 = canUpdate ? [openInEditor] : [];
          const part2 = [
            ...(canCreateWorkflow && canUpdate
              ? [
                  {
                    key: "duplicate",
                    label: "Duplicate",
                    onClick: () => {
                      handleCloneApi(entity.id, true);
                    },
                  },
                ]
              : []),
            ...(canUpdate
              ? [
                  {
                    key: "rename",
                    label: "Rename",
                    onClick: () => {
                      setFocusedEntity(entity);
                      openModal(HomeModalRoutes.RENAME_WORKFLOW);
                    },
                  },
                  moveToFolder,
                ]
              : []),
            ...(canDelete
              ? [
                  {
                    key: "delete",
                    label: DeleteLabel,
                    onClick: () => {
                      setFocusedEntity(entity);
                      openModal(HomeModalRoutes.DELETE_WORKFLOW);
                    },
                  },
                ]
              : []),
            ...(canShare
              ? ([
                  {
                    type: "divider",
                  },
                  shareButton,
                ] as ActionMenuItem[])
              : []),
          ];
          return [
            ...part1,
            ...(part1.length > 0 && part2.length > 0
              ? [{ type: "divider" }, ...part2]
              : []),
          ] as ActionMenuItem[];
        }
        case EntityType.SCHEDULED_JOB: {
          const part1 = [
            ...(canRunScheduledJob && entity.isDeployed
              ? [
                  {
                    key: "run",
                    label: "Run now",
                    onClick: () => {
                      handleRunJob(entity);
                    },
                  },
                ]
              : []),
            ...(entity.scheduleState !== null && canManageScheduledJob
              ? [
                  {
                    key: "pause-or-resume",
                    label:
                      entity.scheduleState === ScheduleState.ACTIVE
                        ? "Pause job"
                        : "Resume job",
                    onClick: () => {
                      handlePauseResume(entity);
                    },
                  },
                ]
              : []),
          ];
          const part2 = canUpdate ? [openInEditor] : [];

          const part3 = [
            ...(canCreateScheduledJob && canUpdate
              ? [
                  {
                    key: "duplicate",
                    label: "Duplicate",
                    onClick: () => {
                      handleCloneApi(entity.id, false);
                    },
                  },
                ]
              : []),
            ...(canUpdate
              ? [
                  {
                    key: "rename",
                    label: "Rename",
                    onClick: () => {
                      setFocusedEntity(entity);
                      openModal(HomeModalRoutes.RENAME_SCHEDULED_JOB);
                    },
                  },
                  moveToFolder,
                ]
              : []),
            ...(canDelete
              ? [
                  {
                    key: "delete",
                    label: DeleteLabel,
                    onClick: () => {
                      setFocusedEntity(entity);
                      openModal(HomeModalRoutes.DELETE_SCHEDULED_JOB);
                    },
                  },
                ]
              : []),
            ...(canShare
              ? ([
                  {
                    type: "divider",
                  },
                  shareButton,
                ] as ActionMenuItem[])
              : []),
          ];
          return [
            ...part1,
            ...(part1.length > 0 && part2.length > 0
              ? [{ type: "divider" }]
              : []),
            ...part2,
            ...(part2.length > 0 && part3.length > 0
              ? [{ type: "divider" }]
              : []),
            ...part3,
          ] as ActionMenuItem[];
        }
        case "folder":
          return [
            {
              key: "open",
              label: "Open",
              onClick: () => {
                navigate({
                  pathname: `/folders/${entity.id}`,
                  search: window.location.search,
                });
              },
            },
            {
              key: "copy-link",
              label: "Copy link",
              onClick: () => {
                const basedUrl = window.location.origin;
                const url = `${basedUrl}/folders/${entity.id}`;
                navigator.clipboard.writeText(url);
              },
            },
            ...(canCreateApplication ||
            canCreateWorkflow ||
            canCreateScheduledJob
              ? [
                  {
                    type: "divider" as const,
                  },
                  {
                    key: "create-new",
                    label: "Create new",
                    children: [
                      {
                        key: "create-application",
                        label: "Application",
                        icon: <AppIcon />,
                        onClick: () => {
                          setFocusedEntity(entity);
                          openModal(HomeModalRoutes.CREATE_APPLICATION);
                        },
                        disabled: !canCreateApplication,
                      },
                      {
                        key: "create-workflow",
                        label: "Workflow",
                        icon: <WorkflowIcon />,
                        onClick: () => {
                          setFocusedEntity(entity);
                          openModal(HomeModalRoutes.CREATE_WORKFLOW);
                        },
                        disabled: !canCreateWorkflow,
                      },
                      {
                        key: "create-scheduled-job",
                        label: "Scheduled job",
                        icon: <JobIcon />,
                        onClick: () => {
                          setFocusedEntity(entity);
                          openModal(HomeModalRoutes.CREATE_SCHEDULED_JOB);
                        },
                        disabled: !canCreateScheduledJob,
                      },
                    ],
                  },
                ]
              : []),
            ...(canMangeFolder
              ? ([
                  {
                    type: "divider",
                  },
                  {
                    key: "rename",
                    label: "Rename",
                    onClick: () => {
                      setFocusedEntity(entity);
                      openModal(HomeModalRoutes.RENAME_FOLDER);
                    },
                  },
                  {
                    key: "delete",
                    label: DeleteLabel,
                    onClick: () => {
                      setFocusedEntity(entity);
                      openModal(HomeModalRoutes.DELETE_FOLDER);
                    },
                  },
                ] as ActionMenuItem[])
              : []),
          ];
      }
    },
    [
      navigate,
      setFocusedEntity,
      openModal,
      canMangeFolder,
      canCreateApplication,
      handleDuplicateApplication,
      canCreateWorkflow,
      handleCloneApi,
      canCreateScheduledJob,
      handleRunJob,
      handlePauseResume,
    ],
  );

  const [persistedSortBy, setPersistedSortBy] = useLocalStorageState<
    SortingRule<Entity>[]
  >({
    key: `table-homepage-entities-sorting`,
    initialValue: [],
  });

  const quickActionItems = useCallback(
    (entity: Entity): QuickActionItem[] => {
      if (entity.type === "folder") {
        return [];
      }

      return [
        {
          key: "open-in-editor",
          icon: <PencilIcon />,
          tooltip: "Open in editor",
          onClick: () => {
            navigate({
              pathname: entity.editUrl ?? "",
            });
          },
        },
      ];
    },
    [navigate],
  );

  return (
    <RecommendedTable<Entity>
      data={entities}
      dataTest="entity-list"
      dataLabel="entities"
      uniqueKey="id"
      columns={columns}
      paginationOptions={
        totalCount > DEFAULT_TABLE_PAGE_SIZE
          ? { pageSize: DEFAULT_TABLE_PAGE_SIZE }
          : undefined
      }
      loading={loading}
      actionMenuItems={menuItems}
      quickActionItems={quickActionItems}
      enableMultiSelect={enableHomePageBulkEdit}
      onMultiRowsSelectChange={onMultiRowsSelectChange}
      defaultSortBy={persistedSortBy}
      onSortByChange={setPersistedSortBy}
      ref={entityTableRef}
    />
  );
};

export default EntityTable;
