import { Input, InputRef, Modal } from "antd";
import React, { useCallback, useRef, useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import { FooterWrapperWide, ModalWrapClass } from "components/ui/Modal";
import { DefaultModalWidth, ModalInnerWrapper } from "components/ui/Modal";
import { useDebounce } from "hooks/ui/useDebounce";
import { useCreateFolderMutation } from "store/slices/reduxApi/folders";
import logger from "utils/logger";
import {
  sendSuccessUINotification,
  sendErrorUINotification,
} from "utils/notification";

type CreateFolderModalProps = {
  closeModal: () => void;
};

const CreateFolderModal = ({ closeModal }: CreateFolderModalProps) => {
  const [createFolder, { isLoading }] = useCreateFolderMutation();

  const [name, setName] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setIsDirty(true);
  };

  const isNameValid = name.trim().length > 0;

  const validationError =
    !isDirty || isNameValid ? null : "Folder name can't be blank";

  const handleCreateNewFolder = useCallback(async () => {
    if (isLoading) return;

    if (!isNameValid) {
      setIsDirty(true);
      return;
    }

    try {
      await createFolder({ name }).unwrap();

      sendSuccessUINotification({
        message: "Folder created",
      });
    } catch (error: any) {
      logger.error("Error creating folder", error);
      sendErrorUINotification({
        message: "Folder creation failed",
      });
    }

    closeModal();
  }, [name, isNameValid, createFolder, closeModal, isLoading]);

  const debouncedHandleCreate = useDebounce(handleCreateNewFolder, 2000, {
    leading: true,
    trailing: false,
  });

  const handleCancel = useCallback(() => {
    if (isLoading) return;
    closeModal();
  }, [closeModal, isLoading]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        debouncedHandleCreate?.();
      }
    },
    [debouncedHandleCreate],
  );

  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      title="Create folder"
      open={true}
      onCancel={handleCancel}
      destroyOnClose
      data-test="create-folder-modal"
      width={DefaultModalWidth}
      wrapClassName={ModalWrapClass}
      footer={null}
    >
      <div className={ModalInnerWrapper} onKeyDown={onKeyDown}>
        <div className={FormWrapper}>
          <FormItem label="Folder name" required error={validationError}>
            <Input
              placeholder="Ex. Customer support"
              data-test="create-folder-name"
              onChange={handleNameChange}
              ref={inputRef}
            />
          </FormItem>
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            disabled={isLoading}
            data-test="cancel-integration-button"
            onClick={closeModal}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            loading={isLoading}
            type="primary"
            data-test="save-integration-button"
            onClick={debouncedHandleCreate}
          >
            Create
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFolderModal;
