import { Input, InputRef, Modal } from "antd";
import { capitalize } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import {
  ModalWrapClass,
  FooterWrapperWide,
  ModalInnerWrapper,
} from "components/ui/Modal";
import { useDebounce } from "hooks/ui/useDebounce";
import {
  sendErrorUINotification,
  sendSuccessUINotification,
} from "utils/notification";
import { Entity } from "../shared";

export type RenameEntityModalProps = {
  open: boolean;
  closeModal: () => void;
  entity?: Entity;
};

type InternalRenameEntityModalProps = RenameEntityModalProps & {
  onRename: (newName: string) => Promise<void>;
  entityLabel: string;
  title: string;
};

const RenameEntityModal = ({
  open,
  closeModal,
  onRename,
  title,
  entityLabel,
  entity,
}: InternalRenameEntityModalProps) => {
  const [newName, setNewName] = useState(entity?.name ?? "");
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
    setIsDirty(true);
  }, []);

  const isNameValid = newName.trim().length > 0;

  const validationError =
    !isDirty || isNameValid ? null : "Name can't be blank";

  const handleRename = useCallback(async () => {
    if (isRenaming) return;
    setIsRenaming(true);
    try {
      await onRename(newName);
      sendSuccessUINotification({
        message: `${capitalize(entityLabel)} name updated`,
      });
    } catch (e: any) {
      sendErrorUINotification({
        message: e?.message ?? `Failed to rename ${entityLabel}`,
      });
    } finally {
      setIsRenaming(false);
      closeModal();
    }
  }, [newName, onRename, closeModal, entityLabel, isRenaming]);

  const debouncedHandleRename = useDebounce(handleRename, 2000, {
    leading: true,
    trailing: false,
  });

  const handleCancel = useCallback(() => {
    if (isRenaming) return;
    closeModal();
  }, [isRenaming, closeModal]);

  const onKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        await debouncedHandleRename();
      }
    },
    [debouncedHandleRename],
  );

  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      width={384}
      wrapClassName={ModalWrapClass}
      open={open}
      onCancel={handleCancel}
      footer={null}
      title={title}
      destroyOnClose={true}
    >
      <div className={ModalInnerWrapper} onKeyDown={onKeyDown}>
        <div className={FormWrapper}>
          <FormItem label="Enter a new name" required error={validationError}>
            <Input
              value={newName}
              onChange={onNameChange}
              disabled={isRenaming}
              data-test={`rename-entity-input`}
              ref={inputRef}
            />
          </FormItem>
        </div>
        <div className={FooterWrapperWide}>
          <SecondaryButton
            onClick={handleCancel}
            disabled={isRenaming}
            data-test={`cancel-rename-entity`}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={debouncedHandleRename}
            loading={isRenaming}
            data-test={`confirm-rename-entity`}
            disabled={!isNameValid}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default RenameEntityModal;
