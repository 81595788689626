import { FolderDataDto, FolderDto } from "@superblocksteam/shared";
import { callServer, HttpMethod } from "../../utils/client";

/** TODO: Remove once we delete HomePageLegacy */

/** Deprecated. Use RTK instead */
export function getFolders() {
  return callServer<FolderDto[]>({
    method: HttpMethod.Get,
    url: "v1/folders/",
  });
}

/** Deprecated. Use RTK instead */
export function createFolder(folderData: FolderDataDto) {
  return callServer<FolderDto>({
    method: HttpMethod.Post,
    url: "v1/folders/",
    body: { folder: folderData },
  });
}

/** Deprecated. Use RTK instead */
export function renameFolder(folderId: string, newName: string) {
  return callServer<FolderDto>({
    method: HttpMethod.Put,
    url: `v1/folders/${folderId}`,
    body: { folder: { name: newName } },
  });
}

/** Deprecated. Use RTK instead */
export function deleteFolder(folderId: string) {
  return callServer<FolderDto>({
    method: HttpMethod.Delete,
    url: `v1/folders/${folderId}`,
  });
}
