import { getNextEntityName } from "@superblocksteam/shared";
import React from "react";
import AddIconButton from "components/ui/IconButtons/AddIconButton";
import {
  ActionableManualMenuItem,
  MenuChildrenType,
  MenuItemType,
} from "legacy/widgets/MenuWidget/types";
import { LinkToType } from "legacy/widgets/navigationProperties";
import BaseControl, { ControlProps } from "./BaseControl";

export const DEFAULT_MENU_ITEM: ActionableManualMenuItem = {
  type: MenuItemType.Link,
  linkTo: LinkToType.Page,
  label: "_default",
  childrenType: MenuChildrenType.Manual,
  isVisible: true,
  isDisabled: false,
};

const generateNewMenuItemLabel = (
  children?: Array<Record<string, unknown>>,
) => {
  if (!children || children.length === 0) {
    return "Menu item 1";
  }

  return getNextEntityName(
    "Menu item ",
    children.map((child) => child.label as string),
    " ",
  );
};

class AddMenuItemControl extends BaseControl<ControlProps> {
  render() {
    if (this.props.disablePanel) {
      return null;
    }

    return (
      <AddIconButton
        onClick={this.addNewNestedItem}
        data-test="add-menu-item"
        title="Add menu item"
        disabled={this.props.isDisabled}
      />
    );
  }

  addNewNestedItem = () => {
    const child: ActionableManualMenuItem = {
      ...DEFAULT_MENU_ITEM,
      label: generateNewMenuItemLabel(this.props.propertyValue),
    };

    const children = this.props.propertyValue
      ? [...this.props.propertyValue, child]
      : [child];

    this.updateProperty(this.props.propertyName, children);
  };

  static getControlType() {
    return "ADD_MENU_ITEM";
  }
}

export default AddMenuItemControl;
