import { ActionTypeEnum, BillingPlan } from "@superblocksteam/shared";
import { Button, Dropdown, Tooltip } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import Fuse from "fuse.js";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down.svg";
import { ReactComponent as AppIcon } from "assets/icons/home/app_small.svg";
import { ReactComponent as FolderIcon } from "assets/icons/home/folder.svg";
import { ReactComponent as JobIcon } from "assets/icons/home/job_small.svg";
import { ReactComponent as WorkflowIcon } from "assets/icons/home/workflow_small.svg";
import { Layout, MainWrapper } from "components/app";
import AddButton from "components/ui/AddButton";
import { SecondaryButton } from "components/ui/Button";
import { SingleFilter } from "components/ui/Filter";
import { HeaderWrapper, MainWrapperStyles } from "components/ui/Page";
import { DropdownOption } from "components/ui/RecommendedSingleDropdown";
import { RecommendedTableRef } from "components/ui/RecommendedTable";
import {
  SearchContainer,
  SearchInput,
  SearchInputWrapper,
} from "components/ui/SearchSection";
import {
  CREATE_JOBS,
  CREATE_WORKFLOWS,
  CREATE_APPLICATIONS,
  MANAGE_FOLDERS,
  MANAGE_ORGANIZATION,
} from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useSearchParamsAsState } from "hooks/ui/useSearchParamsAsState";
import useTimeout from "hooks/ui/useTimeout";
import { openPricingModal } from "legacy/actions/modalActions";
import { HomeModalRoutes as ModalRoutes } from "legacy/constants/routes";
import { getDemoApplication } from "legacy/selectors/applicationSelectors";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { STORAGE_KEYS } from "legacy/utils/StorageUtils";
import LandingPage from "pages/Onboarding/LandingPage";
import { convertResourceTypeToRoleType } from "pages/Permissions/constants";
import ColoredAvatar from "pages/components/ColoredAvatar";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { HOME_TITLE, PageWrapper } from "pages/components/PageWrapper";
import ShareModalV2 from "pages/components/ShareModalV2";
import { BreadCrumb } from "pages/components/navigation/BreadCrumb";
import { useAppDispatch } from "store/helpers";

import { Flag } from "store/slices/featureFlags";
import { getAllApplicationsInit } from "store/slices/homepage/slice";
import { selectOrganizations } from "store/slices/organizations";
import { useListApplicationsQuery } from "store/slices/reduxApi/applications";
import { useListFoldersQuery } from "store/slices/reduxApi/folders";
import { useListWorkflowsAndScheduledJobsQuery } from "store/slices/reduxApi/workflowsAndScheduledJobs";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { EntityType, getEntityLabel } from "utils/entity";
import { shortenUsername } from "utils/user";
import {
  CreateApplicationModal,
  CreateScheduledJobModal,
  CreateWorkflowModal,
} from "./CreateEntityModal/CreateEntityModal";
import CreateFolderModal from "./CreateFolderModal";
import DeleteApplicationModal from "./DeleteEntityModal/DeleteApplicationModal";
import DeleteScheduledJobModal from "./DeleteEntityModal/DeleteScheduledJobModal";
import DeleteWorkflowModal from "./DeleteEntityModal/DeleteWorkflowModal";
import DeleteFolderModal from "./FolderModal/DeleteFolderModal";
import RenameFolderModal from "./FolderModal/RenameFolderModal";
import HomeEntityTable from "./HomeEntityTable";
import RenameApplicationModal from "./RenameEntityModal/RenameApplicationModal";
import RenameScheduledJobModal from "./RenameEntityModal/RenameScheduledJobModal";
import RenameWorkflowModal from "./RenameEntityModal/RenameWorkflowModal";
import {
  apiToEntity,
  appToEntity,
  convertToResourceType,
  Entity,
  FolderMap,
  folderToEntity,
  getMostRecent,
} from "./shared";

const MODAL_AUTO_LOAD_TIMEOUT = 2000;

const HalfRow = styleAsClass`
  display: flex;
  gap: 8px;
`;

const SelectedSection = styleAsClass`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 40px;
  align-items: center;
`;

const ChevronButtonStyle = styleAsClass`
  &.dropdown-open {
    transform: rotate(180deg);
  }
`;

const FolderHeaderButtonWrapperStyle = styleAsClass`
  cursor: pointer;
  border-radius: 4px;
  button {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background: transparent;
    color: ${colors.GREY_300};
    &:hover {
      border-color: ${colors.GREY_100};
      background-color: ${colors.GREY_25};
    }
    &:active, &[data-focused=true] {
      border-color: ${colors.GREY_100};
      background-color: ${colors.GREY_50};
    }
    &.ant-btn:hover, &.ant-btn:focus {
      color: ${colors.GREY_300};
    }
    box-shadow: none;
  }
`;

const FolderHeaderDropdownStyle = styleAsClass`
  width: 188px;
`;

type CreatableTypeValue =
  | EntityType.APPLICATION
  | EntityType.WORKFLOW
  | EntityType.SCHEDULED_JOB
  | "folder";

type TypeValue = CreatableTypeValue | "all";

const allTypeOptions: (Omit<DropdownOption, "value"> & { value: TypeValue })[] =
  [
    { value: "all", displayName: "All", key: "all", hasDivider: true },
    {
      value: "folder",
      displayName: "Folder",
      key: "folder",
      icon: <FolderIcon />,
    },
    {
      value: EntityType.APPLICATION,
      displayName: getEntityLabel(EntityType.APPLICATION),
      key: EntityType.APPLICATION,
      icon: <AppIcon />,
    },
    {
      value: EntityType.WORKFLOW,
      displayName: getEntityLabel(EntityType.WORKFLOW),
      key: EntityType.WORKFLOW,
      icon: <WorkflowIcon />,
    },
    {
      value: EntityType.SCHEDULED_JOB,
      displayName: getEntityLabel(EntityType.SCHEDULED_JOB),
      key: EntityType.SCHEDULED_JOB,
      icon: <JobIcon />,
    },
  ];

const lastDeployedOptions: DropdownOption[] = [
  { value: "all", displayName: "All", key: "all", hasDivider: true },
  { value: "today", displayName: "Today", key: "today" },
  { value: "-7d", displayName: "Last 7 days", key: "last 7 days" },
  { value: "-30d", displayName: "Last 30 days", key: "last 30 days" },
  {
    value: "ytd",
    displayName: `This year (${new Date().getFullYear()})`,
    key: "this year",
    hasDivider: true,
  },
  {
    value: "never",
    displayName: "Never",
    key: "never",
  },
];

function isEntityLastDeployedWithin(
  entity: Entity,
  filterValue: string,
): boolean {
  if (!entity.lastDeployedAt) {
    return filterValue === "never";
  }
  const now = new Date();

  const lastDeployedDate = new Date(entity.lastDeployedAt);

  switch (filterValue) {
    case "today":
      return lastDeployedDate.toDateString() === now.toDateString();
    case "-7d":
      return (
        now.getTime() - lastDeployedDate.getTime() <= 7 * 24 * 60 * 60 * 1000
      );
    case "-30d":
      return (
        now.getTime() - lastDeployedDate.getTime() <= 30 * 24 * 60 * 60 * 1000
      );
    case "ytd":
      return lastDeployedDate.getFullYear() === now.getFullYear();
    case "never":
      return !entity.lastDeployedAt;
    default:
      return true;
  }
}
const CLICK_TRIGGER = ["click" as const];

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const enableHomePageBulkEdit = useFeatureFlag(
    Flag.ENABLE_HOME_PAGE_BULK_EDIT,
  );

  useEffect(() => {
    dispatch(getAllApplicationsInit());
  }, [dispatch]);

  const [focusedEntity, setFocusedEntity] = useState<Entity>();

  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);

  const entityTableRef = useRef<RecommendedTableRef<Entity>>(null);
  useEffect(() => {
    // Reset selected entities when navigating to a different folder
    setSelectedEntities([]);
    entityTableRef.current?.setSelectedRows({});
  }, [folderId]);

  const openModal = useCallback(
    (modal: ModalRoutes) => {
      navigate({ pathname: modal, search: window.location.search });
    },
    [navigate],
  );

  const [canManageFolders, canCreateApps, canCreateJobs, canCreateWorkflows] =
    useAuthorizationCheck([
      MANAGE_FOLDERS,
      CREATE_APPLICATIONS,
      CREATE_JOBS,
      CREATE_WORKFLOWS,
    ]);
  const canCreateEntity =
    canCreateApps || canCreateJobs || canCreateWorkflows || canManageFolders;

  const user = useSelector(getCurrentUser);
  const userName = user?.name;

  // get current organization ID
  const organizations = useSelector(selectOrganizations);
  const organization = Object.values(organizations)[0];

  const { data: applications = [], isLoading: isFetchingApplications } =
    useListApplicationsQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });

  const {
    data: workflowsAndScheduledJobs = [],
    isLoading: isWorkflowsAndScheduledJobsLoading,
  } = useListWorkflowsAndScheduledJobsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  // folder related
  const currentFolderId = folderId ?? "root";

  const closeModal = useCallback(() => {
    const path =
      folderId && folderId !== "root" ? `/folders/${folderId}` : "..";
    navigate({ pathname: path, search: window.location.search });
    setFocusedEntity(undefined);
  }, [navigate, folderId]);

  const { data: folders = [], isLoading: isFoldersLoading } =
    useListFoldersQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });

  const [isFolderHeaderButtonOpen, setIsFolderHeaderButtonOpen] =
    useState(false);

  const folderIdToNameMap = useMemo(() => {
    return folders.reduce(
      (acc, folder) => {
        acc[folder.id] = folder.name ?? "";
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [folders]);

  const applicationEntities = useMemo(() => {
    return applications.map((app) => appToEntity(app, folderIdToNameMap));
  }, [applications, folderIdToNameMap]);

  const workflowsAndScheduledJobsEntities = useMemo(() => {
    return workflowsAndScheduledJobs.map((api) =>
      apiToEntity(api, folderIdToNameMap),
    );
  }, [workflowsAndScheduledJobs, folderIdToNameMap]);

  const folderMap = useMemo(() => {
    const newFolderMap = {} as FolderMap;

    [...applicationEntities, ...workflowsAndScheduledJobsEntities].forEach(
      (entity) => {
        if (entity.folderId) {
          if (!newFolderMap[entity.folderId]) {
            newFolderMap[entity.folderId] = {
              name: folderIdToNameMap[entity.folderId],
              updated: entity.updated!, // todo fix
              lastDeployedAt: entity.lastDeployedAt,
            };
          } else {
            newFolderMap[entity.folderId].updated = getMostRecent(
              newFolderMap[entity.folderId].updated,
              entity.updated,
            );

            newFolderMap[entity.folderId].lastDeployedAt = getMostRecent(
              newFolderMap[entity.folderId].lastDeployedAt,
              entity.lastDeployedAt,
            );
          }
        }
      },
    );

    return newFolderMap;
  }, [
    folderIdToNameMap,
    applicationEntities,
    workflowsAndScheduledJobsEntities,
  ]);

  const folderEntities = useMemo(() => {
    return folders.map((folder) => folderToEntity(folder, folderMap));
  }, [folders, folderMap]);

  const entities = useMemo(
    () => [
      ...folderEntities,
      ...applicationEntities,
      ...workflowsAndScheduledJobsEntities,
    ],
    [folderEntities, applicationEntities, workflowsAndScheduledJobsEntities],
  );

  const currentFolder = useMemo(() => {
    const folder = folders.find((folder) => folder.id === folderId);
    return folder ? folderToEntity(folder, folderMap) : undefined;
  }, [folders, folderId, folderMap]);

  const folderActionsMenu = useMemo(
    () => ({
      items: [
        {
          label: "Copy link",
          key: "copy-link",
          onClick: () => {
            const basedUrl = window.location.origin;
            const url = `${basedUrl}/folders/${currentFolderId}`;
            navigator.clipboard.writeText(url);
          },
        },
        {
          type: "divider",
        },
        {
          label: "Rename",
          key: "rename",
          onClick: () => {
            if (currentFolder) {
              setFocusedEntity(currentFolder);
              openModal(ModalRoutes.RENAME_FOLDER);
            }
          },
        },
        {
          label: <div style={{ color: colors.DANGER }}>Delete</div>,
          key: "delete",
          onClick: () => {
            if (currentFolder) {
              setFocusedEntity(currentFolder);
              openModal(ModalRoutes.DELETE_FOLDER);
            }
          },
        },
      ] as ItemType[],
    }),
    [currentFolder, currentFolderId, openModal],
  );

  const onMultiRowsSelectChange = useCallback((selectedEntities: Entity[]) => {
    setSelectedEntities(selectedEntities);
  }, []);

  // TODO(pimeng): this is following the old home logic, not sure if we should just show everything here. Confirm with kris.
  const visibleEntities = useMemo(
    () =>
      entities.filter(
        (entity) =>
          (entity.isDeployed ||
            entity.isEditable ||
            entity.type === "folder") &&
          (currentFolderId === "root"
            ? true
            : entity.folderId === currentFolderId),
      ),
    [entities, currentFolderId],
  );

  const fuse = useMemo(() => {
    return new Fuse(visibleEntities, {
      keys: ["name", "folderName"],
      threshold: 0.2,
    });
  }, [visibleEntities]);

  const [searchTerm, setSearchTerm] = useSearchParamsAsState<string>({
    key: "search",
    defaultValue: "",
  });

  const handleSearchTermChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm],
  );

  const filteredTypeOptions = useMemo(() => {
    if (currentFolderId === "root") {
      return allTypeOptions;
    }
    return allTypeOptions.filter((option) => option.value !== "folder");
  }, [currentFolderId]);

  const [selectedType, setSelectedType] = useSearchParamsAsState<string>({
    key: "type",
    defaultValue: filteredTypeOptions[0].value,
  });

  // Can't select 'folder' within folder. But we keep the query params for when navigating back.
  const effectiveSelectedType =
    selectedType === "folder" && currentFolderId !== "root"
      ? filteredTypeOptions[0].value
      : selectedType;

  const handleSelectedTypeChange = useCallback(
    (option: DropdownOption) => {
      setSelectedType(option.value);
    },
    [setSelectedType],
  );

  const allCreatorOptions = useMemo(() => {
    const creatorOptionsById = visibleEntities.reduce(
      (acc: Record<string, DropdownOption>, entity) => {
        if (entity.creator) {
          const isMe = entity.creator.id === user?.id;
          acc[entity.creator.id] = {
            displayName: isMe
              ? `${entity.creator.name} (me)`
              : entity.creator.name,
            key: entity.creator.id,
            value: entity.creator.id,
            icon: (
              <ColoredAvatar name={entity.creator.name}>
                {entity.creator.name?.charAt(0).toUpperCase()}
              </ColoredAvatar>
            ),
            subText: entity.creator.email ?? entity.creator.name,
            subTextMaxLines: 1,
          };
        }
        return acc;
      },
      {},
    );
    const allOptions = Object.values(creatorOptionsById).sort((a, b) => {
      if (a.value === user?.id) return -1;
      if (b.value === user?.id) return 1;
      return (a.displayName as string)?.localeCompare(b.displayName as string);
    });
    return [
      {
        displayName: "Anyone",
        key: "all",
        value: "all",
        hasDivider: true,
      },
      ...allOptions,
    ];
  }, [user?.id, visibleEntities]);

  const [selectedCreator, setSelectedCreator] = useSearchParamsAsState<string>({
    key: "creator",
    defaultValue: allCreatorOptions[0].value,
  });

  const handleSelectedCreatorChange = useCallback(
    (option: DropdownOption) => {
      setSelectedCreator(option.value);
    },
    [setSelectedCreator],
  );

  const [selectedLastDeployed, setSelectedLastDeployed] =
    useSearchParamsAsState<string>({
      key: "lastDeployed",
      defaultValue: lastDeployedOptions[0].value,
    });

  const handleSelectedLastDeployedChange = useCallback(
    (option: DropdownOption) => {
      setSelectedLastDeployed(option.value);
    },
    [setSelectedLastDeployed],
  );

  const searchNonFolderResources =
    effectiveSelectedType !== "folder" &&
    (effectiveSelectedType !== "all" ||
      selectedCreator !== "all" ||
      selectedLastDeployed !== "all" ||
      Boolean(searchTerm));

  const filteredEntities = useMemo(() => {
    const fuseResults = searchTerm
      ? fuse.search(searchTerm).map(({ item }) => item)
      : visibleEntities;

    return fuseResults
      .filter((entity) => {
        if (searchNonFolderResources && entity.type === "folder") {
          return false;
        }

        if (
          (currentFolderId !== "root" && entity.folderId !== currentFolderId) ||
          (!searchNonFolderResources &&
            currentFolderId === "root" &&
            entity.folderId)
        ) {
          return false;
        }

        if (
          effectiveSelectedType !== "all" &&
          entity.type !== effectiveSelectedType
        ) {
          return false;
        }
        if (
          selectedCreator !== "all" &&
          entity.creator?.id !== selectedCreator
        ) {
          return false;
        }

        if (
          selectedLastDeployed !== "all" &&
          entity.type !== "folder" &&
          !isEntityLastDeployedWithin(entity, selectedLastDeployed)
        ) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        if (a.type === "folder" && b.type !== "folder") {
          return -1;
        }
        if (a.type !== "folder" && b.type === "folder") {
          return 1;
        }
        return (a.name ?? "")
          .toLocaleLowerCase()
          .localeCompare((b.name ?? "").toLocaleLowerCase());
      });
  }, [
    currentFolderId,
    fuse,
    searchNonFolderResources,
    searchTerm,
    selectedCreator,
    selectedLastDeployed,
    effectiveSelectedType,
    visibleEntities,
  ]);

  const demoApp = useSelector(getDemoApplication);
  const demoEntity = demoApp
    ? appToEntity(demoApp, folderIdToNameMap)
    : undefined;
  const hasOnlyDemoApp =
    visibleEntities?.length === 1 && visibleEntities[0]?.id === demoEntity?.id;

  const welcomeText = useMemo(() => {
    return userName ? `Welcome, ${shortenUsername(userName)}` : HOME_TITLE;
  }, [userName]);

  const openVideoModal = useCallback(
    () => openModal(ModalRoutes.DEMO_VIDEO),
    [openModal],
  );

  const openCreateApplicationModal = useCallback(
    () => openModal(ModalRoutes.CREATE_APPLICATION),
    [openModal],
  );

  const [canUpgradePlan] = useAuthorizationCheck([MANAGE_ORGANIZATION]);

  useTimeout(() => {
    if (
      organization.billing.plan === BillingPlan.TRIAL &&
      organization.billing.isExpired &&
      canUpgradePlan
    ) {
      const lastAutoLoadedTimeInEpoch =
        Number(
          localStorage.getItem(STORAGE_KEYS.PRICING_MODAL_AUTOLOADED_EPOCH_KEY),
        ) || 0;
      const currentTimeInEpoch = new Date().getTime();
      // Popup pricing modal once per day per user
      if (currentTimeInEpoch - lastAutoLoadedTimeInEpoch > 24 * 3600 * 1000) {
        dispatch(openPricingModal(true));
        localStorage.setItem(
          STORAGE_KEYS.PRICING_MODAL_AUTOLOADED_EPOCH_KEY,
          String(currentTimeInEpoch),
        );
      }
    }
  }, MODAL_AUTO_LOAD_TIMEOUT);

  const focusedEntityResourceType = focusedEntity
    ? convertToResourceType(focusedEntity.type)
    : null;

  const focusedEntityRoleType = focusedEntityResourceType
    ? convertResourceTypeToRoleType(focusedEntityResourceType)
    : null;

  const modals = useMemo(() => {
    const modalRoutes = [
      {
        path: ModalRoutes.CREATE_APPLICATION,
        element: (
          <CreateApplicationModal
            organization={organization}
            folderId={
              currentFolderId === "root" && focusedEntity?.type === "folder"
                ? focusedEntity?.id
                : folderId
            }
            isVisible={true}
            onCancel={closeModal}
          />
        ),
      },
      {
        path: ModalRoutes.RENAME_APPLICATION,
        element: (
          <RenameApplicationModal
            open={true}
            closeModal={closeModal}
            entity={focusedEntity}
          />
        ),
      },
      {
        path: ModalRoutes.DELETE_APPLICATION,
        element: (
          <DeleteApplicationModal
            open={true}
            closeModal={closeModal}
            entity={focusedEntity}
          />
        ),
      },
      {
        path: ModalRoutes.CREATE_WORKFLOW,
        element: (
          <CreateWorkflowModal
            organization={organization}
            folderId={
              currentFolderId === "root" && focusedEntity?.type === "folder"
                ? focusedEntity?.id
                : folderId
            }
            isVisible={true}
            onCancel={closeModal}
          />
        ),
      },
      {
        path: ModalRoutes.CREATE_FOLDER,
        element: <CreateFolderModal closeModal={closeModal} />,
      },
      {
        path: ModalRoutes.CREATE_SCHEDULED_JOB,
        element: (
          <CreateScheduledJobModal
            organization={organization}
            folderId={
              currentFolderId === "root" && focusedEntity?.type === "folder"
                ? focusedEntity?.id
                : folderId
            }
            isVisible={true}
            onCancel={closeModal}
          />
        ),
      },
      ...(focusedEntity
        ? [
            {
              path: ModalRoutes.RENAME_APPLICATION,
              element: (
                <RenameApplicationModal
                  open={true}
                  closeModal={closeModal}
                  entity={focusedEntity}
                />
              ),
            },
            {
              path: ModalRoutes.DELETE_APPLICATION,
              element: (
                <DeleteApplicationModal
                  open={true}
                  closeModal={closeModal}
                  entity={focusedEntity}
                />
              ),
            },
            {
              path: ModalRoutes.RENAME_WORKFLOW,
              element: (
                <RenameWorkflowModal
                  open={true}
                  closeModal={closeModal}
                  entity={focusedEntity}
                />
              ),
            },
            {
              path: ModalRoutes.DELETE_WORKFLOW,
              element: (
                <DeleteWorkflowModal
                  open={true}
                  closeModal={closeModal}
                  entity={focusedEntity}
                />
              ),
            },

            {
              path: ModalRoutes.RENAME_SCHEDULED_JOB,
              element: (
                <RenameScheduledJobModal
                  open={true}
                  closeModal={closeModal}
                  entity={focusedEntity}
                />
              ),
            },
            {
              path: ModalRoutes.DELETE_SCHEDULED_JOB,
              element: (
                <DeleteScheduledJobModal
                  open={true}
                  closeModal={closeModal}
                  entity={focusedEntity}
                />
              ),
            },
            {
              path: ModalRoutes.RENAME_FOLDER,
              element: (
                <RenameFolderModal
                  open={true}
                  setIsOpen={closeModal}
                  folder={focusedEntity}
                />
              ),
            },
            {
              path: ModalRoutes.DELETE_FOLDER,
              element: (
                <DeleteFolderModal
                  open={true}
                  setIsOpen={closeModal}
                  folder={focusedEntity}
                  entities={visibleEntities}
                />
              ),
            },
            {
              path: ModalRoutes.SHARE_PERMISSIONS,
              element:
                focusedEntity &&
                focusedEntityResourceType &&
                focusedEntityRoleType ? (
                  <ShareModalV2
                    isVisible={focusedEntity?.type !== "folder"}
                    setShareModalVisible={closeModal}
                    organizationId={organization.id}
                    resourceId={focusedEntity?.id}
                    resourceType={focusedEntityResourceType}
                    resourceDisplayName={focusedEntity?.name ?? ""}
                    inEditMode={false}
                    roleType={focusedEntityRoleType}
                    showShareTab={true}
                  />
                ) : null,
            },
          ]
        : []),
    ];

    return (
      <Routes>
        {modalRoutes.flatMap((route) => [
          // Root level route
          <Route key={route.path} path={route.path} element={route.element} />,
          // Folder level route
          <Route
            key={`folders/${route.path}`}
            path={`folders/:folderId/${route.path}`}
            element={route.element}
          />,
        ])}
      </Routes>
    );
  }, [
    organization,
    folderId,
    currentFolderId,
    closeModal,
    focusedEntity,
    visibleEntities,
    focusedEntityResourceType,
    focusedEntityRoleType,
  ]);

  const createMenuProps = useMemo(() => {
    const items: ItemType[] = [
      {
        label: "Application",
        key: "application",
        icon: <AppIcon />,
        onClick: () => openModal(ModalRoutes.CREATE_APPLICATION),
        disabled: !canCreateApps,
      },
      {
        label: "Workflow",
        key: "workflow",
        icon: <WorkflowIcon />,
        onClick: () => openModal(ModalRoutes.CREATE_WORKFLOW),
        disabled: !canCreateWorkflows,
      },
      {
        label: "Scheduled Job",
        key: "scheduled job",
        icon: <JobIcon />,
        onClick: () => openModal(ModalRoutes.CREATE_SCHEDULED_JOB),
        disabled: !canCreateJobs,
      },
      ...(currentFolderId === "root"
        ? [
            {
              label: "Folder",
              key: "folder",
              icon: <FolderIcon />,
              onClick: () => openModal(ModalRoutes.CREATE_FOLDER),
              disabled: !canManageFolders,
            },
          ]
        : []),
    ];

    return {
      items,
    };
  }, [
    openModal,
    canManageFolders,
    canCreateApps,
    canCreateWorkflows,
    canCreateJobs,
    currentFolderId,
  ]);

  const [
    canUpdateAllSelectedEntities,
    canDeleteAllSelectedEntities,
    canShareAllSelectedEntities,
  ] = useMemo(
    () => [
      selectedEntities.every((entity) =>
        entity.permissions?.includes(ActionTypeEnum.UPDATE),
      ),
      selectedEntities.every((entity) =>
        entity.permissions?.includes(ActionTypeEnum.DELETE),
      ),
      selectedEntities.every((entity) =>
        entity.permissions?.includes(ActionTypeEnum.SHARE),
      ),
    ],
    [selectedEntities],
  );

  const [hasAppOrFlobSelected, hasFolderSelected] = useMemo(() => {
    return [
      selectedEntities.some((entity) => entity.type !== "folder"),
      selectedEntities.some((entity) => entity.type === "folder"),
    ];
  }, [selectedEntities]);

  const multiSelectMenuProps = useMemo(() => {
    return {
      items: [
        {
          label: (
            <div>
              <Tooltip
                title={
                  canUpdateAllSelectedEntities
                    ? ""
                    : "Move disabled: no permission to move one or more items"
                }
              >
                <div>Move to folder</div>
              </Tooltip>
            </div>
          ),
          key: "move",
          disabled: !canUpdateAllSelectedEntities || !hasAppOrFlobSelected,
        },
        {
          label: (
            <div>
              <Tooltip
                title={
                  canShareAllSelectedEntities
                    ? ""
                    : "Share disabled: no permission to share one or more items"
                }
              >
                <div> Share </div>
              </Tooltip>
            </div>
          ),
          key: "share",
          disabled: !canShareAllSelectedEntities || hasFolderSelected,
        },
        {
          label: (
            <div>
              <Tooltip
                title={
                  canDeleteAllSelectedEntities
                    ? ""
                    : "Delete disabled: no permission to delete one or more items"
                }
              >
                <div
                  style={{
                    color: canDeleteAllSelectedEntities
                      ? colors.DANGER
                      : colors.GREY_300,
                  }}
                >
                  Delete
                </div>
              </Tooltip>
            </div>
          ),
          key: "delete",
          disabled: !canDeleteAllSelectedEntities,
        },
      ],
    };
  }, [
    canDeleteAllSelectedEntities,
    canShareAllSelectedEntities,
    canUpdateAllSelectedEntities,
    hasFolderSelected,
    hasAppOrFlobSelected,
  ]);

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const isLoading =
    isFetchingApplications ||
    isWorkflowsAndScheduledJobsLoading ||
    isFoldersLoading;

  const totalCount = useMemo(() => {
    const entitiesInCurrentFolder = visibleEntities.filter((entity) =>
      currentFolderId === "root"
        ? !entity.folderId
        : entity.folderId === currentFolderId,
    );
    return entitiesInCurrentFolder.length;
  }, [visibleEntities, currentFolderId]);

  return (
    <PageWrapper pageName={HOME_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        {currentFolderId === "root" &&
        (visibleEntities.length <= 0 || hasOnlyDemoApp) &&
        !isLoading ? (
          <LandingPage
            title={welcomeText}
            dataTest="home-list-ready"
            description="This is where you can manage your applications, workflows, and jobs and create new ones"
            callToActionButtonText="Create new"
            onClickCallToAction={openCreateApplicationModal}
            onClickDemoVideo={openVideoModal}
            demoApp={demoEntity}
            demoAppButtonText="View demo app"
          />
        ) : (
          <MainWrapper
            data-test={`home-list-${isLoading ? "loading" : "ready"}`}
            className={MainWrapperStyles}
          >
            {currentFolderId !== "root" && (
              <BreadCrumb
                paths={[
                  {
                    title: organization.name,
                    link: "/home" + window.location.search,
                  },
                  { title: currentFolder?.name ?? "" },
                ]}
              />
            )}
            <div className={HeaderWrapper}>
              <div
                className="page-header-title"
                style={{ justifyContent: "flex-start" }}
              >
                <div>{currentFolder?.name ?? organization.name}</div>
                {currentFolderId! !== "root" && (
                  <div className={FolderHeaderButtonWrapperStyle}>
                    <Dropdown
                      trigger={CLICK_TRIGGER}
                      onOpenChange={setIsFolderHeaderButtonOpen}
                      overlayClassName={FolderHeaderDropdownStyle}
                      menu={folderActionsMenu}
                    >
                      <Button
                        icon={<ChevronDown />}
                        data-focused={isFolderHeaderButtonOpen}
                        onClick={() => setIsFolderHeaderButtonOpen(true)}
                      />
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
            <div className={`${SearchContainer} ${SearchInputWrapper}`}>
              <div className={HalfRow}>
                <SearchInput
                  data-test="search-integrations"
                  onChange={handleSearchTermChange}
                  allowClear
                  placeholder="Search"
                  value={searchTerm}
                />
                <SingleFilter
                  value={effectiveSelectedType}
                  options={filteredTypeOptions}
                  onChange={handleSelectedTypeChange}
                  width={68}
                  popoverWidth={155}
                  useDynamicWidth={true}
                  label="Type"
                />
                <SingleFilter
                  value={selectedCreator}
                  options={allCreatorOptions}
                  onChange={handleSelectedCreatorChange}
                  width={103}
                  popoverWidth={250}
                  label="Created by"
                  useDynamicWidth={true}
                  showSearchInPopover={true}
                />
                <SingleFilter
                  value={selectedLastDeployed}
                  options={lastDeployedOptions}
                  onChange={handleSelectedLastDeployedChange}
                  width={120}
                  popoverWidth={166}
                  label="Last deployed"
                  useDynamicWidth={true}
                />
              </div>
              <div className={HalfRow}>
                {selectedEntities.length > 0 && enableHomePageBulkEdit && (
                  <div className={SelectedSection}>
                    <div style={{ whiteSpace: "nowrap", fontSize: 12 }}>
                      {`${selectedEntities.length} selected`}{" "}
                    </div>
                    <div>
                      <Dropdown
                        menu={multiSelectMenuProps}
                        trigger={CLICK_TRIGGER}
                        placement="bottomRight"
                        onOpenChange={setIsActionsDropdownOpen}
                        open={isActionsDropdownOpen}
                        overlayStyle={{
                          zIndex: 100,
                          width: "178px",
                        }}
                      >
                        <SecondaryButton style={{ fontWeight: 500, gap: 4 }}>
                          Actions{" "}
                          <ChevronDown
                            className={`${ChevronButtonStyle} ${
                              isActionsDropdownOpen
                                ? "dropdown-open"
                                : "dropdown-close"
                            }`}
                          />
                        </SecondaryButton>
                      </Dropdown>
                    </div>
                  </div>
                )}
                {canCreateEntity && (
                  <div>
                    <Dropdown
                      data-test="create-entity-dropdown"
                      placement="bottomRight"
                      menu={createMenuProps}
                      trigger={CLICK_TRIGGER}
                    >
                      <div onClick={stopPropagation}>
                        <AddButton text="Create" dataTest="create-entity" />
                      </div>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
            <div>
              <HomeEntityTable
                entities={filteredEntities}
                loading={isLoading}
                totalCount={totalCount}
                setFocusedEntity={setFocusedEntity}
                openModal={openModal}
                showFolderLocation={searchNonFolderResources}
                isInRoot={currentFolderId === "root"}
                folderIdToNameMap={folderIdToNameMap}
                onMultiRowsSelectChange={onMultiRowsSelectChange}
                enableHomePageBulkEdit={enableHomePageBulkEdit}
                entityTableRef={entityTableRef}
              />
            </div>
          </MainWrapper>
        )}
      </Layout>
      {modals}
    </PageWrapper>
  );
};

Home.displayName = HOME_TITLE;

export default Home;
